import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { moveResourceWithConflictResolutionModal } from "../../api";
import { useAuthDataContext } from "../../components/AuthProvider";
import { useCanvasesContext } from "../../components/CanvasesProvider";
import {
  isTrashFolder,
  useFoldersContext,
} from "../../components/FoldersProvider";
import { DragAndDropTypes } from "../../constants";
import { DraggedItem, isDragAllowed, isDropAllowed } from "../../dragAndDrop";
import { IFolder } from "../../interfaces";
import { FolderPreview } from "./FolderPreview";
import { menuForFolder, menuForTrashFolder } from "./ResourceDropdownMenu";
import { TileHeader } from "./TileHeader";

export interface IPreviewTileCommonProps {
  onClickResource: (event: React.MouseEvent, resourceId: string) => void;
  onDoubleClickResource: (event: React.MouseEvent, resourceId: string) => void;
  selectedResources: Set<string>;
  external_url: string;
  modal: Omit<ModalStaticFunctions, "warn">;
}

interface IPreviewTileFolderProps extends IPreviewTileCommonProps {
  folder: IFolder;
}

// A tile that provides a preview for a folder. Used to visualize folders in a
// grid layout.
export const PreviewTileFolder: React.FunctionComponent<IPreviewTileFolderProps> =
  (props) => {
    const { user } = useAuthDataContext();
    const { canvases } = useCanvasesContext();
    const { folders } = useFoldersContext();

    const ref = useRef(null);

    const [, drag] = useDrag(
      () => ({
        type: DragAndDropTypes.Folder,
        item: { id: props.folder.id },
        canDrag: () => {
          return isDragAllowed(props.folder.id, user, canvases, folders);
        },
      }),
      [props.folder, canvases, folders, user]
    );

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: [DragAndDropTypes.Folder, DragAndDropTypes.Canvas],
        collect: (monitor) => ({
          isOver: monitor.isOver() && monitor.canDrop(),
        }),
        canDrop: (item: DraggedItem, _monitor) => {
          return isDropAllowed(props.folder, item.id, user, canvases, folders);
        },
        drop: (item: DraggedItem, _monitor) => {
          moveResourceWithConflictResolutionModal(
            props.folder.id,
            item.id,
            canvases,
            folders
          );
        },
      }),
      [props.folder, user, canvases, folders]
    );

    drag(drop(ref));

    const parentFolder = folders.get(props.folder.folder_id) as IFolder;

    const menu = isTrashFolder(props.folder)
      ? menuForTrashFolder(props.folder, props.modal)
      : menuForFolder(
          props.folder,
          user,
          props.external_url,
          props.modal,
          parentFolder
        );

    const isSelected = props.selectedResources.has(props.folder.id);
    const isMultiSelect = props.selectedResources.size > 1;

    const isDropdownVisible = (isSelected && isMultiSelect) === false;

    return (
      <div
        ref={ref}
        className={`tile folder${isSelected || isOver ? " selected" : ""}`}
        data-resource-id={props.folder.id}
        onClick={(event) => {
          // Stop propagation to avoid triggering handlers in ancestors that
          // would clear selection
          event.stopPropagation();
          props.onClickResource(event, props.folder.id);
        }}
        onDoubleClick={(event) => {
          // Stop propagation to avoid triggering handlers in ancestors that
          // would clear selection
          event.stopPropagation();
          props.onDoubleClickResource(event, props.folder.id);
        }}
      >
        <TileHeader
          title={props.folder.name}
          menu={menu}
          isDropdownVisible={isDropdownVisible}
        />
        <FolderPreview folder={props.folder} tags={true} />
      </div>
    );
  };
