import React from "react";
import { Form, Input, Button, Card, message } from "antd";
import { useAuthDataContext } from "../../components/AuthProvider";
import Axios from "axios";
import { formLayout, formTailLayout } from "../FormLayout";
import ProfileLayout from "./ProfileLayout";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import passwordRule from "../password-rule";

export const ProfilePasswordPage: React.FunctionComponent = () => {
  return (
    <ProfileLayout selectedSideMenuKey="password">
      <PasswordForm />
    </ProfileLayout>
  );
};

const PasswordForm: React.FunctionComponent = () => {
  const { user } = useAuthDataContext();
  const [form] = Form.useForm();

  function handleSubmitForm(values: any): void {
    const { current_password, new_password } = values;

    Axios.post("/api/dashboard/users/" + user.id + "/password", {
      current_password: current_password,
      new_password: new_password,
    })
      .then((_res) => {
        message.success("Password was changed.");
      })
      .catch((error) => {
        console.error("Failed to change password:", error.response);
        const msgFromServer = error.response?.data?.msg;

        message.error("Failed to change password: " + msgFromServer);
      })
      .finally(() => form.resetFields());
  }

  return (
    <>
      <Card title="Password">
        <Form
          form={form}
          {...formLayout}
          name="profile"
          initialValues={{}}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            label="Current password"
            name="current_password"
            rules={[
              {
                required: true,
                type: "string",
                message: "Current password is required",
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item
            label="New password"
            name="new_password"
            rules={[
              {
                required: true,
                type: "string",
                message: "New password is required",
              },
              passwordRule,
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item
            label="Password confirmation"
            name="confirm_password"
            dependencies={["new_password"]}
            rules={[
              {
                required: true,
                message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords provided do not match"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit">
              Change password
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
