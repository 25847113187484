import { Form, Input, message, Modal } from "antd";
import { createCanvas, createFolder } from "../../api";

// Creates a resource by prompting the user to enter a name in a modal dialog.
export function createResourceModal(
  type: "canvas" | "folder",
  currentFolderId: string,
  onCreated: (resourceId: string) => void
) {
  const form = (
    <Form
      // https://ant.design/components/modal/#Note
      preserve={false}
      name="create-resource"
      onFinish={(values: any) => {
        if (type === "canvas") {
          createCanvas(values.name, currentFolderId)
            .then((canvas) => {
              message.success(`${canvas.name} was created.`);
              onCreated(canvas.id);
            })
            .catch((error) => {
              const msg = `Failed to create canvas (${error})`;
              console.error(msg);
              message.error(msg);
            });
        } else {
          createFolder(values.name, currentFolderId)
            .then((folder) => {
              message.success(`${folder.name} was created.`);
              onCreated(folder.id);
            })
            .catch((error) => {
              const msg = `Failed to create folder (${error})`;
              console.error(msg);
              message.error(msg);
            });
        }
      }}
    >
      <Form.Item label="Name" name="name">
        <Input placeholder={type === "canvas" ? "New canvas" : "New folder"} />
      </Form.Item>
    </Form>
  );

  Modal.confirm({
    title: type === "canvas" ? "New canvas" : "New folder",
    className: "create-resource-modal",
    closable: true,
    okText: "Create",
    icon: undefined,
    okButtonProps: {
      type: "primary",
      form: "create-resource",
      htmlType: "submit",
    },
    content: form,
  });
}
