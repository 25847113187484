import * as React from "react";
import { Router } from "./Router";
import AuthDataProvider from "./AuthProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import Axios from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async (context) => {
        const { data } = await Axios.get(`/api/dashboard${context.queryKey}`);
        return data;
      },
      // TODO: we might want to retry in production?
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const App: React.FunctionComponent = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthDataProvider>
        <Router />
      </AuthDataProvider>
    </QueryClientProvider>
  );
};
