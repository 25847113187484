import { useState, useEffect } from "react";
import Axios from "axios";
import React from "react";
import {
  Space,
  Typography,
  Select,
  Table,
  Button,
  Checkbox,
  message,
  Tooltip,
  Divider,
} from "antd";
import {
  UserOutlined,
  TeamOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { subscribe, SubscribeCallbackFn, unsubscribe } from "../backend";
import { UserByIdMap } from "./UsersReducer";
import { useUsersContext } from "./UsersProvider";
import { SelectUserOrGroup } from "./SelectUserOrGroup";

import "./PermissionsTable.less";
import { GroupByIdMap, useGroupsContext } from "./GroupsProvider";
import { genericPostResource } from "../api";
import { useAuthDataContext } from "./AuthProvider";
import { isHomeFolderId } from "./FoldersProvider";
import { guestUserId } from "../constants";
import {
  IPermissionRequest,
  IPermissionSubjectEntry,
  LinkPermissionType,
  PermissionType,
  IResource,
} from "../interfaces";

export type ResourcePrefix = "canvases/" | "canvas-folders/";

export interface IPermissionsTableProps {
  resource: IResource;
  resourcePrefix: ResourcePrefix;
  external_url?: string;
}

interface IPermission {
  type: "user" | "group";
  name: string;
  email: string;
  id: number;
  permission: PermissionType;
  inherited?: boolean;
}

function permissionResponseToArray(
  response: IPermissionRequest,
  users: UserByIdMap,
  groups: GroupByIdMap
) {
  let result: IPermission[] = [];

  response.users.forEach((permission) => {
    const user = users.get(permission.id);

    result.push({
      type: "user",
      name: user ? user.name : "undefined",
      email: user ? user.email : "",
      id: permission.id,
      permission: permission.permission,
      inherited: permission.inherited,
    });
  });

  response.groups.forEach((permission) => {
    const group = groups.get(permission.id);

    result.push({
      type: "group",
      name: group ? group.name : "undefined",
      email: "",
      id: permission.id,
      permission: permission.permission,
      inherited: permission.inherited,
    });
  });

  return result;
}

function handlePermissionChange(
  resourcePath: string,
  principalType: "user" | "group",
  principalId: number,
  permission: PermissionType,
  onSuccess: () => void,
  onFailure: (error: any) => void
) {
  const url = `/api/dashboard/${resourcePath}/permissions`;

  const innerPayload: IPermissionSubjectEntry = {
    id: principalId,
    permission: permission,
  };

  const payload = {
    users: principalType === "user" ? [innerPayload] : [],
    groups: principalType === "group" ? [innerPayload] : [],
  };

  Axios.post(url, payload)
    .then((_response) => {
      onSuccess();
    })
    .catch((error) => {
      onFailure(error);
    });
}

function formatTable(
  readOnly: boolean,
  resourcePrefix: ResourcePrefix,
  resourceId: string
) {
  return [
    {
      title: "Name",
      render: (_text: unknown, record: IPermission) => {
        switch (record.type) {
          case "user": {
            return (
              <Space
                data-principal-type={record.type}
                data-principal-id={record.id}
              >
                <UserOutlined />
                <span>
                  <Typography.Text strong>{record.name}</Typography.Text>
                  <br />
                  <Typography.Text type="secondary">
                    {record.email}
                  </Typography.Text>
                </span>
              </Space>
            );
          }
          case "group": {
            return (
              <Space>
                <TeamOutlined />
                <Typography.Text strong>{record.name}</Typography.Text>
              </Space>
            );
          }
        }
      },
    },
    {
      title: "Access",
      // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      align: "right" as "right",
      render: (_text: unknown, record: IPermission) => {
        const deleteOptionEnabled = (
          <Select.Option value="removed">
            <Typography.Text type="danger">Remove</Typography.Text>
          </Select.Option>
        );
        const deleteOptionDisabled = (
          <Select.Option disabled={true} value="removed">
            <Tooltip title="This permission propagates from a parent folder and can't be removed.">
              Remove
            </Tooltip>
          </Select.Option>
        );

        const resourcePath = `${resourcePrefix}${resourceId}`;
        const isHomeFolder = isHomeFolderId(resourceId);

        return (
          <Select
            value={record.permission}
            disabled={record.permission === "owner" || readOnly}
            style={{ width: 110 }}
            onChange={(value) =>
              handlePermissionChange(
                resourcePath,
                record.type,
                record.id,
                value,
                () => message.success("Permissions were modified."),
                (error) => {
                  const msgFromServer = error.response?.data?.msg;
                  console.error("Failed to modify permissions.", error);
                  message.error(
                    `Failed to modify permissions. (${msgFromServer})`
                  );
                }
              )
            }
          >
            <Select.Option value="view">Viewer</Select.Option>
            <Select.Option value="edit">Editor</Select.Option>
            <Select.Option
              disabled={record.type === "group" || isHomeFolder}
              value="owner"
            >
              Owner
            </Select.Option>

            <Select.Option value="none">No access</Select.Option>
            <Select.Option
              value=""
              disabled={true}
              style={{ cursor: "default", minHeight: 0 }}
            >
              <Divider style={{ margin: "0px 0" }} />
            </Select.Option>
            {record.inherited === true
              ? deleteOptionDisabled
              : deleteOptionEnabled}
          </Select>
        );
      },
    },
  ];
}

// Implements a table to visualize and modify resource permissions.
// Requires: UsersProvider, GroupsProvider
export const PermissionsTable: React.FunctionComponent<IPermissionsTableProps> =
  (props) => {
    const [permissions, setPermissions] = useState<IPermission[]>([]);
    // TODO: the default value is not correct until we finish the async query.
    // Since this is inside a collapse, nobody will likely notice.
    // Should use a 'loading' to handle properly.
    const [editorsCanShare, setEditorsCanShare] = useState<boolean>(false);
    const [selectedPrincipalId, setSelectedPrincipalId] = useState<number>();
    const [selectedPrincipalType, setSelectedPrincipalType] =
      useState<"user" | "group">();
    const [selectedPermissionType, setSelectedPermissionType] =
      useState<"view" | "edit">("edit");

    const [linkPermission, setLinkPermission] =
      useState<LinkPermissionType>("none");

    const { users } = useUsersContext();
    const { groups } = useGroupsContext();

    const { user } = useAuthDataContext();

    // Setup subscription to monitor permissions
    useEffect(() => {
      function handleChange(response: IPermissionRequest) {
        const data = permissionResponseToArray(response, users, groups);

        setPermissions(data);
        setEditorsCanShare(response.editors_can_share);
        setLinkPermission(response.link_permission);
      }

      const resourcePath = `${props.resourcePrefix}${props.resource.id}`;
      const endpoint = `/${resourcePath}/permissions`;

      // Callback used to check result of subscribe request
      const callbackFn: SubscribeCallbackFn = (status) => {
        if (status.ok === false) {
          // The subscribe request failed, probably because of lack of
          // permissions. This is normal, if the user clicks on an folder which
          // they have no permissions for, but one of the child resurces has
          // been shared with them.
          if (status.status !== 403)
            console.error(
              `Request to subscribe to ${endpoint} failed with status code ${status.status}`
            );

          // We invalidate the state to avoid showing previously cached values
          // from a different resource
          setPermissions([]);
        }
      };

      subscribe(endpoint, handleChange, callbackFn);

      return function cleanup() {
        unsubscribe(endpoint, handleChange);
      };
    }, [props.resourcePrefix, props.resource.id, users, groups]);

    function onInvitePermissionChange(value: "view" | "edit") {
      setSelectedPermissionType(value);
    }

    const resourcePath = `${props.resourcePrefix}${props.resource.id}`;
    const linkToCanvas = `${props.external_url}/open/${props.resource.id}`;

    // Construct exclusion sets to prevent adding principals that already have a
    // permission
    let userMembers = new Set<number>();
    let groupMembers = new Set<number>();

    let isOwnerOrAdmin = user.admin;
    permissions.forEach((permission) => {
      if (permission.type === "user") {
        userMembers.add(permission.id);
        if (
          permission.permission === "owner" &&
          permission.email === user.email
        )
          isOwnerOrAdmin = true;
      } else groupMembers.add(permission.id);
    });

    const isReadOnly =
      (props.resource.in_trash ||
        props.resource.access === "view" ||
        (props.resource.access === "edit" && editorsCanShare === false)) &&
      !isOwnerOrAdmin;

    // If Guest access is disabled, unauthenticated access using shared links is
    // disabled
    const isGuestBlocked = users.get(guestUserId)?.blocked;

    const viewLinkText = isGuestBlocked
      ? "Anyone with an account and the link can view"
      : "Anyone with the link can view";
    const editLinkText = isGuestBlocked
      ? "Anyone with an account and the link can edit"
      : "Anyone with the link can edit";

    const canvasLink = (
      <>
        <Space direction="horizontal" className="canvas-link">
          <Typography.Title level={5}>Canvas link</Typography.Title>
          {isGuestBlocked && (
            <Tooltip title="Guest access has been disabled by an administrator. Only users with an account on this server can open shared links.">
              <QuestionCircleTwoTone twoToneColor="#faad14" />
            </Tooltip>
          )}
        </Space>
        <div className="table-header-row">
          <Select<LinkPermissionType>
            className={"select-link-permission"}
            value={linkPermission}
            disabled={isReadOnly}
            onChange={(value) => {
              genericPostResource(
                `${resourcePath}/permissions`,
                { link_permission: value },
                "Permissions updated.",
                "Failed to update permissions."
              );
            }}
          >
            <Select.Option value="none">
              Only people added can open with this link
            </Select.Option>
            <Select.Option value="view">{viewLinkText}</Select.Option>
            <Select.Option value="edit">{editLinkText}</Select.Option>
          </Select>
          <Typography.Text
            copyable={{
              text: linkToCanvas,
              tooltips: ["Copy shared link", "Link copied"],
              icon: "Copy link",
            }}
          />
        </div>
      </>
    );

    // Number of rows visible at a time in the permission table.
    const visiblePermissionRowCount = 5;
    // Height of one row in the permisions table. If the style is modified, this
    // needs to be updated.
    const permissionTableRowHeight = 61;

    return (
      <div className="permissions-wrapper">
        <div className="table-header-row">
          <SelectUserOrGroup
            disabled={isReadOnly}
            excludedUsers={userMembers}
            excludedGroups={groupMembers}
            onChange={(type, id) => {
              setSelectedPrincipalType(type);
              setSelectedPrincipalId(id);
            }}
            valueType={selectedPrincipalType}
            value={selectedPrincipalId}
          />

          <Select
            defaultValue="edit"
            onChange={onInvitePermissionChange}
            disabled={isReadOnly}
            style={{ width: 140 }}
          >
            <Select.Option value="view">Viewer</Select.Option>
            <Select.Option value="edit">Editor</Select.Option>
            <Select.Option value="none">No access</Select.Option>
            {/* "owner" is excluded on purpose to avoid accidentally giving
              away ownership (similar to Google Drive). */}
          </Select>

          <Button
            onClick={() => {
              if (selectedPrincipalType && selectedPrincipalId) {
                handlePermissionChange(
                  resourcePath,
                  selectedPrincipalType,
                  selectedPrincipalId,
                  selectedPermissionType,
                  () => {
                    message.success("Permissions were modified.");
                    setSelectedPrincipalId(undefined);
                    setSelectedPrincipalType(undefined);
                  },
                  (error) => {
                    const msgFromServer = error.response?.data?.msg;
                    console.error("Failed to modify permissions.", error);
                    message.error(
                      `Failed to modify permissions. (${msgFromServer})`
                    );
                  }
                );
              }
            }}
            disabled={selectedPrincipalId === undefined || isReadOnly}
            type="primary"
          >
            Add
          </Button>
        </div>

        <Table<IPermission>
          showHeader={false}
          columns={formatTable(
            isReadOnly,
            props.resourcePrefix,
            props.resource.id
          )}
          dataSource={permissions}
          rowKey={(record: IPermission) => `${record.type}${record.id}`}
          pagination={false}
          scroll={{
            y: visiblePermissionRowCount * permissionTableRowHeight,
          }}
          size={"small"}
        />

        <Typography.Text strong>Owner settings</Typography.Text>
        <Checkbox
          id="editors-can-share"
          disabled={isReadOnly || isOwnerOrAdmin === false}
          checked={editorsCanShare}
          onChange={(event) => {
            genericPostResource(
              `${resourcePath}/permissions`,
              { editors_can_share: event.target.checked },
              "Permissions updated.",
              "Failed to update permissions."
            );
          }}
        >
          Allow editors to change access and add people
        </Checkbox>
        {props.resourcePrefix === "canvases/" && canvasLink}
      </div>
    );
  };
