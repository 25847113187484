import * as PIXI from "pixi.js";
import { BezierTimingFunction } from "./math/bezier-easing";
import { WebClient } from "./web-client";

type Options = {
  time?: number;
  func?: BezierTimingFunction;
  invalidateNav?: boolean;
  destroy?: boolean;
};

type Props = {
  x?: number;
  y?: number;
  scale?: number;
  width?: number;
  height?: number;
};

type Animation = {
  target: PIXI.DisplayObject;
  props: Props;
  src: Props;
  t: number;
  speed: number;
  func: BezierTimingFunction;
  invalidateNav: boolean;
  destroy: boolean;
};

function lerp(t: number, from: number, to: number) {
  return (1.0 - t) * from + t * to;
}

export class Easing {
  // CSS "ease"
  static ease = new BezierTimingFunction(
    { x: 0.25, y: 0.1 },
    { x: 0.25, y: 1.0 }
  );

  private app: WebClient;
  private prevTime = 0;
  private animations = new Map<number, Animation>();
  private nextAnimationId = 1;

  constructor(app: WebClient) {
    this.app = app;
  }

  add(target: PIXI.DisplayObject, props: Props, options: Options = {}): number {
    const id = this.nextAnimationId++;

    for (const [id, anim] of this.animations) {
      if (anim.target === target) {
        for (const prop of Object.keys(props)) {
          if ((anim.props as any)[prop] !== undefined) {
            this.animations.delete(id);
            break;
          }
        }
      }
    }

    const src: Props = {};
    for (const prop of Object.keys(props)) {
      if (prop === "scale") {
        src[prop] = target.scale.x;
      } else {
        (src as any)[prop] = (target as any)[prop];
      }
    }

    const anim = {
      target,
      props,
      src,
      t: -1,
      speed: 1.0 / (options.time ?? 0.5),
      func: options.func ?? Easing.ease,
      invalidateNav: !(options.invalidateNav === false),
      destroy: options.destroy === true,
    };
    this.animations.set(id, anim);

    if (anim.invalidateNav) this.app.setNavigationDirty();
    return id;
  }

  remove(id: number) {
    return this.animations.delete(id);
  }

  cancel(target: PIXI.DisplayObject) {
    for (let [id, anim] of this.animations) {
      if (anim.target === target) {
        this.animations.delete(id);
      }
    }
  }

  // Returns true if all animations are done
  update(time: DOMHighResTimeStamp): boolean {
    if (this.animations.size === 0) {
      this.prevTime = time;
      return true;
    }

    const dt = Math.min(1 / 10.0, (time - this.prevTime) / 1000.0);
    this.prevTime = time;

    for (let [id, anim] of this.animations) {
      if (this.updateAnim(dt, anim)) {
        if (anim.destroy && !anim.target.destroyed) anim.target.destroy();
        this.animations.delete(id);
      }
    }

    return this.animations.size === 0;
  }

  private updateAnim(dt: number, anim: Animation) {
    if (anim.target.destroyed) return true;

    if (anim.t < 0) {
      anim.t = 0;
      return false;
    }

    anim.t = Math.min(1.0, anim.t + dt * anim.speed);

    const t = anim.func.y(anim.t);

    if (anim.props.scale !== undefined)
      anim.target.scale.set(lerp(t, anim.src.scale!, anim.props.scale));
    if (anim.props.x !== undefined)
      anim.target.x = lerp(t, anim.src.x!, anim.props.x);
    if (anim.props.y !== undefined)
      anim.target.y = lerp(t, anim.src.y!, anim.props.y);
    if (anim.props.width !== undefined)
      (anim.target as any).width = lerp(t, anim.src.width!, anim.props.width);
    if (anim.props.height !== undefined)
      (anim.target as any).height = lerp(
        t,
        anim.src.height!,
        anim.props.height
      );

    if (anim.invalidateNav) this.app.setNavigationDirty();

    return anim.t >= 1.0;
  }
}
