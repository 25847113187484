import { Result } from "antd";
import React from "react";
import { CheckOutlined } from "@ant-design/icons";

export const LoginOk: React.FunctionComponent = () => {
  return (
    <Result
      status="info"
      title="Successfully logged in"
      icon={<CheckOutlined />}
    ></Result>
  );
};
