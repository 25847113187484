import {
  faAnchor,
  faAngleLeft,
  faAngleRight,
  faDownload,
  faExpand,
  faFile,
  faFilePdf,
  faImage,
  faStickyNote,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "antd";
import React, { Fragment } from "react";
import { IActiveWidget } from "../../pages/canvas/CanvusWebClient";
import { intersection, Rectf } from "../math/rect";

import "./TitleBar.less";

interface ITitleBarProps {
  widget: IActiveWidget;
  workspaceSize: { width: number; height: number };
  viewOnly: boolean;
  fullScreenWidget?: string;
  setFullScreenWidget: (id?: string) => void;
}

function rectWidth(rect: Rectf) {
  return rect.right - rect.left;
}

function rectHeight(rect: Rectf) {
  return rect.bottom - rect.top;
}

function placeTitle(widgetRect: Rectf, usableRect: Rectf) {
  const margin = 12.0;
  const border = 1.0;
  const h = 48.0;

  usableRect.left += margin;
  usableRect.top += margin;
  usableRect.bottom -= margin;
  usableRect.right -= margin;

  const visibleWidgetRect = intersection(usableRect, widgetRect);

  // Position the widget vertically. By default the title bar is above
  // the canvas item.
  let y = widgetRect.top - margin - h;

  // Does the widget fit there. If not, we need to consider moving the bar
  // below the canvas item.
  const fitsAbove = y >= usableRect.top;
  const fitsBelow =
    y + h + rectHeight(widgetRect) + margin + h < usableRect.bottom;

  // If the title doesn't fit above, but it does fit below, move it there.
  // In case the title bar doesn't fit anywhere (widget is full screen or
  // just large enough), keep the bar at the top of the workspace.
  const forceToBottom = false;
  const atBottom = forceToBottom || (!fitsAbove && fitsBelow);

  // Expand the title bar to match the visible widget width
  const fixedMinWidth = 0.0;
  const mw = Math.max(
    Math.min(rectWidth(usableRect), rectWidth(visibleWidgetRect) - border * 2),
    fixedMinWidth
  );

  // TODO: We don't actually know the real width, just the minimum width. This
  // breaks alignment.
  const w = mw;

  // Position the widget horizontally. Align it on the right if possible.
  let x = widgetRect.right - border - w;
  if (x < usableRect.left) {
    x = usableRect.left;
  } else if (x + w > usableRect.right) {
    x = usableRect.right - w;
  }

  // We want to animate the title moving between top/bottom locations, but we
  // don't want to animate the location otherwise when the widget moves,
  // resizes or avoids an object. Calculate both top and bottom positions,
  // and then just interpolate between those using margin-top value that has
  // a CSS transition.

  const top = atBottom ? y : Math.max(y, usableRect.top);
  const yBottom = Math.min(
    y + margin * 2.0 + h + rectHeight(widgetRect),
    usableRect.bottom - h
  );

  const marginTop = atBottom ? yBottom - y : 0;

  return { left: x, minWidth: w, top, marginTop };
}

export const TitleBar: React.FunctionComponent<ITitleBarProps> = (props) => {
  const style = placeTitle(props.widget.rect, {
    left: 0,
    top: 0,
    right: props.workspaceSize.width,
    bottom: props.workspaceSize.height,
  });

  let icon;
  switch (props.widget.type) {
    case "Image":
      icon = faImage;
      break;
    case "Note":
      icon = faStickyNote;
      break;
    case "Video":
      icon = faVideo;
      break;
    case "Anchor":
      icon = faAnchor;
      break;
    case "Pdf":
      icon = faFilePdf;
      break;
    default:
      icon = faFile;
  }

  async function maximize(e: React.MouseEvent) {
    try {
      if (document.fullscreenElement === null) {
        // TODO: should use references
        const canvas = document.getElementById("web-client")!;
        await canvas.requestFullscreen();
      }
    } catch (error) {
      console.error(error);
    }
    props.setFullScreenWidget(props.widget.id);
  }

  const page = props.widget.page;
  const count = props.widget.pageCount;

  return (
    <div className="title-bar" style={style}>
      <FontAwesomeIcon className="icon" icon={icon} />
      <span className="title">
        {props.widget.title ? props.widget.title : props.widget.type}
      </span>
      {page !== undefined && (
        <Fragment>
          <Button type="text" disabled={page === 0 || props.viewOnly}>
            <FontAwesomeIcon className="menu-icon" icon={faAngleLeft} />
          </Button>
          <Typography.Text>{page + 1}</Typography.Text>
          <Button type="text" disabled={page + 1 === count || props.viewOnly}>
            <FontAwesomeIcon className="menu-icon" icon={faAngleRight} />
          </Button>
        </Fragment>
      )}
      <span className="divider"></span>
      {props.widget.downloadUrl && (
        <Button type="text" href={props.widget.downloadUrl}>
          <FontAwesomeIcon className="menu-icon" icon={faDownload} />
        </Button>
      )}
      <Button type="text" onClick={maximize}>
        <FontAwesomeIcon className="menu-icon" icon={faExpand} />
      </Button>
    </div>
  );
};
