import React, { useState, useEffect, useReducer } from "react";
import { Card, Input, Button, Form, Space, Table, message } from "antd";
import AdminLayout from "./AdminLayout";

import "./AdminEditGroupPage.less";
import { IUserInfo } from "../../components/AuthProvider";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { formLayout, formTailLayout } from "../FormLayout";
import PageLoading from "../../components/PageLoading";
import { IGroup } from "../../components/GroupsProvider";
import UsersProvider from "../../components/UsersProvider";
import { subscribe, unsubscribe } from "../../backend";
import UsersReducer from "../../components/UsersReducer";
import { SelectUser } from "../../components/SelectUser";

// Fetch data about single user
async function fetchGroup(id: number) {
  try {
    const response = await Axios.get("/api/dashboard/groups/" + id);
    return response.data as IGroup;
  } catch (err) {
    console.error("Failed to fetch group " + id, err.response);
  }
}

export const AdminEditGroupPage: React.FunctionComponent = () => {
  const params = useParams();
  const [group, setGroup] = useState<IGroup>();

  const id = params.groupId;

  // Fetch user data on component load
  useEffect(() => {
    fetchGroup(Number(id)).then((g) => {
      setGroup(g);
    });
  }, [id]);

  function handleSubmitForm(values: any): void {
    const { name, description } = values;

    Axios.patch("/api/dashboard/groups/" + group?.id, {
      name,
      description,
    })
      .then(() => {
        message.success("The group was updated.");
      })
      .catch((error) => {
        console.error("Failed to update group:", error.response);
        const msgFromServer = error.response?.data?.msg;

        message.error(`Failed to update the group. (${msgFromServer})`);
      });
  }

  const loading = <PageLoading />;
  const loaded = (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Card title="Edit Group">
        <Form
          {...formLayout}
          name="edit-group"
          initialValues={{
            name: group?.name,
            description: group?.description,
          }}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            label="Group name"
            name="name"
            rules={[
              {
                required: true,
                type: "string",
                message: "Group name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description (optional)"
            name="description"
            rules={[
              {
                required: false,
                type: "string",
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit">
              Save changes
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {group && (
        <UsersProvider>
          <GroupMembers group={group} />
        </UsersProvider>
      )}
    </Space>
  );

  return (
    <AdminLayout selectedSideMenuKey="groups">
      {group === undefined ? loading : loaded}
    </AdminLayout>
  );
};

interface IGroupMembersProps {
  group: IGroup;
}

function addMember(
  groupId: number,
  userId: number,
  onSuccess: () => void,
  onFailure: (error: any) => void
) {
  const url = `/api/dashboard/groups/${groupId}/members`;
  // TODO: we would like to be able to add multiple members at once, but the
  // REST API doesn't support it.
  const data = { id: userId };

  Axios.post(url, data)
    .then((_res) => {
      onSuccess();
    })
    .catch((error) => {
      onFailure(error);
    });
}

function removeMember(groupId: number, user: IUserInfo) {
  const url = `/api/dashboard/groups/${groupId}/members/${user.id}`;
  Axios.delete(url)
    .then(() => {
      message.success(`User ${user.name} was removed from the group.`);
    })
    .catch((error) => {
      console.error("Failed to remove user from group:", error.response);
      const msgFromServer = error.response?.data?.msg;

      message.error(
        `Failed to remove ${user.name} from the group. (${msgFromServer})`
      );
    });
}

export const GroupMembers: React.FunctionComponent<IGroupMembersProps> = (
  props
) => {
  // Current members of the group
  const [members, membersDispatch] = useReducer(UsersReducer, new Map());
  const [selectedUserId, setSelectedUserId] = useState<number>();

  // Subscribe to monitor group member changes
  useEffect(() => {
    const membersEndpoint = `/groups/${props.group.id}/members`;
    function handleMembersChange(members: IUserInfo[]) {
      membersDispatch(members);
    }

    subscribe(membersEndpoint, handleMembersChange);

    return function cleanup() {
      unsubscribe(membersEndpoint, handleMembersChange);
    };
  }, [props.group.id]);

  const groupId = props.group.id;

  const columns = [
    { title: "Name", dataIndex: "name" },
    {
      title: "",
      // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      align: "right" as "right",
      render: (_text: unknown, record: IUserInfo) => {
        return (
          <Button danger onClick={() => removeMember(groupId, record)}>
            Remove
          </Button>
        );
      },
    },
  ];

  // Construct a Set of current member ids
  let currentMemberIds = new Set<number>();
  members.forEach((user) => currentMemberIds.add(user.id));

  return (
    <Card title="Members">
      <p>
        Add new members to <b>{props.group.name}</b>
      </p>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div className="table-header-row">
          <SelectUser
            excludedUsers={currentMemberIds}
            onChange={(value) => setSelectedUserId(value)}
            value={selectedUserId}
          />

          <Button
            disabled={selectedUserId === undefined}
            type="primary"
            onClick={() => {
              if (selectedUserId !== undefined) {
                addMember(
                  groupId,
                  selectedUserId,
                  () => {
                    // Clear selection after successful add
                    setSelectedUserId(undefined);
                    message.success("User was added to the group.");
                  },
                  (error) => {
                    console.error(
                      "Failed to add user to group:",
                      error.response
                    );
                    const msgFromServer = error.response?.data?.msg;
                    message.error(
                      `Failed to add user to the group. (${msgFromServer})`
                    );
                  }
                );
              }
            }}
          >
            Add member
          </Button>
        </div>
        <Table<IUserInfo>
          columns={columns}
          dataSource={Array.from(members.values())}
          rowKey="id"
          locale={{ emptyText: "The group has no members." }}
        />
      </Space>
    </Card>
  );
};
