type Range = {
  begin: number;
  end: number;
};

// Allocates blocks of memory from a continuous Float32Array buffer
export class FloatAllocator {
  private bufferMinLength = 1024;
  public allocatedBlocks: Array<Range> = [];
  public allocatedLength = 0;
  data: Float32Array = new Float32Array(this.bufferMinLength);

  clear() {
    const usageRatio = this.allocatedLength / this.data.length;
    if (usageRatio < 0.2 && this.data.length > this.bufferMinLength) {
      this.data = new Float32Array(this.bufferMinLength);
    }
    this.allocatedBlocks = [];
    this.allocatedLength = 0;
  }

  grow(newLength: number) {
    const newData = new Float32Array(newLength);
    newData.set(this.data);
    this.data = newData;
  }

  allocate(length: number): number {
    if (this.data.length - this.allocatedLength < length) {
      this.grow(Math.max(this.data.length * 2, this.data.length + length));
    }

    if (this.allocatedBlocks.length === 0) {
      this.allocatedBlocks.push({ begin: 0, end: length });
      this.allocatedLength += length;

      return 0;
    }

    let offset = 0;
    for (let [i, b] of this.allocatedBlocks.entries()) {
      const free = b.begin - offset;
      if (free >= length) {
        if (free === length) {
          if (offset === 0) {
            b.begin = 0;
          } else {
            // This is between two blocks, we will just merge them
            this.allocatedBlocks[i - 1].end = b.end;
            this.allocatedBlocks.splice(i, 1);
          }
        } else {
          if (offset === 0) {
            this.allocatedBlocks.unshift({ begin: 0, end: length });
          } else {
            this.allocatedBlocks[i - 1].end += length;
          }
        }
        this.allocatedLength += length;
        return offset;
      }
      offset = b.end;
    }

    const free = this.data.length - offset;
    if (free >= length) {
      this.allocatedBlocks[this.allocatedBlocks.length - 1].end += length;
      this.allocatedLength += length;
      return offset;
    }

    this.grow(Math.max(this.data.length * 2, this.data.length + length));
    this.allocatedBlocks[this.allocatedBlocks.length - 1].end += length;
    this.allocatedLength += length;
    return offset;
  }

  free(offset: number, length: number) {
    for (let [i, b] of this.allocatedBlocks.entries()) {
      if (offset === b.begin) {
        if (offset + length === b.end) {
          this.allocatedBlocks.splice(i, 1);
        } else {
          b.begin += length;
        }
        this.allocatedLength -= length;
        return;
      } else if (offset < b.end) {
        if (offset + length === b.end) {
          b.end = offset;
        } else {
          this.allocatedBlocks.splice(i + 1, 0, {
            begin: offset + length,
            end: b.end,
          });
          b.end = offset;
        }
        this.allocatedLength -= length;
        return;
      }
    }
    throw new Error("Block not found");
  }
}
