import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCompress, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WebClientApi } from "../web-client";

import "./ZoomToolbar.less";

interface IZoomToolbarProps {
  api: WebClientApi;
  workspaceSize: { width: number; height: number };
  scale: number;
  minScale: number;
  maxScale: number;
}

interface IToolbarProps {}
const Toolbar: React.FunctionComponent<IToolbarProps> = (props) => {
  return <div className="Toolbar">{props.children}</div>;
};

interface IToolbarButtonProps {
  icon?: IconProp;
  text?: string;
  disabled?: boolean;
  onActivate?: () => void;
}
const ToolbarButton: React.FunctionComponent<IToolbarButtonProps> = (props) => {
  return (
    <button
      disabled={props.disabled}
      className="ToolbarButton"
      onClick={(e) => {
        if (props.onActivate) props.onActivate();
        e.preventDefault();
      }}
    >
      {props.icon !== undefined && (
        <FontAwesomeIcon size="lg" className="icon" icon={props.icon} />
      )}
      {props.text !== undefined && (
        <span className="icon-text">{props.text}</span>
      )}
    </button>
  );
};

export const ZoomToolbar: React.FunctionComponent<IZoomToolbarProps> = (
  props
) => {
  const zoomAmount = 0.5;
  return (
    <div
      className="ZoomToolbar"
      style={{ bottom: -props.workspaceSize.height + 16 }}
    >
      <Toolbar>
        <ToolbarButton
          icon={faCompress}
          onActivate={() => props.api.zoomToFit()}
        ></ToolbarButton>
      </Toolbar>
      <Toolbar>
        <ToolbarButton
          disabled={props.scale === props.minScale}
          icon={faMinus}
          onActivate={() =>
            props.api.zoom(
              -zoomAmount,
              props.workspaceSize.width * 0.5,
              props.workspaceSize.height * 0.5
            )
          }
        ></ToolbarButton>
        <ToolbarButton
          text={`${Math.round(props.scale * 100.0)}%`}
        ></ToolbarButton>
        <ToolbarButton
          disabled={props.scale === props.maxScale}
          icon={faPlus}
          onActivate={() =>
            props.api.zoom(
              zoomAmount,
              props.workspaceSize.width * 0.5,
              props.workspaceSize.height * 0.5
            )
          }
        ></ToolbarButton>
      </Toolbar>
    </div>
  );
};
