export type Color = {
  r: number;
  g: number;
  b: number;
  a: number;
};

export function hexToColor(hexColor: string): Color {
  return {
    r: parseInt(hexColor.substring(1, 3), 16) / 255.0,
    g: parseInt(hexColor.substring(3, 5), 16) / 255.0,
    b: parseInt(hexColor.substring(5, 7), 16) / 255.0,
    a: parseInt(hexColor.substring(7, 9), 16) / 255.0,
  };
}

export function hexToTintAndAlpha(color: string): [number, number] {
  const tint = parseInt(color.substring(1, 7), 16);
  const alpha = parseInt(color.substring(7), 16) / 255.0;
  return [tint, alpha];
}

export function colorEquals(a: Color, b: Color) {
  return a.r === b.r && a.g === b.g && a.b === b.b && a.a === b.a;
}
