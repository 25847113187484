import { message, Modal } from "antd";
import Axios from "axios";
import { CanvasByIdMap } from "./components/CanvasesProvider";
import { FolderByIdMap } from "./components/FoldersProvider";
import { ICanvas, IFolder, IResource } from "./interfaces";

export function patchServerConfig(payload: any): void {
  Axios.patch("/api/dashboard/server-config", payload)
    .then((_response) => {
      message.success("Settings saved.");
    })
    .catch((error) => {
      console.error("Failed to save changes:", error.response);
      const msgFromServer = error.response?.data?.msg;
      message.error(`Failed to save changes. (${msgFromServer})`);
    });
}

export function patchCanvas(
  canvas: ICanvas,
  successMessage: string,
  failureMessage: string
) {
  const url = `/api/dashboard/canvases/${canvas.id}`;

  Axios.patch(url, canvas)
    .then((_response) => {
      message.success(successMessage);
    })
    .catch((error) => {
      console.error("Failed to PATCH canvas:", error.response);
      const msgFromServer = error.response?.data?.msg;
      message.error(failureMessage + ` (${msgFromServer})`);
    });
}

export function patchFolder(
  folder: IFolder,
  successMessage: string,
  failureMessage: string
) {
  const url = `/api/dashboard/canvas-folders/${folder.id}`;

  Axios.patch(url, folder)
    .then((_response) => {
      message.success(successMessage);
    })
    .catch((error) => {
      console.error("Failed to PATCH folder:", error.response);
      const msgFromServer = error.response?.data?.msg;
      message.error(failureMessage + ` (${msgFromServer})`);
    });
}

export type ConflictResolutionType = "skip" | "cancel" | "replace";
export type MoveType = "copy" | "move";
export type ResourceType = "canvases" | "canvas-folders";

export async function moveResource(
  resourceType: ResourceType,
  moveType: MoveType,
  conflictResolution: ConflictResolutionType,
  resourceToMove: string,
  destination: string
) {
  const url = `/api/dashboard/${resourceType}/${resourceToMove}/${moveType}`;
  const payload = { folder_id: destination, conflicts: conflictResolution };

  return Axios.post(url, payload);
}

export async function moveFolder(
  folder: IFolder,
  newFolderId: string,
  moveType: MoveType,
  conflicts: ConflictResolutionType
): Promise<void> {
  await moveResource(
    "canvas-folders",
    moveType,
    conflicts,
    folder.id,
    newFolderId
  );
}

export async function moveCanvas(
  canvas: ICanvas,
  newFolderId: string,
  moveType: MoveType,
  conflicts: ConflictResolutionType
): Promise<void> {
  await moveResource("canvases", moveType, conflicts, canvas.id, newFolderId);
}

// Do a generic POST request to given resource path
export function genericPostResource(
  resourcePath: string,
  payload: any,
  successMessage: string,
  failureMessage: string
) {
  const url = `/api/dashboard/${resourcePath}`;

  Axios.post(url, payload)
    .then((_response) => {
      message.success(successMessage);
    })
    .catch((error) => {
      console.error(failureMessage, error.response);
      const msgFromServer = error.response?.data?.msg;
      message.error(failureMessage + ` (${msgFromServer})`);
    });
}

// Moves the given resource to a target folder. Provides modal conflict
// resolution UI if needed.
export function moveResourceWithConflictResolutionModal(
  targetFolderId: string,
  resourceId: string,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
) {
  const isCanvas = canvases.has(resourceId);
  const prefix = isCanvas ? "canvases" : "canvas-folders";

  // Try to move, but cancel if there is conflict
  moveResource(prefix, "move", "cancel", resourceId, targetFolderId)
    .then(() => {
      message.info("Moved");
    })
    .catch((error) => {
      // Handle conflicts
      const response = error.response;
      if (response.status === 409) {
        // Try to generate some informative message to the user
        const resourceName =
          canvases.get(resourceId)?.name || folders.get(resourceId)?.name;
        const msg = `A resource named '${resourceName}' already exists in this location. Do you want to replace it with the one you're moving?`;

        Modal.confirm({
          title: "Replace resource?",
          content: msg,
          okText: "Replace",
          okButtonProps: { danger: true },
          onOk: () => {
            const hideProgressMsg = message.loading("Replacing resource...", 0);
            moveResource(prefix, "move", "replace", resourceId, targetFolderId)
              .catch((error) => {
                const serverReply = error.response?.data?.msg;
                message.error(`Failed to move folder: ${serverReply}`);
              })
              .finally(() => {
                hideProgressMsg();
              });
            // Close modal
            return false;
          },
        });
      } else {
        const serverReply = error.response?.data?.msg;
        message.error(`Move failed: ${serverReply}`);
      }
    });
}

export async function createCanvas(
  name: string,
  folderId: string
): Promise<ICanvas> {
  const url = `/api/dashboard/canvases`;
  const payload = { folder_id: folderId, name: name };

  try {
    const response = await Axios.post(url, payload);

    return response.data;
  } catch (error: any) {
    const serverReply = error.response?.data?.msg;

    return Promise.reject(serverReply);
  }
}

export async function createFolder(
  name: string,
  folderId: string
): Promise<IFolder> {
  const url = `/api/dashboard/canvas-folders`;
  const payload = { folder_id: folderId, name: name };

  try {
    const response = await Axios.post(url, payload);

    return response.data;
  } catch (error: any) {
    const serverReply = error.response?.data?.msg;

    return Promise.reject(serverReply);
  }
}

export async function deleteResource(
  resourceType: ResourceType,
  resource: IResource
) {
  const url = `/api/dashboard/${resourceType}/${resource.id}`;

  return Axios.delete(url);
}

export async function deleteCanvas(canvas: ICanvas) {
  return deleteResource("canvases", canvas);
}

export async function deleteFolder(folder: IFolder) {
  return deleteResource("canvas-folders", folder);
}

export async function duplicateResource(
  resourceType: ResourceType,
  resource: IResource
) {
  const url = `/api/dashboard/${resourceType}/${resource.id}/copy`;

  const payload = { folder_id: resource.folder_id };

  return Axios.post(url, payload);
}

export async function duplicateCanvas(resource: ICanvas) {
  return duplicateResource("canvases", resource);
}

export async function duplicateFolder(resource: IFolder) {
  return duplicateResource("canvas-folders", resource);
}
