import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Statistic,
  Typography,
  Form,
  Input,
  Button,
  Upload,
  message,
  Alert,
} from "antd";
import AdminLayout from "./AdminLayout";
import Axios from "axios";
import { IServerLicense } from "../../interfaces";
import { PageLoading } from "../../components/PageLoading";
import "./AdminSettingsLicensePage.less";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { subscribe, unsubscribe } from "../../backend";
import { downloadTextFile } from "../../util";
import dayjs, { Dayjs } from "dayjs";

export const AdminSettingsLicensePage: React.FunctionComponent = () => {
  return (
    <AdminLayout selectedSideMenuKey="settings.license">
      <LicenseInfo />
      <ActivateLicense />
      <UploadLicense />
      <OfflineActivationRequest />
    </AdminLayout>
  );
};

function expiresIn(expiryDate: Dayjs) {
  const today = dayjs();
  const diff = expiryDate.diff(today, "day");

  return diff;
}

function expiryWarning(license: IServerLicense): JSX.Element {
  // Perpetual licenses have no valid_until
  if (license.valid_until === undefined) return <></>;

  const expires = expiresIn(dayjs(license.valid_until));

  if (expires > 30) return <></>;

  if (expires > 0)
    return (
      <Typography.Paragraph>
        <Alert
          message="License about to expire"
          description={`The Canvus Connect server license will expire in ${expires} days.`}
          type="warning"
          showIcon
          closable
        />
      </Typography.Paragraph>
    );

  return (
    <Typography.Paragraph>
      <Alert
        message="License has expired"
        description={`The Canvus Connect server license has expired.`}
        type="error"
        showIcon
        closable
      />
    </Typography.Paragraph>
  );
}

function editionStat(license: IServerLicense) {
  if (license.edition === "") return <></>;

  return (
    <Col span="6">
      <Statistic title="Edition" value={license.edition} />
    </Col>
  );
}

function maxClientsStat(license: IServerLicense) {
  const value = license.max_clients === -1 ? "Unlimited" : license.max_clients;

  return (
    <Col span="6">
      <Statistic title="Maximum clients" value={value} />
    </Col>
  );
}

function validUntilStat(license: IServerLicense) {
  const value =
    license.valid_until === undefined
      ? "Perpetuity"
      : new Date(license.valid_until).toLocaleDateString();

  return (
    <Col span="6">
      <Statistic title="Valid until" value={value} />
    </Col>
  );
}

export const LicenseInfo: React.FunctionComponent = () => {
  const [currentLicense, setCurrentLicense] = useState<IServerLicense>();

  // Subscribe to monitor license changes
  useEffect(() => {
    function handleLicenseChange(license: IServerLicense) {
      setCurrentLicense(license);
    }

    const endpoint = "/license";

    subscribe(endpoint, handleLicenseChange);

    return function cleanup() {
      unsubscribe(endpoint, handleLicenseChange);
    };
  }, []);

  if (currentLicense === undefined) {
    return (
      <Card title="License">
        <PageLoading />
      </Card>
    );
  }

  const noLicense =
    currentLicense.has_expired === false && currentLicense.is_valid === false;

  if (noLicense) {
    return (
      <Typography.Paragraph>
        <Alert
          message="No valid license"
          description={`The Canvus Connect server has no valid license.`}
          type="error"
          showIcon
        />
      </Typography.Paragraph>
    );
  }

  return (
    <>
      {expiryWarning(currentLicense)}
      <Card title="License">
        <Row>
          {editionStat(currentLicense)}
          {maxClientsStat(currentLicense)}
          {validUntilStat(currentLicense)}
        </Row>
      </Card>
    </>
  );
};

export const ActivateLicense: React.FunctionComponent = () => {
  function submit(values: any) {
    const key = values.key;
    const url = `/api/dashboard/license/activate?key=${key}`;

    Axios.post(url)
      .then((_response) => {
        message.success("License activated.");
      })
      .catch((error) => {
        const msgFromServer = error.response?.data?.msg;

        console.error("Failed to activate license", error.response.data);
        message.error(`Failed to activate license: ${msgFromServer}`);
      });
  }

  return (
    <Card title="Activate license">
      <Typography.Paragraph>
        If you have an activation key for Canvus Connect server, enter it below
        to activate a license.
      </Typography.Paragraph>

      <Form name="activate-license" onFinish={submit}>
        <Form.Item
          label="Activation key:"
          name="key"
          rules={[
            {
              required: true,
              type: "string",
              message: "Activation key is required.",
            },
            {
              pattern: /^([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{4}$/,
              message: "Invalid activation key",
            },
          ]}
        >
          <Input placeholder="XXXX-XXXX-XXXX-XXXX" maxLength={19} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Activate license
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export const UploadLicense: React.FunctionComponent = () => {
  // https://stackoverflow.com/questions/55238215/ant-design-upload-get-file-content
  const uploadProps = {
    showUploadList: false,
    beforeUpload: (file: any) => {
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result as string;
        let license = "";

        try {
          license = JSON.parse(content);
        } catch (error) {
          console.log("Failed to parse license");
          message.error("Failed to parse license");
          return;
        }

        const payload = { license: license };
        Axios.post("/api/dashboard/license", payload)
          .then((_response) => {
            message.success("License was successfully installed.");
          })
          .catch((error) => {
            const msgFromServer = error.response?.data?.msg;

            console.error("Failed to install license", error.response.data);
            message.error(`Failed to install license: ${msgFromServer}`);
          });
      };
      reader.readAsText(file);

      // Prevent actual upload
      return false;
    },
  };

  return (
    <Card title="Upload license">
      <Typography.Paragraph>
        If you have a Canvus Connect server license file (.cslicense) you can
        upload it here.
      </Typography.Paragraph>
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Upload license</Button>
      </Upload>
    </Card>
  );
};

export const OfflineActivationRequest: React.FunctionComponent = () => {
  function downloadActivationRequest(values: any) {
    const activationKey = values.key;
    const url = `/api/dashboard/license/request?key=${activationKey}`;

    Axios.get(url)
      .then((res) => {
        const content = JSON.stringify(res.data, null, 2);
        downloadTextFile("request.json", content);
      })
      .catch((err) => {
        console.error("Failed to download activation request", err);
        message.error("Failed to download activation request.");
      });
  }

  return (
    <Card title="Offline activation request">
      <Typography.Paragraph>
        If this server has no internet access and you can not activate a license
        using an activation key, you can generate an offline activation request
        here. Contact MultiTaction support with the license request for further
        instructions.
      </Typography.Paragraph>

      <Form name="offline-license-request" onFinish={downloadActivationRequest}>
        <Form.Item
          label="Activation key:"
          name="key"
          rules={[
            {
              required: true,
              type: "string",
              message: "Activation key is required.",
            },
            {
              pattern: /^([a-zA-Z0-9]{4}-){3}[a-zA-Z0-9]{4}$/,
              message: "Invalid activation key",
            },
          ]}
        >
          <Input placeholder="XXXX-XXXX-XXXX-XXXX" maxLength={19} />
        </Form.Item>
        <Form.Item>
          <Button icon={<DownloadOutlined />} type="primary" htmlType="submit">
            Download activation request
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
