import { IPublicServerConfig } from "../interfaces";
import Axios from "axios";
import { Rule } from "antd/lib/form";

let cachedServerConfig: IPublicServerConfig | undefined = undefined;

function isNumber(ch: string): boolean {
  return !isNaN(Number(ch));
}

function isLetter(ch: string): boolean {
  return ch.toLowerCase() !== ch.toUpperCase();
}

function isLetterOrNumber(ch: string): boolean {
  return isLetter(ch) || isNumber(ch);
}

function check(password: string, config: IPublicServerConfig): void {
  const pwd = config.authentication.password;

  if (pwd.min_length > password.length) {
    throw new Error(
      `Password is less than required length of ${pwd.min_length}`
    );
  }

  let countLower = 0;
  let countUpper = 0;
  let countNumeric = 0;
  let countSymbol = 0;
  let maxRepeat = 0;
  let maxSequenceAsc = 0;
  let maxSequenceDesc = 0;
  let currentRepeat = 0;
  let currentSequenceAsc = 0;
  let currentSequenceDesc = 0;

  for (let i = 0; i < password.length; i++) {
    const ch = password[i];
    if (isLetter(ch)) {
      if (ch.toLowerCase() === ch) {
        countLower++;
      } else if (ch.toUpperCase() === ch) {
        countUpper++;
      }
    } else if (isNumber(ch)) {
      countNumeric++;
    } else {
      countSymbol++;
    }

    if (i > 0) {
      const chPrev = password[i - 1];
      if (ch === chPrev) {
        currentSequenceAsc = 0;
        currentSequenceDesc = 0;
        currentRepeat++;
        maxRepeat = Math.max(maxRepeat, currentRepeat);
      } else {
        currentRepeat = 0;

        // Check only for sequence of alphanumeric characters
        if (!isLetterOrNumber(ch) || !isLetterOrNumber(chPrev)) {
          currentSequenceAsc = 0;
          currentSequenceDesc = 0;
          continue;
        }

        const chCode = password.charCodeAt(i);
        const chPrevCode = password.charCodeAt(i - 1);
        if (chCode === chPrevCode + 1) {
          currentSequenceAsc++;
          currentSequenceDesc = 0;
          maxSequenceAsc = Math.max(maxSequenceAsc, currentSequenceAsc);
        } else if (chCode === chPrevCode - 1) {
          currentSequenceAsc = 0;
          currentSequenceDesc++;
          maxSequenceDesc = Math.max(maxSequenceDesc, currentSequenceDesc);
        } else {
          currentSequenceAsc = 0;
          currentSequenceDesc = 0;
        }
      }
    }
  }

  if (pwd.min_lowercase !== 0 && pwd.min_lowercase > countLower) {
    throw new Error(
      `password has fewer lower case characters ${countLower} than required ${pwd.min_lowercase}`
    );
  }
  if (pwd.min_uppercase !== 0 && pwd.min_uppercase > countUpper) {
    throw new Error(
      `password has fewer upper case characters ${countUpper} than required ${pwd.min_uppercase}`
    );
  }
  if (pwd.min_numeric !== 0 && pwd.min_numeric > countNumeric) {
    throw new Error(
      `password has fewer numeric characters ${countNumeric} than required ${pwd.min_numeric}`
    );
  }
  if (pwd.min_symbol !== 0 && pwd.min_symbol > countSymbol) {
    throw new Error(
      `password has fewer symbols ${countSymbol} than required ${pwd.min_symbol} `
    );
  }
  if (pwd.max_repeats !== 0 && pwd.max_repeats <= maxRepeat) {
    throw new Error(
      `password has more than ${pwd.max_repeats} repeated characters`
    );
  }
  if (
    pwd.max_sequence !== 0 &&
    (pwd.max_sequence <= maxSequenceAsc || pwd.max_sequence <= maxSequenceDesc)
  ) {
    throw new Error(
      `password has a character sequence exceeding ${pwd.max_sequence} characters`
    );
  }
}

// This validator treats empty string or undefined password
// as valid. In that case password is not checked against the rules.
async function passwordValidator(_: Rule, password?: string): Promise<void> {
  if (!password) {
    return Promise.resolve();
  }
  try {
    if (!cachedServerConfig) {
      const _res = await Axios.get("/api/dashboard/server-config");
      cachedServerConfig = _res.data;
    }

    check(password, cachedServerConfig as IPublicServerConfig);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
}

const passwordRule: Rule = {
  type: "string",
  validator: passwordValidator,
};

export default passwordRule;
