import React, { useEffect, useReducer, Reducer, useState } from "react";
import { createContext, useContext } from "react";
import { subscribe, unsubscribe } from "../backend";
import { ICanvas } from "../interfaces";

export type CanvasByIdMap = Map<string, ICanvas>;

export interface ICanvasesContext {
  isLoading: boolean;
  canvases: CanvasByIdMap;
}

const initialValue: ICanvasesContext = {
  isLoading: true,
  canvases: new Map(),
};

// Apply given change to a map of canvases
/// TODO: this could be a generic function that is re-used in all providers
function mutateCanvases(canvases: CanvasByIdMap, change: ICanvas) {
  if (change.state === "deleted") {
    canvases.delete(change.id);
  } else {
    canvases.set(change.id, change);
  }
}

export const CanvasesContext = createContext<ICanvasesContext>(initialValue);

const CanvasesReducer: Reducer<CanvasByIdMap, ICanvas[]> = (
  prevState,
  changes
) => {
  // Must make a copy and not modify prevState
  let state = new Map(prevState);

  changes.forEach((change) => {
    mutateCanvases(state, change);
  });

  return state;
};

// Provides a flat array of all canvases (the user can access) on the server
const CanvasesProvider: React.FunctionComponent = (children) => {
  const [state, dispatch] = useReducer(CanvasesReducer, new Map());

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const endpoint = "/canvases";

    function handleCanvasesChange(changes: ICanvas[]) {
      dispatch(changes);
      setIsLoading(false);
    }

    subscribe(endpoint, handleCanvasesChange);

    return function cleanup() {
      unsubscribe(endpoint, handleCanvasesChange);
    };
  }, []);

  const contextData: ICanvasesContext = {
    isLoading: isLoading,
    canvases: state,
  };

  return <CanvasesContext.Provider value={contextData} {...children} />;
};

export const useCanvasesContext = () => useContext(CanvasesContext);

export default CanvasesProvider;
