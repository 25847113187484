import { Image, Row, Tag } from "antd";
import { ICanvas } from "../../interfaces";

export interface ICanvasPreviewProps {
  external_url: string;
  canvas: ICanvas;
  tags: boolean;
}

// Provides a static preview image of a canvas.
export const CanvasPreview: React.FunctionComponent<ICanvasPreviewProps> = (
  props
) => {
  // The preview_hash is ignored by the API, but used to ensure the React
  // component does live updates
  const previewImageUrl = `/api/dashboard/canvases/${props.canvas.id}/preview?${props.canvas.preview_hash}`;

  const tags = props.tags ? (
    <Row className="tags">
      {props.canvas.mode === "demo" && <Tag color="blue">demo</Tag>}
    </Row>
  ) : undefined;

  return (
    <div className="canvas resource-preview">
      <Image
        preview={false}
        src={previewImageUrl}
        fallback="/no-canvas-preview.svg"
      />
      {tags}
    </div>
  );
};
