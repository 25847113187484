import { message } from "antd";
import { useRef } from "react";
import { useDrag } from "react-dnd";
import { deleteCanvas, moveCanvas } from "../../api";
import { useAuthDataContext } from "../../components/AuthProvider";
import { useCanvasesContext } from "../../components/CanvasesProvider";
import { useFoldersContext } from "../../components/FoldersProvider";
import { DragAndDropTypes } from "../../constants";
import { isDragAllowed } from "../../dragAndDrop";
import { ICanvas, IFolder } from "../../interfaces";
import { IPreviewTileCommonProps } from "./PreviewTileFolder";
import { CanvasPreview } from "./CanvasPreview";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { menuForCanvas } from "./ResourceDropdownMenu";
import { TileHeader } from "./TileHeader";

dayjs.extend(RelativeTime);

export interface IPreviewTileCanvasProps extends IPreviewTileCommonProps {
  external_url: string;
  canvas: ICanvas;
}

// A tile that provides a preview for a canvas. Used to visualize canvases in a
// grid layout.
export const PreviewTileCanvas: React.FunctionComponent<IPreviewTileCanvasProps> =
  (props) => {
    const { user } = useAuthDataContext();
    const { canvases } = useCanvasesContext();
    const { folders } = useFoldersContext();

    const ref = useRef(null);

    // You can only drag canvases, not drop anything on them
    const [, drag] = useDrag(
      () => ({
        type: DragAndDropTypes.Canvas,
        item: { id: props.canvas.id },
        canDrag: () => {
          return isDragAllowed(props.canvas.id, user, canvases, folders);
        },
      }),
      [props.canvas, user, canvases, folders]
    );

    drag(ref);

    const parentFolder = folders.get(props.canvas.folder_id) as IFolder;

    const menu = menuForCanvas(
      props.canvas,
      user,
      props.external_url,
      props.modal,
      parentFolder
    );

    const invalidTimestamp = undefined;

    const lastModified =
      props.canvas.modified_at === invalidTimestamp
        ? "N/A"
        : dayjs(props.canvas.modified_at).fromNow();

    const isSelected = props.selectedResources.has(props.canvas.id);
    const isMultiSelect = props.selectedResources.size > 1;

    const isDropdownVisible = (isSelected && isMultiSelect) === false;

    return (
      <div
        ref={ref}
        className={`tile canvas${isSelected ? " selected" : ""}`}
        data-resource-id={props.canvas.id}
        onClick={(event) => {
          // Stop propagation to avoid triggering handlers in ancestors that
          // would clear selection
          event.stopPropagation();
          props.onClickResource(event, props.canvas.id);
        }}
        onDoubleClick={(event) => {
          // Stop propagation to avoid triggering handlers in ancestors that
          // would clear selection
          event.stopPropagation();
          props.onDoubleClickResource(event, props.canvas.id);
        }}
      >
        <TileHeader
          title={props.canvas.name}
          lastModified={lastModified}
          menu={menu}
          isDropdownVisible={isDropdownVisible}
        />
        <CanvasPreview
          canvas={props.canvas}
          external_url={props.external_url}
          tags={true}
        />
      </div>
    );
  };

export function deleteCanvasWithConfirm(
  resource: ICanvas,
  trashFolderId: string,
  modal: Omit<ModalStaticFunctions, "warn">
) {
  // Handle trashed resource differently
  if (resource.in_trash) {
    modal.confirm({
      okText: "Delete",
      content: `Permanently delete canvas '${resource.name}'?`,
      okButtonProps: { danger: true },
      onOk: () => {
        deleteCanvas(resource)
          .then(() => {
            message.success(`Canvas '${resource.name}' was deleted.`);
          })
          .catch((error) => {
            console.error("Failed to delete canvas:", error.response);
            const msgFromServer = error.response?.data?.msg;
            message.error(
              `Failed to delete canvas '${resource.name}'. (${msgFromServer})`
            );
          });
        return false;
      },
    });
  } else {
    modal.confirm({
      okText: "Trash",
      content: `Move canvas '${resource.name}' to trash?`,
      okButtonProps: { danger: true },
      onOk: () => {
        moveCanvas(resource, trashFolderId, "move", "replace")
          .then(() => {
            message.success(`Canvas '${resource.name}' was moved to trash.`);
          })
          .catch((error) => {
            console.error(
              `Failed to move canvas '${resource.name}' to trash:`,
              error.response
            );
            const msgFromServer = error.response?.data?.msg;
            message.error(
              `Failed to move canvas '${resource.name}' to trash. (${msgFromServer})`
            );
          });
        return false;
      },
    });
  }
}
