import React from "react";
import { Spin } from "antd";

import "./PageLoading.less";

// Component that can be used to indicate a page is loading.
export const PageLoading: React.FunctionComponent = () => {
  return (
    <div className="page-loading">
      <Spin size="large" />
    </div>
  );
};

export default PageLoading;
