import * as PIXI from "pixi.js";

import { Vec2 } from "./math/vec";
import { WebClient } from "./web-client";
import { canvasItemBounds } from "./widgets/canvas";

type ViewHistoryPoint = {
  timeMs: number;
  x: number;
  y: number;
};

export class Navigation {
  private history: Array<ViewHistoryPoint> = [];
  private dx = 0;
  private dy = 0;
  private app: WebClient;

  static fpms = PIXI.settings.TARGET_FPMS || 60 / 1000.0;

  private anim = 0;

  private minScale_ = 0.1;
  maxScale = 10.0;

  constructor(app: WebClient) {
    this.app = app;
  }

  get minScale() {
    return this.app.isPresenting() ? 0.01 : this.minScale_;
  }

  resized() {
    if (this.app.canvas) {
      const screen = this.app.pixi.screen;
      const canvas = this.app.canvas.bbox;
      const sx = screen.width / (canvas.right - canvas.left);
      const sy = screen.height / (canvas.bottom - canvas.top);

      this.minScale_ = Math.max(0.1, Math.min(sx, sy));
      if (this.app.view.scale.x < this.minScale) {
        this.setScale(this.minScale, screen.right / 2, screen.bottom / 2, 0);
      } else {
        this.fixView(0);
      }
    }
  }

  // Returns vector of how much the view needs to be moved to achieve valid viewpoint
  private viewFixDiff(): Vec2 {
    const canvas = this.app.canvas;
    if (!canvas) return { x: 0, y: 0 };

    const view = this.app.view;
    view.transform.updateLocalTransform();
    canvas.host.transform.updateLocalTransform();
    view.transform.updateTransform(view.parent.transform);
    canvas.host.transform.updateTransform(view.transform);

    const screen = this.app.pixi.screen;
    const bbox = canvas.bbox;

    const wm = canvas.host.worldTransform;
    const leftTop = wm.apply({ x: bbox.left, y: bbox.top });
    const rightBottom = wm.apply({ x: bbox.right, y: bbox.bottom });

    const ld = leftTop.x - screen.left;
    const rd = rightBottom.x - screen.right;
    const left = -Math.max(ld, rd);
    const right = -Math.min(ld, rd);

    const diff = { x: 0, y: 0 };
    if (left > 0) {
      diff.x = left;
    } else if (right < 0) {
      diff.x = right;
    }

    const td = leftTop.y - screen.top;
    const bd = rightBottom.y - screen.bottom;
    const top = -Math.max(td, bd);
    const bottom = -Math.min(td, bd);

    if (top > 0) {
      diff.y = top;
    } else if (bottom < 0) {
      diff.y = bottom;
    }
    return diff;
  }

  private fixView(animationTime = 0.5) {
    this.dx = 0;
    this.dy = 0;

    const diff = this.viewFixDiff();
    if (diff.x !== 0 || diff.y !== 0) {
      const view = this.app.view;
      this.animateTo(
        view.x + diff.x,
        view.y + diff.y,
        view.scale.x,
        animationTime,
        false
      );
    }
  }

  private isViewValid() {
    const diff = this.viewFixDiff();
    return Math.abs(diff.x) < 0.5 && Math.abs(diff.y) < 0.5;
  }

  addListeners(root: HTMLDivElement) {
    const pointers = new Map<number, PointerEvent>();

    const update = (dt: number) => {
      if (!this.isViewValid()) {
        this.app.view.transform.updateLocalTransform();
        this.fixView();
        return;
      }

      if (Math.max(Math.abs(this.dx), Math.abs(this.dy)) * dt < 0.2) {
        this.app.pixi.ticker.remove(update);
        return;
      }

      dt /= Navigation.fpms;
      const dts = dt / 1000.0;
      const damping = Math.pow(0.01, dts);
      this.dx *= damping;
      this.dy *= damping;
      this.app.view.x += this.dx * dt;
      this.app.view.y += this.dy * dt;
      this.app.setNavigationDirty();
    };

    const addHistory = (e: Event, view: PIXI.Container, replace: boolean) => {
      this.app.pixi.ticker.remove(update);
      const p = { timeMs: e.timeStamp, x: view.x, y: view.y };
      if (replace) {
        this.history = [p];
      } else {
        while (
          this.history.length > 0 &&
          e.timeStamp - this.history[0].timeMs > 50
        )
          this.history.shift();
        this.history.push(p);
      }
    };

    const historyContinue = (e: Event) => {
      if (!this.isViewValid()) {
        this.fixView();
        return;
      }

      if (this.history.length > 1) {
        const first = this.history[0];
        const last = this.history[this.history.length - 1];
        const dt = last.timeMs - first.timeMs;
        this.dx = (last.x - first.x) / dt;
        this.dy = (last.y - first.y) / dt;
        update((e.timeStamp - last.timeMs) * Navigation.fpms);
        this.app.pixi.ticker.add(update);
      }
    };

    const onWheel = (e: WheelEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (this.app.isPresenting()) return;

      const view = this.app.view;

      // Touchpad pinch gesture sends a fake ctrl key
      if (e.ctrlKey || e.altKey) {
        const maxDiff = 0.5;
        const diff = Math.min(maxDiff, Math.max(-maxDiff, e.deltaY / -50.0));
        this.setScaleRelative(diff, e.offsetX, e.offsetY, 0);
      } else {
        view.x -= e.deltaX;
        view.y -= e.deltaY;
      }
      this.fixView(0.0);
      this.history = [];
      this.app.pixi.ticker.remove(update);

      this.app.setNavigationDirty();
      this.app.easing.remove(this.anim);
    };

    const onPointerDown = (e: PointerEvent) => {
      if (this.app.isPresenting()) return;
      if (pointers.size === 0) {
        addHistory(e, this.app.view, true);
      }
      pointers.set(e.pointerId, e);
      this.app.easing.remove(this.anim);
    };

    const handleMouseEvent = (
      view: PIXI.Container,
      e: PointerEvent,
      movementX: number,
      movementY: number
    ) => {
      if ((e.buttons & (2 | 4)) !== 0) {
        view.x += movementX;
        view.y += movementY;
        addHistory(e, view, false);
        this.app.setNavigationDirty();
        this.app.easing.remove(this.anim);
        this.app.pixi.view.style.cursor = "grabbing";
      } else {
        this.app.pixi.view.style.cursor = "";
        if (e.buttons !== 0) {
          this.history = [];
          this.app.easing.remove(this.anim);
        }
      }
    };

    const handleTouchEvent = (
      view: PIXI.Container,
      e: PointerEvent,
      old: PointerEvent,
      movementX: number,
      movementY: number
    ) => {
      let distanceBefore = 0.0;
      let distanceAfter = 0.0;
      let cx = e.x;
      let cy = e.y;
      let touchEventCount = 1;

      pointers.forEach((e2) => {
        if (e2.pointerType !== "touch") return;
        if (e2.pointerId === e.pointerId) return;

        let dx = e2.x - old.x;
        let dy = e2.y - old.y;
        distanceBefore += Math.sqrt(dx * dx + dy * dy);

        dx = e2.x - e.x;
        dy = e2.y - e.y;
        distanceAfter += Math.sqrt(dx * dx + dy * dy);

        cx += e2.x;
        cy += e2.y;
        touchEventCount++;
      });

      view.x += movementX / touchEventCount;
      view.y += movementY / touchEventCount;

      if (touchEventCount > 1) {
        cx /= touchEventCount;
        cy /= touchEventCount;
        const diff = distanceAfter / distanceBefore;
        this.setScale(view.scale.x * diff, cx, cy, 0);
        this.history = [];
      } else {
        addHistory(e, view, false);
      }

      this.app.easing.remove(this.anim);
      this.app.setNavigationDirty();
    };

    const onPointerMove = (e: PointerEvent) => {
      if (this.app.isPresenting()) return;

      const old = pointers.get(e.pointerId);
      if (old === undefined) return;

      pointers.set(e.pointerId, e);

      /// e.movementX/Y have issues and different browsers have different coordinate system:
      /// https://github.com/w3c/pointerlock/issues/42, so calculate the diff manually
      const movementX = e.x - old.x;
      const movementY = e.y - old.y;

      const view = this.app.view;

      if (e.pointerType === "mouse") {
        handleMouseEvent(view, e, movementX, movementY);
      } else if (e.pointerType === "touch") {
        handleTouchEvent(view, e, old, movementX, movementY);
      } else {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
    };

    const onPointerUp = (e: PointerEvent) => {
      if (!pointers.delete(e.pointerId)) return;

      this.app.easing.remove(this.anim);
      if (pointers.size === 0) {
        this.app.pixi.view.style.cursor = "";
        if (
          this.history.length > 0 &&
          e.timeStamp - this.history[this.history.length - 1].timeMs > 50
        ) {
          this.history = [];
        }
        if (this.app.isPresenting()) return;

        historyContinue(e);
      }
    };

    // Disable right-click so that rmb-drag works when panning the canvas
    const onContextMenu = (e: Event) => {
      e.preventDefault();
    };

    root.addEventListener("wheel", onWheel);
    root.addEventListener("pointerdown", onPointerDown);
    root.addEventListener("pointermove", onPointerMove);
    root.addEventListener("pointerup", onPointerUp);
    root.addEventListener("pointercancel", onPointerUp);
    root.addEventListener("pointerleave", onPointerUp);
    root.addEventListener("contextmenu", onContextMenu);

    return () => {
      root.removeEventListener("contextmenu", onContextMenu);
      root.removeEventListener("pointerleave", onPointerUp);
      root.removeEventListener("pointercancel", onPointerUp);
      root.removeEventListener("pointerup", onPointerUp);
      root.removeEventListener("pointermove", onPointerMove);
      root.removeEventListener("pointerdown", onPointerDown);
      root.removeEventListener("wheel", onWheel);
    };
  }

  stopMotion() {
    this.history = [];
    this.app.easing.remove(this.anim);
    this.dx = 0;
    this.dy = 0;
  }

  animateTo(
    x: number,
    y: number,
    scale: number,
    secs: number,
    validateView = true
  ) {
    this.stopMotion();
    const view = this.app.view;

    if (secs <= 0.0) {
      view.scale.set(scale);
      view.x = x;
      view.y = y;
      this.app.easing.remove(this.anim);
      this.app.setNavigationDirty();
      if (validateView) {
        this.fixView(0);
      }
      return;
    }

    if (validateView) {
      const src = { x: view.x, y: view.y, scale: view.scale.x };
      view.x = x;
      view.y = y;
      view.scale.set(scale);
      view.transform.updateLocalTransform();
      const d = this.viewFixDiff();
      view.x = src.x;
      view.y = src.y;
      view.scale.set(src.scale);

      x += d.x;
      y += d.y;
    }

    this.anim = this.app.easing.add(view, { x, y, scale }, { time: secs });
  }

  zoomToFit(animationTime = 0.5) {
    const rect = canvasItemBounds(this.app);
    if (rect === undefined) return;

    rect.pad(rect.width * 0.1, rect.height * 0.1);
    this.zoomTo(rect, animationTime);
  }

  // rect is in application coordinate system
  zoomTo(rect: PIXI.Rectangle, animationTime = 0.5) {
    const screen = this.app.pixi.screen;
    const s = Math.min(screen.width / rect.width, screen.height / rect.height);
    const newScale = Math.min(this.maxScale, Math.max(this.minScale, s));
    const x = screen.width * 0.5 - newScale * (rect.x + 0.5 * rect.width);
    const y = screen.height * 0.5 - newScale * (rect.y + 0.5 * rect.height);

    this.animateTo(x, y, newScale, animationTime);
  }

  setScaleRelative(diff: number, x: number, y: number, animationTime = 0.5) {
    const scale = this.app.view.scale.x * Math.pow(2.0, diff);
    this.setScale(scale, x, y, animationTime);
  }

  private setScale(scale: number, x: number, y: number, animationTime = 0.2) {
    const w = this.app.view;
    w.transform.updateLocalTransform();
    const pivotInLocalCoordinates = w.worldTransform.applyInverse(
      new PIXI.Point(x, y)
    );
    const before = w.localTransform.apply(pivotInLocalCoordinates);

    const oldScale = w.scale.x;
    const newScale = Math.min(this.maxScale, Math.max(this.minScale, scale));
    w.scale.set(newScale);

    w.transform.updateLocalTransform();
    const after = w.localTransform.apply(pivotInLocalCoordinates);
    w.scale.set(oldScale);
    w.transform.updateLocalTransform();

    this.animateTo(
      w.x + before.x - after.x,
      w.y + before.y - after.y,
      newScale,
      animationTime
    );
  }
}
