import React, { useState } from "react";
import { Form, Input, Button, Card, message } from "antd";
import { useAuthDataContext } from "../../components/AuthProvider";
import Axios from "axios";
import { formLayout, formTailLayout } from "../FormLayout";
import ProfileLayout from "./ProfileLayout";

async function patchUser(
  user: any,
  successMessage: string,
  failureMessage: string
) {
  const url = `/api/dashboard/users/${user.id}`;

  try {
    await Axios.patch(url, user);

    message.success(successMessage);
  } catch (error) {
    console.error("Failed to PATCH user:", error.response);

    const msg = error.response?.data?.msg;

    message.error(failureMessage + ` (${msg})`);
  }
}

async function changeEmail(userId: number, email: string) {
  const url = `/api/dashboard/users/${userId}/change-email`;
  const payload = { id: userId, new_email: email };

  try {
    await Axios.post(url, payload);

    message.info(
      `An email was sent to ${email} to confirm the address change.`,
      5
    );
  } catch (error) {
    console.error("Failed to POST email change:", error.response);

    const msg = error.response?.data?.msg;
    message.error(`Failed to change email ${msg}`);
  }
}

// Compare two objects for equality by property values. Assumes that a contains
// a (sub)set of b's values. Only works with basic properties.
function isDifferent(a: any, b: any): boolean {
  return Object.keys(a).some((x) => a[x] !== b[x]);
}

export const ProfileAccountPage: React.FunctionComponent = () => {
  return (
    <ProfileLayout selectedSideMenuKey="account">
      <ProfileForm />
    </ProfileLayout>
  );
};

const ProfileForm: React.FunctionComponent = () => {
  const { user } = useAuthDataContext();

  function handleSubmitForm(values: any): void {
    const { name, email } = values;

    if (name !== user.name) {
      const payload = { id: user.id, name: name };

      patchUser(
        payload,
        "Profile settings were updated.",
        "Failed to update profile settings:"
      );
    }

    if (email !== user.email) {
      changeEmail(user.id, email);
    }
  }

  const [valuesChanged, setValuesChanged] = useState<boolean>(false);

  return (
    <>
      <Card title="Edit Profile">
        <Form
          {...formLayout}
          name="profile"
          initialValues={{ name: user.name, email: user.email }}
          onFinish={handleSubmitForm}
          onValuesChange={(changed, all) => {
            setValuesChanged(isDifferent(all, user));
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, type: "string" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button disabled={!valuesChanged} type="primary" htmlType="submit">
              Update profile settings
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
