import React, { useState } from "react";
import { Alert, Button, Card, Form, Input, message, Typography } from "antd";
import AdminLayout from "./AdminLayout";
import Axios, { AxiosError } from "axios";
import { IServerConfig } from "../../interfaces";
import { formLayout, formTailLayout } from "../FormLayout";
import { QueryFailure } from "../../components/QueryFailure";
import PageLoading from "../../components/PageLoading";
import { useMutation, useQuery, useQueryClient } from "react-query";

interface IWrapperProps {
  onAlertClose: () => void;
  errorMessage?: string;
}

const PageWrapper: React.FunctionComponent<IWrapperProps> = (props) => {
  return (
    <AdminLayout selectedSideMenuKey="settings.general">
      {props.errorMessage !== undefined && (
        <Alert
          message="Failed to save settings"
          description={props.errorMessage}
          type="error"
          showIcon
          closable
          onClose={props.onAlertClose}
        />
      )}
      <Card title="General settings">{props.children}</Card>
    </AdminLayout>
  );
};

export const AdminSettingsGeneralPage: React.FunctionComponent = () => {
  const { isLoading, data, error } =
    useQuery<IServerConfig, AxiosError>("/server-config");

  const mutation = useMutation<IServerConfig, AxiosError, any>(
    async (config) => {
      const { data } = await Axios.patch(
        "/api/dashboard/server-config",
        config
      );
      return data;
    }
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const queryClient = useQueryClient();

  function clearError() {
    setErrorMessage(undefined);
  }

  function onSubmit(values: any) {
    mutation.mutate(values, {
      onSuccess: (data) => {
        message.success("Settings saved.");
        clearError();
        queryClient.setQueryData("/server-config", data);
      },
      onError: (error) => {
        const msgFromServer = error.response?.data?.msg;
        setErrorMessage(msgFromServer);
      },
    });
  }

  if (error) {
    return (
      <PageWrapper onAlertClose={clearError}>
        <QueryFailure
          title="Failed to query server configuration"
          error={error}
        />
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper onAlertClose={clearError}>
        <PageLoading />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper errorMessage={errorMessage} onAlertClose={clearError}>
      <GeneralSettings serverConfig={data!} onSubmit={onSubmit} />
    </PageWrapper>
  );
};

interface IProps {
  serverConfig: IServerConfig;
  onSubmit: (values: any) => void;
}

const GeneralSettings: React.FunctionComponent<IProps> = (props) => {
  const config = props.serverConfig;

  function submitForm(values: any): void {
    let payload = { ...values };
    props.onSubmit(payload);
  }

  return (
    <>
      <Typography.Paragraph>
        Configure general settings for the server.
      </Typography.Paragraph>
      <Form
        {...formLayout}
        name="general"
        initialValues={{
          external_url: config.external_url,
          server_name: config.server_name,
        }}
        onFinish={submitForm}
      >
        <Form.Item
          label="External URL"
          name="external_url"
          help={
            <p>
              For Canvus to display correct canvas links to your users, it needs
              to know the URL under which it is reached by your users, e.g.
              https://canvus.example.com.
            </p>
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Server Name"
          name="server_name"
          help={
            <p>
              Server name is used in Canvus as a human-readable name of the
              server.
            </p>
          }
        >
          <Input />
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Button type="primary" htmlType="submit">
            Save settings
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
