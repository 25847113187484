import React from "react";
import { Form, Input, Button, Card, message } from "antd";
import Axios from "axios";

import { formLayout, formTailLayout } from "../FormLayout";
import AdminLayout from "./AdminLayout";
import { useNavigate } from "react-router-dom";

export const AdminNewGroupPage: React.FunctionComponent = () => {
  return (
    <AdminLayout selectedSideMenuKey="groups">
      <NewGroupForm />
    </AdminLayout>
  );
};

const NewGroupForm: React.FunctionComponent = () => {
  const navigate = useNavigate();

  function handleSubmitForm(values: any): void {
    const { name, description } = values;

    Axios.post("/api/dashboard/groups", {
      name,
      description,
    })
      .then((_response) => {
        navigate("/admin/groups");
        message.success(`Created group '${name}'.`);
      })
      .catch((err) => {
        console.error("Failed to create a group:", err.response);
        const msgFromServer = err.response?.data?.msg;
        message.error(`Failed to create a group. (${msgFromServer})`);
      });
  }

  return (
    <>
      <Card title="New Group">
        <Form
          {...formLayout}
          name="new-group"
          initialValues={{}}
          onFinish={handleSubmitForm}
        >
          <Form.Item
            label="Group name"
            name="name"
            rules={[
              {
                required: true,
                type: "string",
                message: "Group name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Description (optional)"
            name="description"
            rules={[
              {
                required: false,
                type: "string",
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit">
              Create group
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
