import * as PIXI from "pixi.js";
import { MipmapInfoEvent } from "../canvas-element-events";
import { gcAdd, gcRemove, Renderer } from "../gc";

type MipmapInfo = {
  width: number;
  height: number;
  maxLevel: number;
};

export class MipmapResource extends PIXI.Resource {
  private publicHashHex: string;
  private page: number;
  private info?: MipmapInfo;
  private loadPromise?: Promise<MipmapResource>;

  // level => last used timestamp
  private levels: Map<number, number> = new Map<number, number>();
  private level?: number;

  private downloadCtrl?: AbortController;

  private bitmapLevel?: number;
  private bitmap?: ImageBitmap;

  private registeredToGc = false;
  private lastUsed = 0;

  private baseTexture?: PIXI.BaseTexture;

  private setDirty: () => void;

  constructor(publicHashHex: string, page: number, setDirty: () => void) {
    super();
    this.publicHashHex = publicHashHex;
    this.page = page;
    this.setDirty = setDirty;
    // TODO: handle errors
    this.load();
  }

  upload(
    renderer: PIXI.Renderer,
    baseTexture: PIXI.BaseTexture,
    glTexture: PIXI.GLTexture
  ): boolean {
    // Always return true to deny TextureSystem touching this texture
    if (
      this.bitmap === undefined ||
      this.bitmapLevel === undefined ||
      this.info === undefined
    )
      return true;

    const gl = renderer.gl;

    gl.pixelStorei(
      gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL,
      baseTexture.alphaMode === PIXI.ALPHA_MODES.UNPACK
    );

    if (
      glTexture.width === this.bitmap.width &&
      glTexture.height === this.bitmap.height
    ) {
      return true;
    }

    glTexture.width = this.bitmap.width;
    glTexture.height = this.bitmap.height;

    gl.texImage2D(
      baseTexture.target!,
      0,
      glTexture.internalFormat,
      baseTexture.format!,
      glTexture.type,
      this.bitmap
    );

    gl.generateMipmap(baseTexture.target!);

    this.setDirty();

    this.baseTexture = baseTexture;

    // We probably don't need the bitmap anymore, and it will consume a lot of
    // memory. Fetching the mipmap from the cache is fast and decompressing it
    // relatively cheap compared to keeping everything in memory all the time.
    this.bitmap.close();
    this.bitmap = undefined;
    this.bitmapLevel = undefined;

    return true;
  }

  gc(renderer: Renderer) {
    if (this.lastUsed < renderer.viewRenderTime) {
      if (this.baseTexture !== undefined) {
        renderer.texture.destroyTexture(this.baseTexture, true);
        this.baseTexture = undefined;
      }
      this.level = undefined;
      if (this.downloadCtrl !== undefined) {
        this.downloadCtrl.abort();
      }
      if (this.bitmap !== undefined) {
        this.bitmap.close();
        this.bitmap = undefined;
        this.bitmapLevel = undefined;
      }

      this.registeredToGc = false;
      gcRemove(this);
    } else if (this.info !== undefined && this.level !== undefined) {
      let minLevel = this.info.maxLevel;
      let minLevelTime = 0;
      for (let [level, time] of this.levels.entries()) {
        if (time >= renderer.viewRenderTime && level < minLevel) {
          minLevel = level;
          minLevelTime = time;
        }
      }
      if (minLevel > this.level && minLevelTime === this.lastUsed) {
        this.level = undefined;
        this.loadLevel(minLevel, performance.now(), false);
      }
    }
  }

  async loadLevel(level: number, now: number, setDirty: boolean) {
    if (this.info === undefined) return;
    if (level < 0) level = 0;
    if (level > this.info.maxLevel) level = this.info.maxLevel;

    this.lastUsed = now;
    this.levels.set(level, now);

    if (level === this.level) return;

    if (this.level !== undefined && level > this.level) return;

    if (this.downloadCtrl !== undefined) {
      this.downloadCtrl.abort();
    }

    this.level = level;
    this.downloadCtrl = new AbortController();
    try {
      const signal = this.downloadCtrl.signal;
      const response = await fetch(
        `/api/v1/mipmaps/${this.publicHashHex}/${level}?page=${this.page}`,
        { signal }
      );
      const blob = await response.blob();
      if (!signal.aborted) {
        const bitmap = await createImageBitmap(blob);
        if (!signal.aborted) {
          this.bitmap = bitmap;
          this.bitmapLevel = level;
          this.update();
          if (setDirty) this.setDirty();
          if (!this.registeredToGc) {
            this.registeredToGc = true;
            gcAdd(this);
          }
        }
      }
    } catch (err) {
      this.onError.emit(err);
    }
  }

  load(): Promise<MipmapResource> {
    if (this.loadPromise) {
      return this.loadPromise;
    }

    this.loadPromise = fetch(
      `/api/v1/mipmaps/${this.publicHashHex}?page=${this.page}`
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((info: MipmapInfoEvent) => {
        if (this.destroyed) return this;
        this.info = {
          width: info.resolution.width,
          height: info.resolution.height,
          maxLevel: info.max_level,
        };
        this.resize(this.info.width, this.info.height);
        this.setDirty();
        return this;
      })
      .catch((err) => {
        this.onError.emit(err);
        throw err;
      });

    return this.loadPromise;
  }

  dispose(): void {
    super.dispose();

    this.loadPromise = undefined;
    if (this.downloadCtrl !== undefined) {
      this.downloadCtrl.abort();
      this.downloadCtrl = undefined;
    }

    if (this.bitmap !== undefined) {
      this.bitmap.close();
      this.bitmap = undefined;
      this.bitmapLevel = undefined;
    }

    if (this.registeredToGc) {
      this.registeredToGc = false;
      gcRemove(this);
    }
  }

  hasData(): boolean {
    return this.bitmap !== undefined || this.baseTexture !== undefined;
  }
}
