// id of the System user in the data model
export const systemUserId = 1;
// id of the Guest user in the data model
export const guestUserId = 100;
// id of the All Users group in the data model
export const allUsersGroupId = 1;

// Drag and drop related
export enum DragAndDropTypes {
  Folder = "folder",
  Canvas = "canvas",
}
