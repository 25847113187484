import React from "react";
import { Result } from "antd";
import { ResultProps } from "antd/lib/result";
import PageLayout from "./PageLayout";

export interface IErrorPageProps {
  status: ResultProps["status"];
  title: ResultProps["title"];
  subTitle: ResultProps["subTitle"];
}

export const ErrorPage: React.FunctionComponent<IErrorPageProps> = (props) => {
  return (
    <PageLayout>
      <Result
        status={props.status}
        title={props.title}
        subTitle={props.subTitle}
      />
    </PageLayout>
  );
};

export const UnauthorizedPage: React.FunctionComponent = () => {
  return (
    <ErrorPage
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
    />
  );
};
