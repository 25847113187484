import * as PIXI from "pixi.js";
import { WebClient, Widget } from "../web-client";
import { CanvasWidgetEvent } from "../canvas-element-events";
import { syncAnnotations } from "../widget-synchronizer";
import { fromWorldToApp, setBBoxSize } from "./helpers";
import { CanvasBackground } from "./background";

export type CanvasWidget = Widget & {
  bg?: CanvasBackground;
};

export function syncCanvasWidget(
  app: WebClient,
  event: CanvasWidgetEvent,
  view: PIXI.Container
) {
  let w: CanvasWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as CanvasWidget;
    setBBoxSize(w, event.size);
  } else {
    w = {
      id: event.id,
      host: new PIXI.Container(),
      type: "Canvas",
      title: "",
      bbox: {
        left: 0,
        top: 0,
        right: event.size.width,
        bottom: event.size.height,
      },
    };
    w.host.name = "CanvasWidget";
    w.host.sortableChildren = true;
    w.host.zIndex = 1;

    view.addChild(w.host);
    app.widgets.set(event.id, w);
    app.canvas = w;
    app.nav.resized();
  }

  syncAnnotations(app, event, w);

  if (w.bg) {
    // TODO: In theory should also update the background image here
    w.bg.host.width = event.size.width;
    w.bg.host.height = event.size.height;
  }
}

// Bounding box of all canvas items under root, in world (view) coordinates
export function canvasItemBoundsWorld(
  root: PIXI.Container,
  skipUpdate = false,
  rect: PIXI.Rectangle | undefined = undefined
) {
  for (const c of root.children) {
    if ((c as any).canvasItem !== true) continue;
    const r = c.getBounds(skipUpdate);
    if (r.width <= 0.0 || r.height <= 0.0) continue;
    if (rect === undefined) {
      rect = r;
    } else {
      rect.enlarge(r);
    }
  }

  return rect;
}

// Bounding box of all canvas items, in application coordinate system
export function canvasItemBounds(app: WebClient, skipUpdate = false) {
  let rect = canvasItemBoundsWorld(app.anchorLayer, skipUpdate);
  if (app.canvas) {
    rect = canvasItemBoundsWorld(app.canvas.host, skipUpdate, rect);
  }
  if (!rect) return rect;
  return fromWorldToApp(app, rect);
}
