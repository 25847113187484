import * as PIXI from "pixi.js";
import { PdfWidgetEvent } from "../canvas-element-events";
import { MipmapResource } from "../images/mipmap-resource";
import { MipmapSprite } from "../images/mipmap-sprite";
import { WebClient, Widget } from "../web-client";
import { syncCanvasItem } from "../widget-synchronizer";
import { setBBoxSize } from "./helpers";

export type PdfWidget = Widget & {
  page: number;
  pageCount?: number;
  // Change pages atomically. While loading the new page (sprite), render the old page
  oldSprite?: MipmapSprite;
  sprite?: MipmapSprite;
};

// TODO: This doesn't work right with PDFs with various page sizes. The
// container size should be the size of the first page instead of the
// widget size.
function syncPdf(pdf: PdfWidget) {
  if (!pdf.sprite || pdf.sprite.width <= 1) return;
  pdf.sprite.renderable = true;

  const texW = pdf.sprite.texture.width;
  const texH = pdf.sprite.texture.height;
  const scale = Math.min(pdf.bbox.right / texW, pdf.bbox.bottom / texH);
  pdf.sprite.scale.set(scale);
  pdf.sprite.x = 0.5 * (pdf.bbox.right - scale * texW);
  pdf.sprite.y = 0.5 * (pdf.bbox.bottom - scale * texH);

  if (
    pdf.oldSprite &&
    (pdf.sprite.texture.baseTexture.resource as MipmapResource).hasData()
  ) {
    pdf.oldSprite.destroy();
    pdf.oldSprite = undefined;
  }
}

export function syncPdfWidget(app: WebClient, event: PdfWidgetEvent) {
  const page = Math.round(event.index);
  let w: PdfWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as PdfWidget;
    w.title = event.title;
    let sync = false;
    if (event.hash !== "" && (w.page !== page || !w.sprite)) {
      if (w.oldSprite) {
        if (w.sprite) w.sprite.destroy();
      } else {
        w.oldSprite = w.sprite;
      }
      w.sprite = MipmapSprite.create(app, event.hash, page);
      w.sprite.renderable = false;
      w.sprite.on("update", () => syncPdf(w));
      w.host.addChild(w.sprite);
      w.page = page;
      sync = true;
    }
    if (setBBoxSize(w, event.size)) sync = true;
    if (sync) syncPdf(w);
  } else {
    w = {
      id: event.id,
      host: new PIXI.Container(),
      type: "Pdf",
      title: event.title,
      page,
      bbox: {
        left: 0,
        top: 0,
        right: event.size.width,
        bottom: event.size.height,
      },
      downloadUrl: `/api/v1/canvases/${app.canvasId}/pdfs/${event.id}/download`,
    };
    w.host.name = "PdfWidget";
    if (event.hash !== "") {
      w.sprite = MipmapSprite.create(app, event.hash, page);
      w.host.addChild(w.sprite);
      w.sprite.renderable = false;
      w.sprite.on("update", () => syncPdf(w));
      syncPdf(w);
    }

    app.widgets.set(event.id, w);
  }

  syncCanvasItem(app, event, w);
}
