import * as PIXI from "pixi.js";
import { AnchorWidgetEvent } from "../canvas-element-events";
import { Widget, WebClient } from "../web-client";
import { syncCanvasItem } from "../widget-synchronizer";
import { setBBoxSize } from "./helpers";

export type AnchorWidget = Widget & {
  // Rounded rectangle
  bg: PIXI.Graphics;
  index: number;
};

function drawBg(bg: PIXI.Graphics, size: { height: number; width: number }) {
  bg.beginFill(0xffffff, 0.0);
  // 1px inner border using @divider-color
  bg.lineStyle(1.0, 0xffffff, 0.6, 0);
  // 4px rounded corner
  bg.drawRoundedRect(0, 0, size.width, size.height, 4);
  bg.endFill();
}

export function syncAnchorWidget(
  app: WebClient,
  event: AnchorWidgetEvent
): AnchorWidget {
  let w: AnchorWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as AnchorWidget;
    w.title = event.anchor_name;
    w.index = event.anchor_index;
    if (setBBoxSize(w, event.size)) {
      w.bg.clear();
      drawBg(w.bg, event.size);
    }
  } else {
    w = {
      id: event.id,
      host: new PIXI.Container(),
      bg: new PIXI.Graphics(),
      index: event.anchor_index,
      type: "Anchor",
      title: event.anchor_name,
      bbox: {
        left: 0,
        top: 0,
        right: event.size.width,
        bottom: event.size.height,
      },
    };
    w.host.name = "AnchorWidget";
    drawBg(w.bg, event.size);

    w.host.addChild(w.bg);
    app.widgets.set(event.id, w);
    app.anchorLayer.addChild(w.host);
    w.host.visible = app.showAnchors;
  }

  const handleParent = false;
  syncCanvasItem(app, event, w, handleParent);
  return w;
}
