import React, { useEffect, useReducer } from "react";
import { createContext, useContext } from "react";
import { IUserInfo } from "./AuthProvider";
import UsersReducer, { UserByIdMap } from "./UsersReducer";
import { subscribe, unsubscribe } from "../backend";

export interface IUsersContext {
  users: UserByIdMap;
}

const initialValue: IUsersContext = {
  users: new Map(),
};

export const UsersContext = createContext<IUsersContext>(initialValue);

// Provides a Map of all users by their id on the server
const UsersProvider: React.FunctionComponent = (children) => {
  const [state, dispatch] = useReducer(UsersReducer, new Map());

  useEffect(() => {
    const endpoint = "/users";

    function handleUsersChange(changes: IUserInfo[]) {
      dispatch(changes);
    }

    subscribe(endpoint, handleUsersChange);

    return function cleanup() {
      unsubscribe(endpoint, handleUsersChange);
    };
  }, []);

  const contextData: IUsersContext = {
    users: state,
  };

  return <UsersContext.Provider value={contextData} {...children} />;
};

export const useUsersContext = () => useContext(UsersContext);

export default UsersProvider;
