import * as PIXI from "pixi.js";

const objs = new Set<CanvusGCObject>();

export type Renderer = PIXI.Renderer & {
  frameTime: number;
  viewRenderTime: number;
  // In world coordinates
  viewport: PIXI.Rectangle;
  projectionScale: number;
};

export type CanvusGCObject = {
  gc: (renderer: Renderer) => void;
};

export function gcAdd(obj: CanvusGCObject) {
  objs.add(obj);
}
export function gcRemove(obj: CanvusGCObject) {
  objs.delete(obj);
}
export function gcRun(renderer: Renderer) {
  for (let obj of objs) {
    obj.gc(renderer);
  }
}
