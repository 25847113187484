import { Layout } from "antd";
import React from "react";
import {
  CanvusHeader,
  CanvusHeaderNavKeyType,
} from "../components/CanvusHeader";

import "./Layout.less";

export interface IPageLayoutProps {
  selectedTopNavKey?: CanvusHeaderNavKeyType;
}

// Layout for pages without sider
const PageLayout: React.FunctionComponent<IPageLayoutProps> = (props) => {
  return (
    <Layout className="site-layout-background">
      <Layout.Header>
        <CanvusHeader selectedTopNavKey={props.selectedTopNavKey} />
      </Layout.Header>
      <Layout.Content>{props.children}</Layout.Content>
    </Layout>
  );
};

export default PageLayout;
