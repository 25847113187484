import React, { useEffect, useRef, useState } from "react";
import { Rectf } from "../../web-client/math/rect";
import { CanvasUiLayer } from "../../web-client/ui-layer/CanvasUiLayer";
import { openWebClient, WebClientApi } from "../../web-client/web-client";
import { useCanvasPageContext } from "./CanvasPage";

import "./CanvusWebClient.less";

interface ICanvusWebClientProps {
  canvasId: string;
}

export interface IActiveWidget {
  id: string;
  rect: Rectf;
  type:
    | "Note"
    | "Canvas"
    | "Image"
    | "Video"
    | "Other"
    | "Anchor"
    | "Pdf"
    | "Connector";
  title?: string;
  page?: number;
  pageCount?: number;
  downloadUrl?: string;
}

export interface IAnchor {
  id: string;
  name: string;
  index: number;
}

export const CanvusWebClient: React.FunctionComponent<ICanvusWebClientProps> = (
  props
) => {
  const canvasRef = useRef<HTMLDivElement>(null);
  const [anchors, setAnchors] = useState<IAnchor[]>([]);
  const [activeWidget, setActiveWidget] = useState<IActiveWidget>();
  const [webClientApi, setWebClientApi] = useState<WebClientApi>();
  const [scale, setScale] = useState<[number, number, number]>([0, 0, 0]);
  const canvasPageContext = useCanvasPageContext();

  useEffect(() => {
    function onAnchorUpdated(id: string, name: string, index: number) {
      setAnchors((anchors) => {
        if (anchors && anchors.length > 0) {
          const aidx = anchors.findIndex((a) => a.id === id);
          let tmp;
          if (aidx < 0) {
            tmp = [...anchors, { id, name, index }];
          } else {
            tmp = [...anchors];
            tmp[aidx] = { id, name, index };
          }
          tmp.sort((a, b) => a.index - b.index);
          return tmp;
        } else {
          return [{ id, name, index }];
        }
      });
    }

    function onAnchorRemoved(id: string) {
      setAnchors((anchors) => {
        const aidx = (anchors ?? []).findIndex((a) => a.id === id);
        if (aidx < 0) {
          return anchors;
        } else {
          let tmp = [...anchors];
          tmp.splice(aidx, 1);
          return tmp;
        }
      });
    }

    if (canvasRef.current === null) {
      setWebClientApi(undefined);
    } else {
      const ret = openWebClient(canvasRef.current, props.canvasId, {
        onWidgetActivate: setActiveWidget,
        onAnchorUpdated,
        onAnchorRemoved,
        onScaleChanged: (scale, minScale, maxScale) =>
          setScale([scale, minScale, maxScale]),
      });
      setWebClientApi(ret.api);
      return ret.cleanup;
    }
  }, [props.canvasId]);

  useEffect(() => {
    if (webClientApi) {
      webClientApi.setShowAnchors(canvasPageContext.showAnchors);
      webClientApi.setFullscreen(canvasPageContext.fullScreenWidget);
    }
  }, [canvasPageContext, webClientApi]);

  return (
    <div id="web-client" className="web-client">
      {webClientApi !== undefined && (
        <CanvasUiLayer
          activeWidget={activeWidget}
          api={webClientApi}
          anchors={anchors ?? []}
          scale={scale[0]}
          minScale={scale[1]}
          maxScale={scale[2]}
        />
      )}
      <div ref={canvasRef} className="canvas-layer"></div>
    </div>
  );
};
