import { Button, Dropdown, Empty, Space, Table, Tag, Typography } from "antd";
import {
  FolderByIdMap,
  folderIcon,
  useFoldersContext,
} from "../../components/FoldersProvider";
import { IResource } from "../../interfaces";
import { IFilteredFolderViewProps } from "./FolderView";
import { FileOutlined, EllipsisOutlined } from "@ant-design/icons";
import { formatBytes, formatDateFromNow } from "../../util";
import { menuForResource } from "./ResourceDropdownMenu";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { IUserInfo, useAuthDataContext } from "../../components/AuthProvider";
import { getCollator } from "../../sorting";
import { TableRowSelection } from "antd/lib/table/interface";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { DragAndDropTypes } from "../../constants";
import { useCanvasesContext } from "../../components/CanvasesProvider";
import { DraggedItem, isDragAllowed, isDropAllowed } from "../../dragAndDrop";
import { moveResourceWithConflictResolutionModal } from "../../api";
import { selectResources } from "./FolderViewGrid";

function columns(
  user: IUserInfo,
  external_url: string,
  folders: FolderByIdMap,
  modal: Omit<ModalStaticFunctions, "warn">
) {
  const collator = getCollator();

  return [
    {
      title: "Name",
      dataIndex: "name",
      sorter: {
        compare: (a: IResource, b: IResource) => {
          const isFolderA = folders.has(a.id);
          const isFolderB = folders.has(b.id);

          // Show folders before canvases
          if (isFolderA !== isFolderB) {
            return isFolderA ? -1 : 1;
          }

          return collator.compare(a.name, b.name);
        },
      },
      render: (_text: unknown, record: any) => {
        const isFolder = folders.has(record.id);
        const icon = isFolder ? folderIcon(record) : <FileOutlined />;

        return (
          <Space direction="horizontal">
            {icon}
            <Typography.Text>{record.name}</Typography.Text>
            {record.mode === "demo" && <Tag color="blue">demo</Tag>}
          </Space>
        );
      },
    },
    {
      title: "Assets",
      dataIndex: "asset_size",
      sorter: (a: any, b: any) => {
        return a.asset_size < b.asset_size ? -1 : 1;
      },
      render: (_text: unknown, record: any) => {
        const isFolder = folders.has(record.id);
        if (isFolder) return <span />;

        return formatBytes(record.asset_size);
      },
    },
    {
      title: "Last modified",
      dataIndex: "modified_at",
      sorter: (a: any, b: any) => {
        return a.modified_at < b.modified_at ? 1 : -1;
      },
      render: (_text: unknown, record: any) => {
        const isFolder = folders.has(record.id);
        if (isFolder) return <span />;

        return formatDateFromNow(record.modified_at);
      },
    },
    {
      // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      align: "right" as "right",
      render: (_text: unknown, record: IResource) => {
        const menu = menuForResource(
          record,
          user,
          external_url,
          modal,
          folders
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              type="text"
              onClick={(e) => {
                // Stop event propagation so that clicking on button does
                // not trigger selection.
                e.stopPropagation();
              }}
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
}

// Custom rows for our table
const CustomTableRow: React.FunctionComponent = (props: any) => {
  const { canvases } = useCanvasesContext();
  const { folders } = useFoldersContext();
  const { user } = useAuthDataContext();

  const ref = useRef(null);

  // The props come from antd, extract resource id from them
  const resourceId = props["data-row-key"];

  const isFolder = folders.has(resourceId);

  // Both canvases are folders are potentially draggable
  const [, drag] = useDrag(
    () => ({
      type: isFolder ? DragAndDropTypes.Folder : DragAndDropTypes.Canvas,
      item: { id: resourceId },
      canDrag: () => {
        return isDragAllowed(resourceId, user, canvases, folders);
      },
    }),
    [isFolder, resourceId, canvases, folders, user]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [DragAndDropTypes.Folder, DragAndDropTypes.Canvas],
      collect: (monitor) => ({
        isOver: monitor.isOver() && monitor.canDrop(),
      }),
      canDrop: (item: DraggedItem, _monitor) => {
        // Can't drop on a canvas
        if (!isFolder) return false;

        const folder = folders.get(resourceId);
        if (!folder) return false;

        return isDropAllowed(folder, item.id, user, canvases, folders);
      },
      drop: (item: DraggedItem, _monitor) => {
        moveResourceWithConflictResolutionModal(
          resourceId,
          item.id,
          canvases,
          folders
        );
      },
    }),
    [isFolder, resourceId, user, canvases, folders]
  );

  drag(drop(ref));

  // Modify CSS class to visualize drop target (same style as selection)
  let { className, ...rest } = props;
  if (isOver) {
    className += " ant-table-row-selected";
  }

  return (
    <tr ref={ref} className={className} {...rest}>
      {props.children}
    </tr>
  );
};

// Component to visualize contents of a folder using a list layout
export const FolderViewList: React.FunctionComponent<IFilteredFolderViewProps> =
  (props) => {
    const { user } = useAuthDataContext();
    const { folders } = useFoldersContext();

    const isFolderEmpty =
      props.childFolders.length === 0 && props.childCanvases.length === 0;
    const emptyMessage =
      props.nameFilter.length === 0
        ? "This folder is empty."
        : "No results match your filter.";

    const cols = columns(user, props.external_url, folders, props.modal);

    const data = props.childFolders.concat(props.childCanvases);

    const rowSelection: TableRowSelection<IResource> = {
      selectedRowKeys: Array.from(props.selectedResources),
      // We have our own select all at the bottom
      hideSelectAll: true,
    };

    return (
      <div
        className={`folder-view-list${isFolderEmpty ? " empty" : ""}`}
        onClick={(event) => {
          // Clear selection if clicking on background
          event.stopPropagation();
          props.onSelectResources(new Set());
        }}
      >
        {isFolderEmpty && <Empty description={emptyMessage} />}
        {!isFolderEmpty && (
          <Table
            rowSelection={rowSelection}
            onRow={(data, _index) => {
              return {
                onClick: (event) => {
                  event.stopPropagation();
                  const selection = selectResources(
                    event,
                    data.id,
                    props.selectedResources
                  );

                  props.onSelectResources(selection);
                },
                onDoubleClick: (event) => {
                  props.onDoubleClickResource(event, data.id);
                },
              };
            }}
            className="list"
            size="small"
            columns={cols}
            dataSource={data}
            rowKey="id"
            pagination={false}
            components={{
              body: {
                row: CustomTableRow,
              },
            }}
          />
        )}
      </div>
    );
  };
