import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useUsersContext } from "./UsersProvider";
import { IUserInfo } from "./AuthProvider";
import { UserByIdMap } from "./UsersReducer";
import { GroupByIdMap, useGroupsContext, IGroup } from "./GroupsProvider";
import { getCollator } from "../sorting";

interface ISelectUserOrGroupProps {
  disabled?: boolean;
  excludedUsers: Set<number>;
  excludedGroups: Set<number>;
  onChange: (type: "user" | "group", id: number) => void;
  valueType?: "user" | "group";
  value?: number;
}

type UserIdSet = Set<number>;
type GroupIdSet = Set<number>;

// Filter users by comparing their name and email
function filterUsers(
  allUsers: UserByIdMap,
  excludedUsers: UserIdSet,
  value: string
): UserIdSet {
  const toCompare = value.toLowerCase();

  let result: UserIdSet = new Set();

  allUsers.forEach((user, id) => {
    if (
      excludedUsers.has(id) === false &&
      (user.name.toLowerCase().includes(toCompare) ||
        user.email.toLowerCase().includes(toCompare))
    ) {
      result.add(id);
    }
  });

  return result;
}

// Filter groups by their name
function filterGroups(
  allGroups: GroupByIdMap,
  excludedGroups: GroupIdSet,
  value: string
): GroupIdSet {
  const toCompare = value.toLowerCase();

  let result: GroupIdSet = new Set();

  allGroups.forEach((group, id) => {
    if (
      excludedGroups.has(id) === false &&
      group.name.toLowerCase().includes(toCompare)
    ) {
      result.add(id);
    }
  });

  return result;
}

interface ICandidates {
  users: UserIdSet;
  groups: GroupIdSet;
}

// Regular expression to parse <Option> values
const optionRe = /^(user|group)([0-9]+)$/;

export const SelectUserOrGroup: React.FunctionComponent<ISelectUserOrGroupProps> =
  (props) => {
    const [searchString, setSearchString] = useState<string>("");
    const [candidates, setCandidates] = useState<ICandidates>({
      users: new Set(),
      groups: new Set(),
    });

    const { users } = useUsersContext();
    const { groups } = useGroupsContext();

    // Update candidates when some of the inputs change
    useEffect(() => {
      const newUserCandidates = filterUsers(
        users,
        props.excludedUsers,
        searchString
      );
      const newGroupCandidates = filterGroups(
        groups,
        props.excludedGroups,
        searchString
      );
      setCandidates({ users: newUserCandidates, groups: newGroupCandidates });
    }, [
      users,
      groups,
      searchString,
      props.excludedUsers,
      props.excludedGroups,
    ]);

    let userOptions: IUserInfo[] = [];
    candidates.users.forEach((userId) => {
      const user = users.get(userId);

      if (user) userOptions.push(user);
    });

    let groupOptions: IGroup[] = [];
    candidates.groups.forEach((groupId) => {
      const group = groups.get(groupId);

      if (group) groupOptions.push(group);
    });

    // Sort entries
    const collator = getCollator();
    userOptions.sort((a, b) => collator.compare(a.name, b.name));
    groupOptions.sort((a, b) => collator.compare(a.name, b.name));

    const selectValue =
      props.valueType && props.value
        ? `${props.valueType}${props.value}`
        : undefined;

    const userOptionGroup = (
      <Select.OptGroup label="Users">
        {userOptions.map((user) => (
          <Select.Option key={`user${user.id}`} value={`user${user.id}`}>
            {user.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    );

    const groupOptionGroup = (
      <Select.OptGroup label="Groups">
        {groupOptions.map((group) => (
          <Select.Option key={`group${group.id}`} value={`group${group.id}`}>
            {group.name}
          </Select.Option>
        ))}
      </Select.OptGroup>
    );

    return (
      <Select<string>
        className="select-user-or-group"
        disabled={props.disabled}
        showSearch
        filterOption={false}
        style={{ width: "100%" }}
        placeholder="Add user or group..."
        onChange={(value, option) => {
          const matches = optionRe.exec(value);
          if (matches?.length === 3) {
            const type = matches[1];
            const id = Number(matches[2]);

            if (type === "user" || type === "group") props.onChange(type, id);
            else {
              console.error("Unkown option type in SelectUserOrGroup:", type);
            }
          }
        }}
        onSearch={(value) => setSearchString(value)}
        value={selectValue}
      >
        {userOptions.length > 0 && userOptionGroup}
        {groupOptions.length > 0 && groupOptionGroup}
      </Select>
    );
  };
