import { Color, colorEquals } from "../math/color";
import {
  contains,
  createEmptyRect,
  createScaledRect,
  expand,
  expand3,
  intersects,
  Rectf,
} from "../math/rect";
import {
  BezierNode,
  BezierVertex,
  SplineStyle,
  tessellateBezierSpline,
} from "./bezier-spline-tessellator";
import { FloatAllocator } from "./float-allocator";

export function splineBoundsApproximation2D(path: Array<BezierNode>): Rectf {
  const rect = createEmptyRect();

  for (let n of path) {
    expand3(rect, n.ctrlIn);
    expand3(rect, n.point);
    expand3(rect, n.ctrlOut);
  }

  return rect;
}

type Stroke = {
  id: number;
  color: Color;
  depth: number;
  nodes: Array<BezierNode>;
  bbox: Rectf;
  style: SplineStyle;
  generation: number;
};

// One LOD mipmap triangle strip of a stroke, pointing on vertex buffer on GPU
type Renderable = {
  // Copied from Stroke.depth here for quick sorting
  depth: number;

  stroke: Stroke;

  // Offset to BezierSplineRenderer.buffer
  bufferOffset: number;
  vertexCount: number;
};

type StrokePolygon = {
  triangleStrip: Array<BezierVertex>;
  generation: number;
  bufferOffset: number;
  vertexCount: number;
};

export class LevelOfDetail {
  // Viewport used when picking renderables. As long as the current
  // viewport is inside this one, renderables can be reused.
  activeView = createEmptyRect();

  // If false, renderables are already in order
  depthChanged = false;

  // Removed, changed or added strokes since last time build() was called
  removed = new Set<number>();
  changed = new Set<Stroke>();
  added = new Set<Stroke>();

  polygons = new Map<number, StrokePolygon>();

  bufferChanged = true;
  buffer = new FloatAllocator();

  // All mipmaps that should be rendered
  renderables: Array<Renderable> = [];

  level: number;

  constructor(level: number) {
    this.level = level;
  }

  buildStroke(
    stroke: Stroke,
    invScale: number,
    curveError: number
  ): Renderable {
    let polygon = this.polygons.get(stroke.id);
    if (polygon === undefined) {
      polygon = {
        triangleStrip: [],
        generation: 0,
        bufferOffset: 0,
        vertexCount: 0,
      };
      this.polygons.set(stroke.id, polygon);
    }

    if (polygon.generation !== stroke.generation) {
      if (polygon.vertexCount > 0) {
        this.buffer.free(polygon.bufferOffset * 6, polygon.vertexCount * 6);
        polygon.vertexCount = 0;
      }

      if (polygon.triangleStrip.length > 0) {
        polygon.triangleStrip = [];
      }

      tessellateBezierSpline(
        stroke.nodes,
        stroke.color,
        stroke.style,
        polygon.triangleStrip,
        curveError * invScale,
        curveError * invScale
      );
      polygon.generation = stroke.generation;
    }

    if (polygon.vertexCount === 0) {
      polygon.vertexCount = polygon.triangleStrip.length;
      let i = this.buffer.allocate(polygon.vertexCount * 6);
      polygon.bufferOffset = i / 6;

      let d = this.buffer.data;
      for (let v of polygon.triangleStrip) {
        d[i++] = v.location.x;
        d[i++] = v.location.y;
        d[i++] = v.color.r;
        d[i++] = v.color.g;
        d[i++] = v.color.b;
        d[i++] = v.color.a;
      }

      // TODO: pixi doesn't support partial uploads, but we should really use them here
      this.bufferChanged = true;
    }

    return {
      depth: stroke.depth,
      stroke,
      bufferOffset: polygon.bufferOffset,
      vertexCount: polygon.vertexCount,
    };
  }

  build(
    viewport: Rectf,
    invScale: number,
    curveError: number,
    strokes: Map<string, Stroke>
  ) {
    const recreate = !contains(this.activeView, viewport);
    const changed =
      this.added.size > 0 || this.changed.size > 0 || this.removed.size > 0;
    if (!recreate && !changed && !this.depthChanged) return false;

    const extendedArea = createScaledRect(viewport, 0.2);

    if (!recreate && changed) {
      if (this.removed.size !== 0 || this.changed.size !== 0) {
        let size = this.renderables.length;
        for (let idx = 0; idx < size; ) {
          const renderable = this.renderables[idx];
          if (this.removed.has(renderable.stroke.id)) {
            --size;
            if (idx < size) {
              this.renderables[idx] = this.renderables.pop()!;
            } else {
              this.renderables.pop();
            }
            this.depthChanged = true;
          } else {
            if (this.changed.delete(renderable.stroke)) {
              if (intersects(extendedArea, renderable.stroke.bbox)) {
                this.renderables[idx] = this.buildStroke(
                  renderable.stroke,
                  invScale,
                  curveError
                );
                ++idx;
              } else {
                this.depthChanged = true;
                --size;
                if (idx < size) {
                  this.renderables[idx] = this.renderables.pop()!;
                } else {
                  this.renderables.pop();
                }
              }
            } else {
              ++idx;
            }
          }
        }
        this.removed.clear();
      }

      for (const stroke of this.added) {
        if (intersects(extendedArea, stroke.bbox)) {
          this.renderables.push(this.buildStroke(stroke, invScale, curveError));
          this.depthChanged = true;
        }
      }

      // Any remaining items in view.changed were not already in view.renderables,
      // but their bounding box might have changed so that they now should be
      // there, so check them separately.
      for (const stroke of this.changed) {
        if (intersects(extendedArea, stroke.bbox)) {
          this.renderables.push(this.buildStroke(stroke, invScale, curveError));
          this.depthChanged = true;
        }
      }

      this.added.clear();
      this.changed.clear();
    } else if (recreate) {
      this.renderables = [];
      this.depthChanged = true;
      this.removed.clear();
      this.changed.clear();
      this.added.clear();

      for (let stroke of strokes.values()) {
        if (intersects(extendedArea, stroke.bbox)) {
          this.renderables.push(this.buildStroke(stroke, invScale, curveError));
        }
      }
    }

    this.activeView = extendedArea;

    if (strokes.size === this.renderables.length) {
      this.activeView = {
        left: -Infinity,
        top: -Infinity,
        right: Infinity,
        bottom: Infinity,
      };
    }

    if (this.depthChanged) {
      this.renderables.sort((a, b) => a.depth - b.depth);
      this.depthChanged = false;
    }
    return true;
  }
}

export class BezierSplineBuilder {
  private bboxCache = createEmptyRect();
  private bboxDirty = false;

  private curveError = 0.3;

  private minLod: number;

  strokes = new Map<string, Stroke>();
  private nextId = 0;

  private lods: Array<LevelOfDetail | undefined> = [];

  private scaleToLod(scale: number): number {
    const lod = Math.floor(Math.log2(scale)) - this.minLod;
    return Math.max(0, Math.min(lod, this.lods.length - 1));
  }

  private lodToScale(lod: number): number {
    return Math.pow(2.0, lod + this.minLod);
  }

  constructor(minScale = 0.01, maxScale = 1000.0) {
    this.minLod = Math.floor(Math.log2(minScale));
    const maxLod = Math.ceil(Math.log2(maxScale));
    this.lods.length = maxLod - this.minLod + 1;
  }

  add(
    id: string,
    color: Color,
    nodes: Array<BezierNode>,
    depth: number,
    style: SplineStyle
  ) {
    let stroke = this.strokes.get(id);
    if (stroke !== undefined) {
      for (const lod of this.lods) {
        if (lod && !lod.added.has(stroke)) {
          lod.changed.add(stroke);
        }
      }
      stroke.color = color;
      stroke.nodes = nodes;
      stroke.style = style;
      stroke.bbox = splineBoundsApproximation2D(stroke.nodes);
      stroke.generation++;
      this.bboxDirty = true;
      stroke.depth = depth;
    } else {
      stroke = {
        id: this.nextId++,
        bbox: splineBoundsApproximation2D(nodes),
        generation: 1,
        color,
        depth,
        nodes,
        style,
      };
      this.strokes.set(id, stroke);
      for (const lod of this.lods) {
        if (lod) {
          lod.added.add(stroke);
        }
      }
      if (!this.bboxDirty) {
        expand(this.bboxCache, stroke.bbox);
      }
    }
  }

  delete(id: string) {
    const stroke = this.strokes.get(id);
    if (!stroke) return;

    this.strokes.delete(id);
    this.bboxDirty = true;

    if (this.strokes.size === 0) {
      for (const [i, lod] of this.lods.entries()) {
        if (lod) {
          this.lods[i] = undefined;
        }
      }
      return;
    }

    for (const lod of this.lods) {
      if (lod) {
        lod.added.delete(stroke);
        lod.changed.delete(stroke);
        lod.removed.add(stroke.id);
      }
    }
  }

  setNodes(id: string, nodes: BezierNode[]) {
    const stroke = this.strokes.get(id);
    if (!stroke) return;

    this.bboxDirty = true;

    stroke.nodes = nodes;
    stroke.bbox = splineBoundsApproximation2D(nodes);
    stroke.generation++;

    for (const lod of this.lods) {
      if (lod && !lod.added.has(stroke)) {
        lod.changed.add(stroke);
      }
    }
  }

  insertNodes(id: string, idx: number, diff: BezierNode[]) {
    const stroke = this.strokes.get(id);
    if (!stroke) return;

    stroke.nodes.splice(idx, 0, ...diff);
    stroke.bbox = splineBoundsApproximation2D(stroke.nodes);
    stroke.generation++;

    if (!this.bboxDirty) {
      expand(this.bboxCache, stroke.bbox);
    }

    for (const lod of this.lods) {
      if (lod && !lod.added.has(stroke)) {
        lod.changed.add(stroke);
      }
    }
  }

  updateNode(id: string, color?: Color, depth?: number) {
    const stroke = this.strokes.get(id);
    if (!stroke) return;

    const colorChanged = color && !colorEquals(stroke.color, color);
    if (colorChanged) {
      stroke.color = color!;
      stroke.generation++;
      for (const lod of this.lods) {
        if (lod && !lod.added.has(stroke)) {
          lod.changed.add(stroke);
        }
      }
    }

    if (depth && stroke.depth !== depth) {
      stroke.depth = depth;
      for (const lod of this.lods) {
        if (lod) {
          lod.depthChanged = true;
          if (!colorChanged) {
            for (const renderable of lod.renderables) {
              if (renderable.stroke === stroke) {
                renderable.depth = depth;
              }
            }
          }
        }
      }
    }
  }

  bbox() {
    if (this.bboxDirty) {
      this.bboxCache = createEmptyRect();
      for (let s of this.strokes.values()) {
        expand(this.bboxCache, s.bbox);
      }
      this.bboxDirty = false;
    }
    return this.bboxCache;
  }

  // Returns [changed, lod]
  build(scale: number, viewport: Rectf): [boolean, LevelOfDetail | undefined] {
    if (this.strokes.size === 0) {
      return [false, undefined];
    }

    let level = this.scaleToLod(scale);
    let lod = this.lods[level];
    if (!lod) {
      const level2 = this.scaleToLod(scale * 1.1);
      lod = this.lods[level2];
      if (lod) {
        level = level2;
      } else {
        lod = new LevelOfDetail(level);
        this.lods[level] = lod;
      }
    }

    const invScale = 1.0 / this.lodToScale(level);
    const changed = lod.build(
      viewport,
      invScale,
      this.curveError,
      this.strokes
    );
    return [changed, lod];
  }

  gc(level: number) {
    this.lods[level] = undefined;
  }
}
