import * as PIXI from "pixi.js";
import { gcAdd, gcRemove, Renderer } from "../gc";
import { estimateScale, toLocal } from "../widgets/helpers";
import { BezierSplineBuilder } from "./bezier-spline-builder";

type LevelOfDetailGl = {
  lastUsed: number;

  buffer?: PIXI.Buffer;
  geometry?: PIXI.Geometry;

  // Used with WEBGL_multi_draw extension, if supported
  offsets?: Int32Array;
  counts?: Int32Array;
};

export class BezierSplineRenderer {
  private builder: BezierSplineBuilder;
  private lods: Array<LevelOfDetailGl | undefined> = [];

  static shader?: PIXI.Shader;

  private state = PIXI.State.for2d();

  private registeredToGc = false;

  constructor(builder: BezierSplineBuilder) {
    this.builder = builder;
  }

  gc(renderer: Renderer) {
    for (let [i, lod] of this.lods.entries()) {
      if (lod && lod.lastUsed < renderer.viewRenderTime) {
        if (lod.buffer) lod.buffer.destroy();
        if (lod.geometry) lod.geometry.destroy();
        this.lods[i] = undefined;
        this.builder.gc(i);
      }
      this.registeredToGc = false;
      gcRemove(this);
    }
  }

  public destroy() {
    for (const lod of this.lods) {
      if (lod) {
        if (lod.buffer) lod.buffer.destroy();
        if (lod.geometry) lod.geometry.destroy();
      }
    }
  }

  public render(
    renderer: Renderer,
    viewport: PIXI.Rectangle,
    worldTransform: PIXI.Matrix
  ) {
    const scale = estimateScale(worldTransform) * renderer.projectionScale;

    const [changed, lod] = this.builder.build(
      scale,
      toLocal(viewport, worldTransform)
    );
    if (!lod || lod.renderables.length === 0) return;

    let lodGl = this.lods[lod.level];
    if (lodGl) {
      lodGl.lastUsed = renderer.frameTime;
    } else {
      lodGl = { lastUsed: renderer.frameTime };
      this.lods[lod.level] = lodGl;
    }

    if (!this.registeredToGc) {
      this.registeredToGc = true;
      gcAdd(this);
    }

    if (lodGl.buffer === undefined) {
      lodGl.buffer = new PIXI.Buffer(lod.buffer.data, false, false);
      lodGl.geometry = this.createGeometry(lodGl.buffer);
    } else if (lod.bufferChanged) {
      lodGl.buffer.update(lod.buffer.data);
    }
    // TODO: This is stupid
    lod.bufferChanged = false;

    const shader = BezierSplineRenderer.splineShader();

    renderer.batch.flush();

    shader.uniforms.translationMatrix = worldTransform.toArray(true);
    renderer.shader.bind(shader);
    renderer.state.set(this.state);
    renderer.geometry.bind(lodGl.geometry, shader);

    const gl = renderer.gl;
    // Unfortunately this extension is currently only implemented in Chromium,
    // so we need to have fallback implementation that just calls drawArrays
    // N times.
    //
    // TODO: Fallback implementation could use degenerated triangles and a
    //       single drawArrays call, even though that would require more
    //       buffer updates and more expensive build step.
    // TODO: Multi-draw implementation could put colors to a separate buffer
    //       and index that using gl_DrawID in the fragment shader. That
    //       would save memory usage by 67%.
    let ext = gl.getExtension("WEBGL_multi_draw");
    if (ext !== null) {
      const len = lod.renderables.length;
      if (changed) {
        if (lodGl.offsets === undefined || lodGl.offsets.length < len) {
          lodGl.offsets = new Int32Array(len);
          lodGl.counts = new Int32Array(len);
        }
        for (let [i, r] of lod.renderables.entries()) {
          lodGl.offsets[i] = r.bufferOffset;
          lodGl.counts![i] = r.vertexCount;
        }
      }
      ext.multiDrawArraysWEBGL(
        gl.TRIANGLE_STRIP,
        lodGl.offsets,
        0,
        lodGl.counts,
        0,
        len
      );
    } else {
      for (let r of lod.renderables) {
        gl.drawArrays(gl.TRIANGLE_STRIP, r.bufferOffset, r.vertexCount);
      }
    }
  }

  createGeometry(buffer: PIXI.Buffer): PIXI.Geometry {
    const g = new PIXI.Geometry();

    g.addAttribute(
      "location",
      buffer,
      2,
      false,
      PIXI.TYPES.FLOAT,
      6 * 4,
      0,
      false
    );
    g.addAttribute(
      "color",
      buffer,
      4,
      false,
      PIXI.TYPES.FLOAT,
      6 * 4,
      2 * 4,
      false
    );
    return g;
  }

  static splineShader(): PIXI.Shader {
    if (BezierSplineRenderer.shader) {
      return BezierSplineRenderer.shader;
    }

    const program = PIXI.Program.from(
      `attribute vec2 location;
       attribute vec4 color;

       uniform mat3 translationMatrix;
       uniform mat3 projectionMatrix;

       varying vec4 c;

       void main() {
         gl_Position = vec4((projectionMatrix * translationMatrix * vec3(location, 1.0)).xy, 0.0, 1.0);
         c = color;
       }`,

      `varying vec4 c;
    
       void main() {
         gl_FragColor = c;
       }`
    );

    BezierSplineRenderer.shader = new PIXI.Shader(program);
    return BezierSplineRenderer.shader;
  }
}
