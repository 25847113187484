import React from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { ToolOutlined } from "@ant-design/icons";
import { useAuthDataContext } from "./AuthProvider";
import { UserAvatarMenu } from "./UserAvatarMenu";

import "./CanvusHeader.less";

export type CanvusHeaderNavKeyType =
  | "canvases"
  | "users"
  | "groups"
  | "admin"
  | "profile";

export interface ICanvusHeaderProps {
  selectedTopNavKey?: CanvusHeaderNavKeyType;
}

export const CanvusHeader: React.FunctionComponent<ICanvusHeaderProps> = (
  props
) => {
  const { isAuthenticated, user } = useAuthDataContext();

  const topNav = (
    <Menu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[
        props.selectedTopNavKey ? props.selectedTopNavKey : "",
      ]}
    >
      <Menu.Item key="canvases">
        <Link to="/canvases">Canvases</Link>
      </Menu.Item>
      <Menu.Item key="users">
        <Link to="/users">Users</Link>
      </Menu.Item>
      <Menu.Item key="groups">
        <Link to="/groups">Groups</Link>
      </Menu.Item>

      {user.admin && (
        <Menu.Item key="admin">
          <Link to="/admin/dashboard">
            <ToolOutlined />
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className="canvus-header">
      {isAuthenticated && <UserAvatarMenu />}

      <Link to="/canvases">
        <img className="logo" src="/canvus-button.svg" alt="Canvus logo" />
      </Link>

      {isAuthenticated && topNav}
    </div>
  );
};
