import { Reducer } from "react";
import { IUserInfo } from "./AuthProvider";

export type UserByIdMap = Map<number, IUserInfo>;

// Apply given change to a map of users
function mutateUsers(users: UserByIdMap, change: IUserInfo) {
  if (change.state === "deleted") {
    users.delete(change.id);
  } else {
    users.set(change.id, change);
  }
}

// A reducer to maintain an array of IUserInfo based on changes from a REST API
// subscription.
const UsersReducer: Reducer<UserByIdMap, IUserInfo[]> = (
  prevState,
  changes
) => {
  // Must make a copy and not modify prevState
  let state = new Map(prevState);

  changes.forEach((change) => {
    mutateUsers(state, change);
  });

  return state;
};

export default UsersReducer;
