import React, { createContext, useContext, useEffect, useState } from "react";

export interface IViewportContext {
  width: number;
  height: number;
}

const initialValue: IViewportContext = { width: 0, height: 0 };

export const ViewportContext = createContext<IViewportContext>(initialValue);

const ViewportProvider: React.FunctionComponent = (children) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return <ViewportContext.Provider value={{ width, height }} {...children} />;
};

export const useViewportContext = () => useContext(ViewportContext);

export default ViewportProvider;
