import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { CanvusHeader } from "../../components/CanvusHeader";
import { Link } from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  TeamOutlined,
  SettingOutlined,
  AuditOutlined,
} from "@ant-design/icons";

import "../Layout.less";
import { usePersistedState } from "../../util";

export interface IAdminPageProps {
  selectedSideMenuKey:
    | "dashboard"
    | "users"
    | "groups"
    | "settings.general"
    | "settings.authentication"
    | "settings.email"
    | "settings.license"
    | "audit";
}

const AdminLayout: React.FunctionComponent<IAdminPageProps> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const defaultOpenKeys = props.selectedSideMenuKey.includes("settings.")
    ? ["settings"]
    : [];

  const [openKeys, setOpenKeys] = usePersistedState(
    "admin-menu-open-keys",
    defaultOpenKeys
  );

  return (
    <Layout className="site-layout-background">
      <Layout.Header>
        <CanvusHeader selectedTopNavKey="admin" />
      </Layout.Header>

      <Layout className="site-layout">
        <Layout.Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          breakpoint="md"
        >
          <Menu
            theme="dark"
            selectedKeys={[props.selectedSideMenuKey]}
            openKeys={openKeys}
            mode="inline"
            onOpenChange={(keys) => {
              setOpenKeys(keys as string[]);
            }}
          >
            <Menu.ItemGroup key="g1" title="Admin Area">
              <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
                Dashboard
                <Link to="/admin/dashboard" />
              </Menu.Item>
              <Menu.Item key="users" icon={<UserOutlined />}>
                Users
                <Link to="/admin/users" />
              </Menu.Item>
              <Menu.Item key="groups" icon={<TeamOutlined />}>
                Groups
                <Link to="/admin/groups" />
              </Menu.Item>
              <Menu.SubMenu
                key="settings"
                icon={<SettingOutlined />}
                title="Settings"
              >
                <Menu.Item key="settings.general">
                  General
                  <Link to="/admin/settings/general" />
                </Menu.Item>
                <Menu.Item key="settings.authentication">
                  Authentication
                  <Link to="/admin/settings/authentication" />
                </Menu.Item>
                <Menu.Item key="settings.email">
                  Email
                  <Link to="/admin/settings/email" />
                </Menu.Item>
                <Menu.Item key="settings.license">
                  License
                  <Link to="/admin/settings/license" />
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="audit" icon={<AuditOutlined />}>
                Audit log
                <Link to="/admin/audit-log" />
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Layout.Sider>

        <Layout.Content className="scrollable">{props.children}</Layout.Content>
      </Layout>
    </Layout>
  );
};

export default AdminLayout;
