import * as PIXI from "pixi.js";
import { Rectf } from "./math/rect";
import { Vec3 } from "./math/vec";
import { WebClient, Widget } from "./web-client";
import { fromWorldToApp, widgetWorldBBox } from "./widgets/helpers";

export class PresentationMode {
  private app: WebClient;
  private widget?: Widget;

  // Left and right or top and bottom
  private letterboxes?: [PIXI.Sprite, PIXI.Sprite];
  // Horizontal means that the letterboxes are on the left and right
  private horizontal = true;

  private origPos?: Vec3;

  constructor(app: WebClient) {
    this.app = app;
  }

  isPresenting() {
    return this.widget !== undefined;
  }

  startPresenting(widget: Widget) {
    if (!this.origPos) {
      this.origPos = {
        x: this.app.view.x,
        y: this.app.view.y,
        z: this.app.view.scale.x,
      };
    }
    this.widget = widget;
    this.zoom(widget);
  }

  stopPresenting() {
    this.widget = undefined;
    this.clearLetterBoxes();

    if (this.origPos) {
      this.app.nav.animateTo(
        this.origPos.x,
        this.origPos.y,
        this.origPos.z,
        0.5
      );
      this.origPos = undefined;
    }
  }

  resized() {
    if (this.widget) {
      this.zoom(this.widget);
    }
  }

  private zoom(widget: Widget) {
    const rect = fromWorldToApp(this.app, widgetWorldBBox(widget));
    this.app.nav.zoomTo(rect);

    this.setLetterbox(rect);
  }

  private clearLetterBoxes() {
    if (this.letterboxes) {
      const screen = this.app.pixi.screen;
      if (this.horizontal) {
        const left = this.letterboxes[0];
        const right = this.letterboxes[1];
        this.app.easing.add(left, { x: -16, width: 16 }, { destroy: true });
        this.app.easing.add(right, { x: screen.width }, { destroy: true });
      } else {
        const top = this.letterboxes[0];
        const bottom = this.letterboxes[1];
        this.app.easing.add(top, { y: -16, height: 16 }, { destroy: true });
        this.app.easing.add(bottom, { y: screen.height }, { destroy: true });
      }
    }
  }

  private setLetterbox(rect: Rectf) {
    const screen = this.app.pixi.screen;
    const screenAr = screen.width / screen.height;
    const widgetAr = (rect.right - rect.left) / (rect.bottom - rect.top);

    const horizontal = screenAr >= widgetAr;
    if (horizontal !== this.horizontal) this.clearLetterBoxes();

    if (horizontal !== this.horizontal || !this.letterboxes) {
      this.horizontal = horizontal;
      if (horizontal) {
        const left = new PIXI.Sprite(PIXI.Texture.WHITE);
        left.tint = 0x000000;
        left.name = "Letterbox (left)";
        left.height = screen.height;
        left.x = -16;

        const right = new PIXI.Sprite(PIXI.Texture.WHITE);
        right.tint = 0x000000;
        right.name = "Letterbox (right)";
        right.x = screen.width;
        right.height = screen.height;
        this.app.pixi.stage.addChild(right);
        this.letterboxes = [left, right];
      } else {
        const top = new PIXI.Sprite(PIXI.Texture.WHITE);
        top.tint = 0x000000;
        top.name = "Letterbox (top)";
        top.width = screen.width;
        top.y = -16;

        const bottom = new PIXI.Sprite(PIXI.Texture.WHITE);
        bottom.tint = 0x000000;
        bottom.name = "Letterbox (bottom)";
        bottom.y = screen.height;
        bottom.width = screen.width;
        this.app.pixi.stage.addChild(bottom);
        this.letterboxes = [top, bottom];
      }

      this.app.pixi.stage.addChild(this.letterboxes[0]);
      this.app.pixi.stage.addChild(this.letterboxes[1]);
    }

    if (horizontal) {
      const left = this.letterboxes[0];
      const right = this.letterboxes[1];

      const wWidth = screen.height * widgetAr;
      const w = 0.5 * (screen.width - wWidth);
      left.height = screen.height;
      this.app.easing.add(left, { x: 0, width: w });

      right.height = screen.height;
      if (right.x + right.width < screen.width)
        right.width = screen.width - right.x;
      this.app.easing.add(right, { x: w + wWidth, width: w });
    } else {
      const top = this.letterboxes[0];
      const bottom = this.letterboxes[1];

      const wHeight = screen.width / widgetAr;
      const h = 0.5 * (screen.height - wHeight);
      top.width = screen.width;
      this.app.easing.add(top, { y: 0, height: h });

      bottom.width = screen.width;
      if (bottom.y + bottom.height < screen.height)
        bottom.height = screen.height - bottom.y;
      this.app.easing.add(bottom, { y: h + wHeight, height: h });
    }
  }
}
