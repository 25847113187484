import { Button, Card, Form, Input, message, Result, Row } from "antd";
import React, { useState } from "react";
import {
  MailOutlined,
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Axios from "axios";

// Reuse styles from login page for consistent look
import "./LoginPage.less";
import PageLayout from "./PageLayout";
import passwordRule from "./password-rule";

const RegisterFeedback: React.FunctionComponent = () => {
  return (
    <Card className="login-card">
      <Result
        status="success"
        title="Thank you"
        subTitle="Your registration was successful but you must now confirm your email address before you can sign in. Please check your email and click on the link provided."
        extra={
          <Link to="/users/login">
            <Button type="primary">Sign in</Button>
          </Link>
        }
      />
    </Card>
  );
};

interface IRegisterProps {
  isRequestInProgress: boolean;
  onSubmit: (values: any) => void;
}

const RegisterForm: React.FunctionComponent<IRegisterProps> = (props) => {
  return (
    <Card title="Sign up" className="login-card">
      <Form name="register" className="login-form" onFinish={props.onSubmit}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              type: "string",
              message: "Please enter your name.",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder="Name"
            disabled={props.isRequestInProgress}
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please provide a valid email.",
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder="Email"
            disabled={props.isRequestInProgress}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              type: "string",
              message: "Please enter a password.",
            },
            passwordRule,
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            disabled={props.isRequestInProgress}
          />
        </Form.Item>

        {
          // TODO: Add reCAPTCHAv3? It needs an account.
        }

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            disabled={props.isRequestInProgress}
          >
            Register
          </Button>
        </Form.Item>
        <Form.Item>
          Already have login and password?{" "}
          <Link to="/users/login">Sign in</Link>.
        </Form.Item>
      </Form>
    </Card>
  );
};

enum State {
  Register,
  RegisterInProgress,
  RegisterFeedback,
}

export const RegisterPage: React.FunctionComponent = () => {
  const [componentState, setComponentState] = useState<State>(State.Register);

  function onRegisterAccount(values: any) {
    const payload = {
      email: values.email,
      name: values.name,
      password: values.password,
    };

    const hideProgressMsg = message.loading("Registering account...", 0);

    setComponentState(State.RegisterInProgress);

    Axios.post("/api/dashboard/users/register", payload)
      .then(() => {
        setComponentState(State.RegisterFeedback);
      })
      .catch((error) => {
        console.error("Account registration failed", error.response);
        const msgFromServer = error.response?.data?.msg;
        message.error(`Account registration failed (${msgFromServer})`);

        setComponentState(State.Register);
      })
      .finally(() => {
        hideProgressMsg();
      });
  }

  let component;
  switch (componentState) {
    case State.Register:
      component = (
        <RegisterForm
          isRequestInProgress={false}
          onSubmit={onRegisterAccount}
        />
      );
      break;
    case State.RegisterInProgress:
      component = (
        <RegisterForm isRequestInProgress={true} onSubmit={onRegisterAccount} />
      );
      break;
    case State.RegisterFeedback:
      component = <RegisterFeedback />;
      break;
  }

  return (
    <PageLayout>
      <Row justify="center" align="middle">
        {component}
      </Row>
    </PageLayout>
  );
};
