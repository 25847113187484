import React, { useState, useEffect } from "react";
import { Card, Input, Table, Typography } from "antd";
import "./LoginPage.less";
import { IUserInfo } from "../components/AuthProvider";
import UsersProvider, { useUsersContext } from "../components/UsersProvider";
import PageLayout from "./PageLayout";

function formatTable() {
  return [
    {
      title: "User",
      render: (_text: unknown, record: IUserInfo) => {
        return (
          <span>
            <Typography.Text>{record.name}</Typography.Text>
            <br />
            <Typography.Text type="secondary">{record.email}</Typography.Text>
          </span>
        );
      },
    },
  ];
}

export const UsersPage: React.FunctionComponent = () => {
  return (
    <UsersProvider>
      <UsersPageContent />
    </UsersProvider>
  );
};

const UsersPageContent: React.FunctionComponent = () => {
  const { users } = useUsersContext();
  const [filterString, setFilterString] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<IUserInfo[]>(
    Array.from(users.values())
  );

  const columns = formatTable();

  // Only search in visible columns to avoid confusing the user. Use string
  // instead of an array to workaround the fact that arrays can't be compared in
  // JavaScript. This avoids infinite loop in the hook below.
  const keysToSearch = "name,email";

  // Hook to perform the actual data filtering
  useEffect(() => {
    const result = Array.from(users.values()).filter((user) => {
      // Convert string back to array
      const keyArray = keysToSearch.split(",");

      // Filter by search string
      for (const key in user) {
        if (keyArray.includes(key)) {
          const value = String((user as any)[key]).toLowerCase();

          if (value.includes(filterString)) return true;
        }
      }

      return false;
    });

    setFilteredUsers(result);
  }, [users, filterString, keysToSearch]);

  // Handle table filtering. Modifying filters will trigger hook below to do
  // the actual filtering.
  function onFilterStringChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterString(event.target.value.toLowerCase());
  }

  return (
    <PageLayout selectedTopNavKey="users">
      <Card>
        <Input.Search
          className="filter-input"
          placeholder="Filter results..."
          onChange={onFilterStringChange}
        ></Input.Search>
        <Table<IUserInfo>
          columns={columns}
          dataSource={filteredUsers}
          rowKey="id"
        />
      </Card>
    </PageLayout>
  );
};
