// Simple vec2/vec3 types needed by the client.
// At the moment we are not using any third-party math library or PIXI classes
// because they all seem to have extra overhead. Some different implementations
// were tested with tessellateBezierSpline, but this one was the fastest when
// running the tests with node.js on Linux.

// If we need more these in the future, we should spend more time for finding
// and selecting a fast library.

// Classes (new Vec2(x, y)), plain arrays ([x, y]) or Float32Arrays were all
// measurably slower than this.

export type Vec2 = {
  x: number;
  y: number;
};

export type Vec3 = {
  x: number;
  y: number;
  z: number;
};

export function rotate2(v: Vec2, s: number, c: number) {
  const t = v.x;
  v.x = v.x * c + v.y * -s;
  v.y = t * s + v.y * c;
}

export function rotate(v: Vec2, angle: number) {
  rotate2(v, Math.sin(angle), Math.cos(angle));
}

export function dot2(a: Vec2, b: Vec2) {
  return a.x * b.x + a.y * b.y;
}

export function dot3(a: Vec3, b: Vec3) {
  return a.x * b.x + a.y * b.y + a.z * b.z;
}

export function lengthSqr3(v: Vec3) {
  return dot3(v, v);
}

export function length(v: Vec2) {
  return Math.sqrt(dot2(v, v));
}

export function distance(a: Vec2, b: Vec2) {
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function cross(a: Vec3, b: Vec3): Vec3 {
  return {
    x: a.y * b.z - a.z * b.y,
    y: a.z * b.x - a.x * b.z,
    z: a.x * b.y - a.y * b.x,
  };
}

export function cross2(a: Vec2, b: Vec2) {
  return a.x * b.y - a.y * b.x;
}

export function normalized(v: Vec2) {
  const l = length(v);
  return { x: v.x / l, y: v.y / l };
}
