import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { message, Modal, Empty } from "antd";

import CanvasesProvider, {
  CanvasByIdMap,
  useCanvasesContext,
} from "../../components/CanvasesProvider";
import CanvasHierarchyProvider from "../../components/CanvasHierarchyProvider";
import FoldersProvider, {
  useFoldersContext,
  FolderByIdMap,
} from "../../components/FoldersProvider";
import { useAuthDataContext } from "../../components/AuthProvider";
import PageLoading from "../../components/PageLoading";
import { AxiosError } from "axios";
import UsersProvider from "../../components/UsersProvider";
import GroupsProvider from "../../components/GroupsProvider";
import { useQuery } from "react-query";
import { IServerConfig } from "../../interfaces";
import { QueryFailure } from "../../components/QueryFailure";
import { moveResource } from "../../api";
import PageLayout from "../PageLayout";
import { useNavigate } from "react-router-dom";
import { FolderView } from "./FolderView";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import { FolderTree } from "./FolderTree";
import "./CanvasListPage.less";
import { SelectTargetFolderDialog } from "./SelectTargetFolderDialog";
import { usePersistedState } from "../../util";
import ViewportProvider, {
  useViewportContext,
} from "../../components/ViewportProvider";

interface IResourceError {
  ok: boolean;
  id: string;
  msg: string;
}

// Given array of error messages, display them so that each unique message
// appears only once
function compactErrorMessages(
  errors: IResourceError[],
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
) {
  // Determine unique error messages
  const uniqueMsg = new Set(errors.map((x) => x.msg));

  // Count how many times each unique error occurred
  for (const msg of uniqueMsg) {
    const count = errors.filter((value) => value.msg === msg).length;

    if (count > 1) {
      // For repeated errors, just display one single message without resource
      // names
      message.error(`${msg} (x${count})`, 5);
    } else {
      // For singular messages, display the affected resource name
      const error = errors.find((x) => x.msg === msg);
      if (error) {
        const name = resourceName(error.id, canvases, folders);

        message.error(`Failed to remove '${name}': ${msg}`, 5);
      } else {
        console.error("Failed to find resource id for error message:", msg);
      }
    }
  }
}

export const CanvasListPage: React.FunctionComponent = () => {
  // Need this to access our contexts inside modals (permissions)
  const [modal, contextHolder] = Modal.useModal();

  return (
    <UsersProvider>
      <GroupsProvider>
        <FoldersProvider>
          <CanvasesProvider>
            <CanvasHierarchyProvider>
              <ViewportProvider>
                {contextHolder}
                <DndProvider backend={HTML5Backend}>
                  <PageLayout selectedTopNavKey="canvases">
                    <CanvasListPageContent modal={modal} />
                  </PageLayout>
                </DndProvider>
              </ViewportProvider>
            </CanvasHierarchyProvider>
          </CanvasesProvider>
        </FoldersProvider>
      </GroupsProvider>
    </UsersProvider>
  );
};

// TODO: This used to be more useful, maybe not anymore.
enum VisibleDialog {
  CopyResource,
  MoveResource,
  None,
}

function resourceName(
  id: string,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
) {
  const canvas = canvases.get(id);
  if (canvas !== undefined) return canvas.name;

  const folder = folders.get(id);
  if (folder !== undefined) return folder.name;

  return undefined;
}

interface ICanvasListPageContentProps {
  modal: Omit<ModalStaticFunctions, "warn">;
}

const CanvasListPageContent: React.FunctionComponent<ICanvasListPageContentProps> =
  (props) => {
    const { canvases } = useCanvasesContext();
    const { folders, isLoading: isFolderLoading } = useFoldersContext();
    const { user } = useAuthDataContext();
    const navigate = useNavigate();
    const viewport = useViewportContext();

    // The user's home folder's id equals their user id
    const userHomeFolderKey: string = user.id.toString();

    // Id of the current folder being viewed. Used to keep left and right panel in
    // sync.
    const [currentFolderId, setCurrentFolderId] = usePersistedState(
      "canvas-list-current-folder",
      userHomeFolderKey
    );

    // Ids of the currently selected resources on the right panel.
    const [selectedResources, setSelectedResources] = useState<Set<string>>(
      new Set()
    );

    const [visibleDialog, setVisibleDialog] = useState<VisibleDialog>(
      VisibleDialog.None
    );

    function onNavigateToFolder(folderId: string) {
      setCurrentFolderId(folderId);
      // Clear selection on navigation
      setSelectedResources(new Set());
    }

    // Need external_url from server config for shared links
    const {
      isLoading: isConfigLoading,
      error,
      data,
    } = useQuery<IServerConfig, AxiosError>("/server-config");

    if (error) {
      return (
        <QueryFailure
          title="Failed to query server configuration"
          error={error}
        />
      );
    }

    if (isConfigLoading || isFolderLoading) {
      return <PageLoading />;
    }

    const config = data!;

    const currentFolder = folders.get(currentFolderId);
    const userTrashFolderId = `trash.${user.id}`;

    return (
      <div className="two-column-layout">
        <SelectTargetFolderDialog
          serverName={config.server_name}
          visible={visibleDialog !== VisibleDialog.None}
          onCancel={() => {
            setVisibleDialog(VisibleDialog.None);
          }}
          onCopyFinish={(targetFolderId: string) => {
            // TODO: should we navigate to target folder?
            setVisibleDialog(VisibleDialog.None);
          }}
          currentFolderId={currentFolderId}
          resourceIds={Array.from(selectedResources)}
          operation={
            visibleDialog === VisibleDialog.CopyResource ? "copy" : "move"
          }
          canvases={canvases}
          folders={folders}
        />

        {viewport.width >= 992 && (
          <div className="left-panel">
            {/* TODO: this panel should be resizable horizontally */}

            <FolderTree
              rootNodeName={config.server_name}
              selectedFolderId={currentFolderId}
              onSelectFolder={(selectedKeys) => {
                const key = selectedKeys[0] as string;
                onNavigateToFolder(key);
              }}
            />
          </div>
        )}

        <div className="right-panel">
          {currentFolder && (
            <FolderView
              currentFolder={currentFolder}
              external_url={config.external_url}
              onNavigateToFolder={(folderId) => onNavigateToFolder(folderId)}
              onSelectResources={(resources) => setSelectedResources(resources)}
              selectedResources={selectedResources}
              onDeleteResource={async (resourceIds) => {
                const hideDeleteMsg = message.loading(
                  `Removing ${resourceIds.length} resource${
                    resourceIds.length > 1 ? "s" : ""
                  }...`,
                  0
                );

                // Make promises to move everything...
                const promises = resourceIds.map((id) => {
                  const isCanvas = canvases.has(id);
                  const prefix = isCanvas ? "canvases" : "canvas-folders";

                  return moveResource(
                    prefix,
                    "move",
                    "replace",
                    id,
                    userTrashFolderId
                  );
                });

                // Wait for everything to settle...
                Promise.allSettled(promises)
                  .then((results) => {
                    // Combine results with resource ids
                    const resultWithId: IResourceError[] = results.map(
                      (value, index) => {
                        const ok = value.status === "fulfilled";
                        const id = resourceIds[index];
                        const msg =
                          value.status === "rejected"
                            ? value.reason.response.data.msg
                            : "ok";

                        return { ok, id, msg };
                      }
                    );

                    // Determine if any failed...
                    const failed: IResourceError[] = resultWithId.filter(
                      (value) => {
                        return value.ok === false;
                      }
                    );

                    // Generate readable error messages
                    if (failed.length > 0) {
                      compactErrorMessages(failed, canvases, folders);

                      // Keep failed resources selected
                      const failedIds = failed.map((x) => x.id);
                      setSelectedResources(new Set(failedIds));
                    } else {
                      message.success("Resources were moved to trash.");
                      // Clear selection
                      setSelectedResources(new Set());
                    }
                  })
                  .finally(() => {
                    hideDeleteMsg();
                  });
              }}
              onCopyResource={(resourceIds) => {
                setVisibleDialog(VisibleDialog.CopyResource);
              }}
              onMoveResource={(resourceIds) => {
                setVisibleDialog(VisibleDialog.MoveResource);
              }}
              onDoubleClickResource={(_event, resourceId) => {
                const canvas = canvases.get(resourceId);

                if (canvas) {
                  // Open the canvas unless it is in trash
                  if (canvas.in_trash) {
                    message.error("Can't open canvases in trash.");
                  } else {
                    navigate(`/open/${resourceId}`);
                  }
                } else {
                  // Navigate to folder
                  onNavigateToFolder(resourceId);
                }
              }}
              modal={props.modal}
            />
          )}
          {currentFolder === undefined && <Empty />}
        </div>
      </div>
    );
  };
