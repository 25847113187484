import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { useUsersContext } from "./UsersProvider";
import { IUserInfo } from "./AuthProvider";
import { UserByIdMap } from "./UsersReducer";

interface ISelectUserProps {
  excludedUsers: Set<number>;
  // TODO: should onChange() get IUserInfo instead?
  onChange: (userId: number) => void;
  value?: number;
}

type UserIdSet = Set<number>;

// Filter users by comparing their name and email
function filterUsers(
  allUsers: UserByIdMap,
  excludedUsers: UserIdSet,
  value: string
): UserIdSet {
  const toCompare = value.toLowerCase();

  let result: UserIdSet = new Set();

  allUsers.forEach((user, id) => {
    if (
      excludedUsers.has(id) === false &&
      (user.name.toLowerCase().includes(toCompare) ||
        user.email.toLowerCase().includes(toCompare))
    ) {
      result.add(id);
    }
  });

  return result;
}

export const SelectUser: React.FunctionComponent<ISelectUserProps> = (
  props
) => {
  const [searchString, setSearchString] = useState<string>("");
  const [candidates, setCandidates] = useState<UserIdSet>(new Set());

  const { users } = useUsersContext();

  // Update filtered candidates if candidates or search string changes
  useEffect(() => {
    const newCandidates = filterUsers(users, props.excludedUsers, searchString);
    setCandidates(newCandidates);
  }, [users, searchString, props.excludedUsers]);

  let options: IUserInfo[] = [];
  candidates.forEach((userId) => {
    const user = users.get(userId);

    if (user) options.push(user);
  });

  return (
    <Select
      showSearch
      filterOption={false}
      style={{ width: "100%" }}
      placeholder="Search for a user..."
      onChange={props.onChange}
      onSearch={(value) => setSearchString(value)}
      value={props.value}
    >
      {options.map((user) => (
        <Select.Option key={user.id} value={user.id}>
          {user.name}
        </Select.Option>
      ))}
    </Select>
  );
};
