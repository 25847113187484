import * as PIXI from "pixi.js";
import {
  BezierNode,
  bezierReversed,
  bezierTangent2D,
  createBezierCurve,
  CubicBezierCurve,
  SplineCapStyle,
} from "../annotations/bezier-spline-tessellator";
import { ConnectorTip, ConnectorWidgetEvent } from "../canvas-element-events";
import { Color, hexToColor } from "../math/color";
import { distance, length, normalized, Vec2 } from "../math/vec";
import { Widget, WebClient } from "../web-client";
import { AnnotationContainer } from "./annotation-container";
import {
  fromWorldToLocal,
  fuzzyCompare,
  listenSceneTransform,
  widgetWorldBBox,
} from "./helpers";

const strokeLineId = "1";
const strokeSrcTipId = "2";
const strokeDstTipId = "3";

enum Tip {
  SolidEquilateralTriangle,
  None,
}

export type ConnectorWidget = Widget & {
  splines: AnnotationContainer;

  data: ConnectorWidgetEvent;

  srcTip: Tip;
  dstTip: Tip;
  lineColor: Color;

  src?: Widget;
  dst?: Widget;
  invalidated: boolean;

  srcPoint: Vec2;
  dstPoint: Vec2;

  canvasScale: number;

  deleteSrcChangeListener?: () => void;
  deleteDstChangeListener?: () => void;
  deleteReparentListeners?: () => void;
  deleteDepthListeners?: () => void;
};

function findWidgets(app: WebClient, w: ConnectorWidget) {
  w.host.visible = false;

  if (!w.data.src.id || !w.data.dst.id) return;

  const inv = () => invalidate(app, w);

  if (!w.src || w.src.host.destroyed) {
    if (w.deleteSrcChangeListener) {
      w.deleteSrcChangeListener();
      w.deleteSrcChangeListener = undefined;
    }
    w.src = app.widgets.get(w.data.src.id);
    if (w.src) {
      listenSceneTransform(app, w.src.host);
      const h = w.src.host;
      h.on("change", inv);
      w.deleteSrcChangeListener = () => h.off("change", inv);
    }
  }

  if (!w.dst || w.dst.host.destroyed) {
    if (w.deleteDstChangeListener) {
      w.deleteDstChangeListener();
      w.deleteDstChangeListener = undefined;
    }

    w.dst = app.widgets.get(w.data.dst.id);
    if (w.dst) {
      listenSceneTransform(app, w.dst.host);
      const h = w.dst.host;
      h.on("change", inv);
      w.deleteDstChangeListener = () => h.off("change", inv);
    }
  }

  if (w.src && w.dst) setupListeners(app, w);
}

function findCommonParent(
  x: PIXI.Container,
  y: PIXI.Container,
  root: PIXI.Container,
  a: Array<PIXI.Container>,
  b: Array<PIXI.Container>
): PIXI.Container {
  for (let p = x; p && p !== root; p = p.parent) {
    if (p === y) {
      // x is a (grand)child of y
      return p;
    }
    a.push(p);
  }

  for (let p = y; p && p !== root; p = p.parent) {
    if (p === x) {
      // y is a (grand)child of x
      a.length = 0;
      return p;
    }

    const idx = a.indexOf(p);
    if (idx !== -1) {
      // src and dst have a common parent p
      a.length = idx;
      return p;
    }

    b.push(p);
  }

  return root;
}

function setupListeners(app: WebClient, w: ConnectorWidget) {
  w.host.visible = false;
  if (!w.src || !w.dst) return;

  if (w.deleteReparentListeners) {
    w.deleteReparentListeners();
    w.deleteReparentListeners = undefined;
  }

  if (w.deleteDepthListeners) {
    w.deleteDepthListeners();
    w.deleteDepthListeners = undefined;
  }

  // Simple case, connector starts and ends with the same widget. We only
  // need to monitor for scale and reparenting in the parent chain up to
  // the CanvasWidget
  if (w.src === w.dst) {
    if (w.host.parent !== w.src.host) {
      w.src.host.addChild(w.host);
      w.invalidated = true;
      regenerate(app, w);
    } else {
      invalidate(app, w);
    }
    return;
  }

  // a is the chain of widgets from src to commonParent, excluding commonParent.
  // b is the same from dst to commonParent.
  const a: Array<PIXI.Container> = [];
  const b: Array<PIXI.Container> = [];
  findCommonParent(w.src.host, w.dst.host, app.view, a, b);

  let reparented = () => setupListeners(app, w);
  for (let w of a) w.on("added", reparented);
  for (let w of b) w.on("added", reparented);

  w.deleteReparentListeners = () => {
    for (let w of a) w.off("added", reparented);
    for (let w of b) w.off("added", reparented);
  };

  // If src is a (grand)child of dst or other way around, either a or b is
  // empty, since commonParent is either src or dst. If both have items, then
  // src and dst are in separate widget hierarchies, so we need to monitor
  // the topmost widget in both hierarchies for depth changes in order to
  // determine which widget is on top. That widget will host this widget.
  if (a.length > 0 && b.length > 0) {
    const w1 = a[a.length - 1];
    const w2 = b[b.length - 1];
    function checkDepth() {
      if (!w.src || !w.dst) return;
      const srcOnTop = w1.zIndex > w2.zIndex;
      const targetParent = srcOnTop ? w.src : w.dst;
      if (w.host.parent !== targetParent.host) {
        // We need to reparent this widget to either src or dst since that
        // is raised higher than our current parent.
        targetParent.host.addChild(w.host);
        w.invalidated = true;
        regenerate(app, w);
      }
    }

    w1.on("depth-changed", checkDepth);
    w2.on("depth-changed", checkDepth);
    w.deleteDepthListeners = () => {
      w1.off("depth-changed", checkDepth);
      w2.off("depth-changed", checkDepth);
    };

    checkDepth();
  } else if (a.length > 0) {
    // b is empty, meaning commonParent is dst and src is a (grand)child of dst
    if (w.host.parent !== w.src.host) w.src.host.addChild(w.host);
  } else if (b.length > 0) {
    if (w.host.parent !== w.dst.host) w.dst.host.addChild(w.host);
  }

  invalidate(app, w);
}

function invalidate(app: WebClient, w: ConnectorWidget) {
  if (!w.invalidated) {
    w.invalidated = true;
    app.setDirty();
    app.run(() => regenerate(app, w));
  }
}

function regenerate(app: WebClient, w: ConnectorWidget) {
  if (!w.invalidated) return;
  w.invalidated = false;

  if (!w.src || !w.dst || w.src.host.destroyed || w.dst.host.destroyed) {
    w.host.visible = false;
    return;
  }

  // TODO: Remove once the bezier builder supports modifications
  w.splines.reset();

  w.host.updateTransform();

  const srcBox = fromWorldToLocal(w.host, widgetWorldBBox(w.src));
  const dstBox = fromWorldToLocal(w.host, widgetWorldBBox(w.dst));

  const srcP = connectorPointLocation(srcBox, w.data.src.rel_location);
  const dstP = connectorPointLocation(dstBox, w.data.dst.rel_location);

  w.canvasScale = 1.0;
  for (let p = w.host.parent; p !== undefined && p !== app.view; p = p.parent) {
    w.canvasScale *= p.scale.x;
  }

  const curve = generateSpline(
    { x: srcP.x, y: srcP.y },
    { x: dstP.x, y: dstP.y },
    w
  );
  generateTips(curve, srcP, dstP, w);
  w.host.visible = true;
  app.setDirty();
}

function connectorPointLocation(bbox: PIXI.Rectangle, relLocation: Vec2): Vec2 {
  const w = bbox.width;
  const h = bbox.height;
  return { x: bbox.left + w * relLocation.x, y: bbox.top + h * relLocation.y };
}

function connectorDir(relLocation: Vec2): Vec2 {
  // Nimble::Math::SQRT2_PER2
  const sqrt2d2 = 0.70710678118654757273;
  if (fuzzyCompare(relLocation.y, 0.0)) {
    if (fuzzyCompare(relLocation.x, 0.0)) {
      return { x: -sqrt2d2, y: -sqrt2d2 };
    } else if (fuzzyCompare(relLocation.x, 1.0)) {
      return { x: sqrt2d2, y: -sqrt2d2 };
    } else {
      return { x: 0.0, y: -1.0 };
    }
  } else if (fuzzyCompare(relLocation.y, 1.0)) {
    if (fuzzyCompare(relLocation.x, 0.0)) {
      return { x: -sqrt2d2, y: sqrt2d2 };
    } else if (fuzzyCompare(relLocation.x, 1.0)) {
      return { x: sqrt2d2, y: sqrt2d2 };
    } else {
      return { x: 0.0, y: 1.0 };
    }
  } else if (fuzzyCompare(relLocation.x, 0.0)) {
    return { x: -1.0, y: 0.0 };
  } else if (fuzzyCompare(relLocation.x, 1.0)) {
    return { x: 1.0, y: 0.0 };
  } else {
    return { x: 0.0, y: 0.0 };
  }
}

function generateSpline(
  srcP: Vec2,
  dstP: Vec2,
  w: ConnectorWidget
): CubicBezierCurve {
  const spline: Array<BezierNode> = [];

  const srcDir = connectorDir(w.data.src.rel_location);
  const dstDir = connectorDir(w.data.dst.rel_location);

  w.srcPoint = { x: srcP.x, y: srcP.y };
  w.dstPoint = { x: dstP.x, y: dstP.y };

  const lineWidth = w.data.line_width / w.canvasScale;
  const r = 0.5 * lineWidth;

  const arrowDistance = lineWidth * 6;

  // With an arrow tip, the curve actually stops roughly where the arrow starts.
  // Otherwise the arrow and the curve itself are drawn on top of each other
  // which makes it impossible to have sharp arrow tip.
  if (w.srcTip === Tip.SolidEquilateralTriangle) {
    srcP.x += srcDir.x * arrowDistance;
    srcP.y += srcDir.y * arrowDistance;
  }

  if (w.dstTip === Tip.SolidEquilateralTriangle) {
    dstP.x += dstDir.x * arrowDistance;
    dstP.y += dstDir.y * arrowDistance;
  }

  const dist = distance(srcP, dstP) * 0.5;

  spline[0] = {
    ctrlIn: { x: 0, y: 0, z: 0 },
    point: { x: srcP.x, y: srcP.y, z: r },
    ctrlOut: { x: srcP.x + srcDir.x * dist, y: srcP.y + srcDir.y * dist, z: r },
  };
  spline[1] = {
    ctrlOut: { x: 0, y: 0, z: 0 },
    point: { x: dstP.x, y: dstP.y, z: r },
    ctrlIn: { x: dstP.x + dstDir.x * dist, y: dstP.y + dstDir.y * dist, z: r },
  };

  // TODO: setStrokePath
  w.splines.reset();
  w.splines.builder.add(strokeLineId, w.lineColor, spline, 1, {
    capBegin: SplineCapStyle.FLAT,
    capEnd: SplineCapStyle.FLAT,
  });
  return createBezierCurve(spline[0], spline[1]);
}

function generateTips(
  curve: CubicBezierCurve,
  srcP: Vec2,
  dstP: Vec2,
  w: ConnectorWidget
) {
  if (w.srcTip === Tip.None) {
    // TODO: setStrokePath
    /*if (!m_srcTip.empty()) {
      m_srcTip.clear();
      m_renderer.setStrokePath(s_strokeSrcTipId, &m_srcTip);
    }*/
  }
  if (w.dstTip === Tip.None) {
    /*if (!m_dstTip.empty()) {
      m_dstTip.clear();
      m_renderer.setStrokePath(s_strokeDstTipId, &m_dstTip);
    }*/
  }
  if (w.srcTip === Tip.None && w.dstTip === Tip.None) return;

  if (w.srcTip === Tip.SolidEquilateralTriangle) {
    const t30 = Math.tan(Math.PI / 6.0);
    const spline = generateSolidArrow(curve, srcP, 8.0 / t30, 8.0);
    w.splines.builder.add(strokeSrcTipId, w.lineColor, spline, 2, {
      capBegin: SplineCapStyle.FLAT,
      capEnd: SplineCapStyle.FLAT,
    });
  }

  if (w.dstTip === Tip.SolidEquilateralTriangle) {
    const reversed = bezierReversed(curve);
    const t30 = Math.tan(Math.PI / 6.0);
    const spline = generateSolidArrow(reversed, dstP, 8.0 / t30, 8.0);
    w.splines.builder.add(strokeDstTipId, w.lineColor, spline, 3, {
      capBegin: SplineCapStyle.FLAT,
      capEnd: SplineCapStyle.FLAT,
    });
  }
}

function arrowDirection(curve: CubicBezierCurve): Vec2 {
  const dir = bezierTangent2D(curve, 0);
  const len = length(dir);
  if (len <= 0.01) {
    return normalized(bezierTangent2D(curve, 0.01));
  } else {
    return { x: dir.x / len, y: dir.y / len };
  }
}

function generateSolidArrow(
  curve: CubicBezierCurve,
  c: Vec2,
  relativeLength: number,
  relativeWidth: number
): [BezierNode, BezierNode] {
  const dir = arrowDirection(curve);

  const r = curve.p0.z;
  const z = r * relativeWidth;
  const x = c.x + dir.x * r * relativeLength;
  const y = c.y + dir.y * r * relativeLength;

  return [
    {
      ctrlIn: { x: 0, y: 0, z: 0 },
      point: { x: c.x, y: c.y, z: 0 },
      ctrlOut: { x: c.x, y: c.y, z: 0 },
    },
    {
      ctrlOut: { x: 0, y: 0, z: 0 },
      point: { x, y, z },
      ctrlIn: { x, y, z },
    },
  ];
}

function parseTip(t: ConnectorTip) {
  switch (t) {
    case "solid-equilateral-triangle":
      return Tip.SolidEquilateralTriangle;

    case "none":
      return Tip.None;
  }
  console.error("Unknown connector tip", t);
  return Tip.None;
}

export function syncConnectorWidget(
  app: WebClient,
  event: ConnectorWidgetEvent
) {
  let w: ConnectorWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as ConnectorWidget;
    if (w.data.src.id !== event.src.id) {
      if (w.deleteSrcChangeListener) {
        w.deleteSrcChangeListener();
        w.deleteSrcChangeListener = undefined;
      }
      w.src = undefined;
    }
    if (w.data.dst.id !== event.dst.id) {
      if (w.deleteDstChangeListener) {
        w.deleteDstChangeListener();
        w.deleteDstChangeListener = undefined;
      }
      w.dst = undefined;
    }
    w.data = event;
    w.srcTip = parseTip(event.src.tip);
    w.dstTip = parseTip(event.dst.tip);
    w.lineColor = hexToColor(event.line_color);
  } else {
    w = {
      id: event.id,
      host: new PIXI.Container(),
      splines: new AnnotationContainer(),
      type: "Connector",
      data: event,
      srcTip: parseTip(event.src.tip),
      dstTip: parseTip(event.dst.tip),
      lineColor: hexToColor(event.line_color),
      invalidated: false,
      srcPoint: { x: 0, y: 0 },
      dstPoint: { x: 0, y: 0 },
      canvasScale: 1.0,
      bbox: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    };
    w.host.once("destroyed", () => {
      if (w.deleteReparentListeners) {
        w.deleteReparentListeners();
        w.deleteReparentListeners = undefined;
      }
      if (w.deleteSrcChangeListener) {
        w.deleteSrcChangeListener();
        w.deleteSrcChangeListener = undefined;
      }
      if (w.deleteDstChangeListener) {
        w.deleteDstChangeListener();
        w.deleteDstChangeListener = undefined;
      }
      if (w.deleteDepthListeners) {
        w.deleteDepthListeners();
        w.deleteDepthListeners = undefined;
      }
    });
    w.host.name = "ConnectorWidget";
    w.host.addChild(w.splines);
    app.widgets.set(event.id, w);
  }

  if (!w.host.interactive) {
    (w.host as any).canvasItem = true;
    w.host.interactive = true;
  }

  app.pixi.ticker.addOnce(() => {
    findWidgets(app, w);
  });
}
