import { Spin, Typography } from "antd";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { useUsersContext } from "../../components/UsersProvider";
import { ICanvas, IPermissionRequest } from "../../interfaces";
import { formatBytes, formatDate } from "../../util";
import { CanvasPreview } from "./CanvasPreview";
import { findOwnerId } from "./DetailsFolder";

export interface IDetailsCanvasProps {
  canvas: ICanvas;
  external_url: string;
}

// Provides detailed information for a given canvas.
export const DetailsCanvas: React.FunctionComponent<IDetailsCanvasProps> = (
  props
) => {
  const url = `/canvases/${props.canvas.id}/permissions`;

  // This doesn't subscribe for changes to keep this simple. It shouldn't matter
  // in this component.
  const { data } = useQuery<IPermissionRequest, AxiosError>(url);
  const { users } = useUsersContext();

  let ownerName;

  if (data) {
    const ownerId = findOwnerId(data);

    if (ownerId) {
      const owner = users.get(ownerId);

      if (owner) {
        ownerName = owner.name;
      }
    }
  } else {
    ownerName = <Spin />;
  }

  return (
    <div className="resource-details-container" data-cy="canvas-details">
      <CanvasPreview
        canvas={props.canvas}
        external_url={props.external_url}
        tags={true}
      />
      <Typography.Paragraph className="title" strong>
        {props.canvas.name}
      </Typography.Paragraph>

      <div className="row">
        <Typography.Text strong>Owner</Typography.Text>
        <Typography.Text>{ownerName}</Typography.Text>
      </div>

      <div className="row">
        <Typography.Text strong>Modified</Typography.Text>
        <Typography.Text>
          {formatDate(props.canvas.modified_at)}
        </Typography.Text>
      </div>
      <div className="row">
        <Typography.Text strong>Created</Typography.Text>
        <Typography.Text>{formatDate(props.canvas.created_at)}</Typography.Text>
      </div>
      <div className="row">
        <Typography.Text strong>Assets</Typography.Text>
        <Typography.Text>
          {formatBytes(props.canvas.asset_size)}
        </Typography.Text>
      </div>
      <div className="row">
        <Typography.Text strong>Resource Id</Typography.Text>
        <Typography.Text
          copyable={{
            text: props.canvas.id,
            tooltips: ["Copy resource id for REST API.", "Copied."],
          }}
        ></Typography.Text>
      </div>
    </div>
  );
};
