import React, { useEffect, useState } from "react";
import { Statistic, Row, Col, Card } from "antd";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import UsersProvider, { useUsersContext } from "../../components/UsersProvider";
import GroupsProvider, {
  useGroupsContext,
} from "../../components/GroupsProvider";
import CanvasesProvider, {
  useCanvasesContext,
} from "../../components/CanvasesProvider";

export const AdminDashboardPage: React.FunctionComponent = () => {
  return (
    <UsersProvider>
      <GroupsProvider>
        <CanvasesProvider>
          <AdminDashboardPageContent />
        </CanvasesProvider>
      </GroupsProvider>
    </UsersProvider>
  );
};

const AdminDashboardPageContent: React.FunctionComponent = () => {
  const { users } = useUsersContext();
  const { groups } = useGroupsContext();
  const { canvases } = useCanvasesContext();

  const [serverVersion, setServerVersion] = useState("N/A");

  // Query the server version from the API and invoke given callback with the
  // result.
  async function queryServerVersion(callback: (version: string) => void) {
    try {
      const res = await axios.get("/api/dashboard/server-info");
      callback(res.data.version);
    } catch (err) {
      console.error("Failed to query server-info:", err);
    }
  }

  useEffect(() => {
    queryServerVersion(setServerVersion);
  }, []);

  return (
    <AdminLayout selectedSideMenuKey="dashboard">
      <Card>
        <Row>
          <Col span="6">
            <Statistic title="Canvases" value={canvases.size}></Statistic>
          </Col>
          <Col span="6">
            <Statistic title="Users" value={users.size}></Statistic>
          </Col>
          <Col span="6">
            <Statistic title="Groups" value={groups.size} />
          </Col>
          <Col span="6">
            <Statistic title="Server" value={serverVersion} />
          </Col>
        </Row>
      </Card>
    </AdminLayout>
  );
};
