import React, { useState, useEffect } from "react";
import { Card, Input, Table, Typography } from "antd";
import "./LoginPage.less";
import GroupsProvider, {
  IGroup,
  useGroupsContext,
} from "../components/GroupsProvider";
import PageLayout from "./PageLayout";

function formatTable() {
  return [
    {
      title: "Group",
      render: (_text: unknown, record: IGroup) => {
        return (
          <span>
            <Typography.Text strong>{record.name}</Typography.Text>
            <br />
            <Typography.Text>{record.description}</Typography.Text>
          </span>
        );
      },
    },
  ];
}

export const GroupsPage: React.FunctionComponent = () => {
  return (
    <GroupsProvider>
      <GroupsPageContent />
    </GroupsProvider>
  );
};

const GroupsPageContent: React.FunctionComponent = () => {
  const { groups } = useGroupsContext();
  const [filterString, setFilterString] = useState<string>("");
  const [filteredGroups, setFilteredGroups] = useState<IGroup[]>(
    Array.from(groups.values())
  );

  const columns = formatTable();

  // Only search in visible columns to avoid confusing the user. Use string
  // instead of an array to workaround the fact that arrays can't be compared in
  // JavaScript. This avoids infinite loop in the hook below.
  const keysToSearch = "name,description";

  // Handle table filtering. Modifying filters will trigger hook below to do
  // the actual filtering.
  function onFilterStringChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterString(event.target.value.toLowerCase());
  }

  // Hook to perform the actual data filtering
  useEffect(() => {
    const result = Array.from(groups.values()).filter((group) => {
      // Convert string back to array
      const keyArray = keysToSearch.split(",");

      // Filter by search string
      for (const key in group) {
        if (keyArray.includes(key)) {
          const value = String((group as any)[key]).toLowerCase();

          if (value.includes(filterString)) return true;
        }
      }

      return false;
    });

    setFilteredGroups(result);
  }, [groups, filterString, keysToSearch]);

  return (
    <PageLayout selectedTopNavKey="groups">
      <Card>
        <div className="table-header">
          <Input.Search
            className="filter-input"
            placeholder="Filter results..."
            onChange={onFilterStringChange}
          ></Input.Search>
        </div>
        <Table<IGroup>
          columns={columns}
          dataSource={filteredGroups}
          rowKey="id"
        />
      </Card>
    </PageLayout>
  );
};
