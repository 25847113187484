import React, { useEffect, useReducer, Reducer, useState } from "react";
import { createContext, useContext } from "react";
import { subscribe, unsubscribe } from "../backend";
import { IFolder } from "../interfaces";
import {
  DeleteOutlined,
  HomeOutlined,
  FolderOutlined,
} from "@ant-design/icons";

// Returns true if the folder is the server root folder
export function isRootFolder(folder: IFolder): boolean {
  return folder.folder_id === "";
}

// Regular expressions to determine folder type from id
const reIsTrash: RegExp = /^trash/;
const reIsHome: RegExp = /^[0-9]+$/;

// Returns true if the folder id matches a trash folder
export function isTrashFolderId(folderId: string): boolean {
  return reIsTrash.test(folderId);
}

// Returns true if the folder is a trash folder
export function isTrashFolder(folder: IFolder): boolean {
  return isTrashFolderId(folder.id);
}

// Given a folder, traverse the hierarchy up until encountering a trash folder.
export function parentTrashFolder(
  folder: IFolder,
  folders: FolderByIdMap
): IFolder | undefined {
  let f: IFolder | undefined = folder;

  while (f) {
    if (isTrashFolder(f)) return f;

    f = folders.get(f.folder_id);
  }

  return undefined;
}

// Returns true if the folder is a home folder
export function isHomeFolderId(folderId: string): boolean {
  return reIsHome.test(folderId);
}

// Returns true if the folder is a home folder
export function isHomeFolder(folder: IFolder): boolean {
  return isHomeFolderId(folder.id);
}

// Returns an icon for the given folder
export function folderIcon(folder: IFolder): JSX.Element {
  if (isTrashFolder(folder)) return <DeleteOutlined />;
  if (isHomeFolder(folder)) return <HomeOutlined />;

  return <FolderOutlined />;
}

export type FolderByIdMap = Map<string, IFolder>;

export interface IFoldersContext {
  isLoading: boolean;
  folders: FolderByIdMap;
}

const initialValue: IFoldersContext = {
  isLoading: true,
  folders: new Map(),
};

// Apply given change to a map of folders
/// TODO: this could be a generic function that is re-used in all providers
function mutateFolders(folders: FolderByIdMap, change: IFolder) {
  if (change.state === "deleted") {
    folders.delete(change.id);
  } else {
    folders.set(change.id, change);
  }
}

export const FoldersContext = createContext<IFoldersContext>(initialValue);

const FoldersReducer: Reducer<FolderByIdMap, IFolder[]> = (
  prevState,
  changes
) => {
  // Must make a copy and not modify prevState
  let state = new Map(prevState);

  changes.forEach((change) => {
    mutateFolders(state, change);
  });

  return state;
};

// Provides a Map of all folders (the user can access) by their id
const FoldersProvider: React.FunctionComponent = (children) => {
  const [state, dispatch] = useReducer(FoldersReducer, new Map());

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const endpoint = "/canvas-folders";

    function handleFoldersChange(changes: IFolder[]) {
      dispatch(changes);
      setIsLoading(false);
    }

    subscribe(endpoint, handleFoldersChange);

    return function cleanup() {
      unsubscribe(endpoint, handleFoldersChange);
    };
  }, []);

  const contextData: IFoldersContext = {
    isLoading: isLoading,
    folders: state,
  };

  return <FoldersContext.Provider value={contextData} {...children} />;
};

export const useFoldersContext = () => useContext(FoldersContext);

export default FoldersProvider;
