import React, { useState, useEffect } from "react";
import { Card, Input, Table, Button, Space, Modal, message } from "antd";
import AdminLayout from "./AdminLayout";
import { ExclamationOutlined } from "@ant-design/icons";

import "./AdminGroupsPage.less";
import Axios from "axios";
import { Link } from "react-router-dom";
import { allUsersGroupId } from "../../constants";
import GroupsProvider, {
  useGroupsContext,
  IGroup,
} from "../../components/GroupsProvider";

function deleteGroup(group: IGroup) {
  Modal.confirm({
    title: "Delete group " + group.name + "?",
    icon: <ExclamationOutlined />,
    content: (
      <div>
        <p>Are you sure you want to delete the group?</p>
      </div>
    ),
    onOk() {
      Axios.delete("/api/dashboard/groups/" + group.id)
        .then((_response) => {
          message.success(`The group '${group.name}' was deleted.`);
        })
        .catch((error) => {
          console.error("Failed to delete group:", error.response);
          const msgFromServer = error.response?.data?.msg;
          message.error(
            `Failed to delete group '${group.name}'. (${msgFromServer})`
          );
        });
    },
  });
}

function formatTable() {
  // TODO: map "created at" and "last activity" to API results once available
  return [
    {
      title: "Group",
      dataIndex: "name",
      render: (_text: unknown, record: IGroup) => {
        return (
          <>
            <b>{record.name}</b>
            <br />
            {record.description}
          </>
        );
      },
    },
    {
      title: "",
      // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      align: "right" as "right",
      render: (_text: unknown, record: IGroup) => {
        return (
          <Space size="small">
            <Link to={"/admin/groups/" + record.id + "/edit"}>
              <Button disabled={record.id === allUsersGroupId}>Edit</Button>
            </Link>
            <Button
              disabled={record.id === allUsersGroupId}
              danger
              onClick={() => deleteGroup(record)}
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];
}

export const AdminGroupsPage: React.FunctionComponent = () => {
  return (
    <GroupsProvider>
      <AdminGroupsPageContent />
    </GroupsProvider>
  );
};

const AdminGroupsPageContent: React.FunctionComponent = () => {
  const { groups } = useGroupsContext();
  const [filterString, setFilterString] = useState<string>("");
  const [filteredGroups, setFilteredGroups] = useState<IGroup[]>(
    Array.from(groups.values())
  );
  const columns = formatTable();

  // Only search in visible columns to avoid confusing the user. Use string
  // instead of an array to workaround the fact that arrays can't be compared in
  // JavaScript. This avoids infinite loop in the hook below.
  const keysToSearch = columns
    .filter((x) => x.dataIndex)
    .map((x) => x.dataIndex)
    .join();

  // Handle table filtering. Modifying filters will trigger hook below to do
  // the actual filtering.
  function onFilterStringChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFilterString(event.target.value.toLowerCase());
  }

  // Hook to perform the actual data filtering
  useEffect(() => {
    const result = Array.from(groups.values()).filter((group) => {
      // Convert string back to array
      const keyArray = keysToSearch.split(",");

      // Filter by search string
      for (const key in group) {
        if (keyArray.includes(key)) {
          const value = String((group as any)[key]).toLowerCase();

          if (value.includes(filterString)) return true;
        }
      }

      return false;
    });

    setFilteredGroups(result);
  }, [groups, filterString, keysToSearch]);

  return (
    <AdminLayout selectedSideMenuKey="groups">
      <Card>
        <div className="table-header">
          <Input.Search
            className="filter-input"
            placeholder="Filter results..."
            onChange={onFilterStringChange}
          ></Input.Search>
          <Link to="/admin/groups/new">
            <Button type="primary" className="inline-button">
              New group
            </Button>
          </Link>
        </div>
        <Table<IGroup>
          columns={columns}
          dataSource={filteredGroups}
          rowKey="id"
        />
      </Card>
    </AdminLayout>
  );
};
