import { Breadcrumb } from "antd";
import {
  FolderByIdMap,
  isRootFolder,
  useFoldersContext,
} from "../../components/FoldersProvider";
import { DatabaseOutlined } from "@ant-design/icons";

export interface IFolderBreadcrumbsProps {
  folderId: string;
  onNavigateToFolder: (folderId: string) => void;
}

// Breadcrumb navigation and visualization of parent folder hierarchy
export const FolderBreadcrumbs: React.FunctionComponent<IFolderBreadcrumbsProps> =
  (props) => {
    const { folders } = useFoldersContext();

    const breadcrumbs = getBreadcrumbs(props.folderId, folders);

    return (
      <Breadcrumb>
        {breadcrumbs.map((folderId) => {
          const folder = folders.get(folderId);
          if (folder) {
            if (isRootFolder(folder)) {
              return (
                <Breadcrumb.Item
                  key={folderId}
                  href="#"
                  onClick={() => {
                    props.onNavigateToFolder(folderId);
                  }}
                >
                  <DatabaseOutlined />
                </Breadcrumb.Item>
              );
            }
            return (
              <Breadcrumb.Item
                key={folderId}
                href="#"
                onClick={() => {
                  props.onNavigateToFolder(folderId);
                }}
              >
                {folder.name}
              </Breadcrumb.Item>
            );
          } else {
            console.error(`FolderBreadcrumbs: folder ${folderId} is undefined`);
          }

          return <Breadcrumb.Item>undefined</Breadcrumb.Item>;
        })}
      </Breadcrumb>
    );
  };

// Given the current folder id, get an array of its parent folder ids with
// current folder ordered as last element
function getBreadcrumbs(folderId: string, folders: FolderByIdMap): string[] {
  const result: string[] = [];

  let folder = folders.get(folderId);
  while (folder !== undefined) {
    result.push(folder.id);
    folder = folders.get(folder.folder_id);
  }

  return result.reverse();
}
