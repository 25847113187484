import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { CanvusHeader } from "../../components/CanvusHeader";
import { Link } from "react-router-dom";
import { LockOutlined, UserOutlined, ApiOutlined } from "@ant-design/icons";

import "../Layout.less";

export interface IProfilePageProps {
  selectedSideMenuKey: string;
}

const ProfileLayout: React.FunctionComponent<IProfilePageProps> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Layout className="site-layout-background">
      <Layout.Header>
        <CanvusHeader selectedTopNavKey="profile" />
      </Layout.Header>

      <Layout className="site-layout">
        <Layout.Sider
          collapsible
          collapsed={collapsed}
          onCollapse={setCollapsed}
          breakpoint="md"
        >
          <Menu
            theme="dark"
            defaultSelectedKeys={[props.selectedSideMenuKey]}
            mode="inline"
          >
            <Menu.ItemGroup key="g1" title="User Settings">
              <Menu.Item key="account" icon={<UserOutlined />}>
                Account
                <Link to="/profile" />
              </Menu.Item>
              <Menu.Item key="password" icon={<LockOutlined />}>
                Password
                <Link to="/profile/password" />
              </Menu.Item>
              <Menu.Item key="tokens" icon={<ApiOutlined />}>
                Access Tokens
                <Link to="/profile/access-tokens" />
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu>
        </Layout.Sider>

        <Layout.Content>{props.children}</Layout.Content>
      </Layout>
    </Layout>
  );
};

export default ProfileLayout;
