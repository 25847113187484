import { Row } from "antd";
import { isHomeFolder, isTrashFolder } from "../../components/FoldersProvider";
import { IFolder } from "../../interfaces";
import {
  DeleteOutlined,
  HomeOutlined,
  FolderOutlined,
} from "@ant-design/icons";

export interface IFolderPreviewProps {
  folder: IFolder;
  tags: boolean;
}

// Provides a static preview image of a canvas.
export const FolderPreview: React.FunctionComponent<IFolderPreviewProps> = (
  props
) => {
  const tags = props.tags ? <Row className="tags"></Row> : undefined;

  // Select an icon for the folder
  let folderIcon: JSX.Element;
  if (isTrashFolder(props.folder)) folderIcon = <DeleteOutlined />;
  else if (isHomeFolder(props.folder)) folderIcon = <HomeOutlined />;
  else folderIcon = <FolderOutlined />;

  return (
    <div className="folder resource-preview">
      {folderIcon}
      {tags}
    </div>
  );
};
