import React from "react";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Tooltip } from "antd";

dayjs.extend(RelativeTime);
dayjs.extend(LocalizedFormat);

// Persist React state in sessionStorage. Uses similar API like setState().
// https://dev.to/selbekk/persisting-your-react-state-in-9-lines-of-code-9go
export function usePersistedState(key: string, defaultValue: any) {
  const [state, setState] = React.useState(() => {
    const data = sessionStorage.getItem(key);
    if (!data) return defaultValue;
    return JSON.parse(data);
  });
  React.useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number, decimals: number = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

// Format date in a readable format
export function formatDate(date: string) {
  if (date === undefined) return "N/A";

  const d = new Date(date);

  return d.toLocaleDateString("default", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

// Format date in "fromNow" format
export function formatDateFromNow(data: string) {
  const inLocalTime = dayjs(data).format("lll");
  const inFromNow = dayjs(data).fromNow();

  return (
    <Tooltip title={inLocalTime}>
      <span>{inFromNow}</span>
    </Tooltip>
  );
}

// https://medium.com/@kayathiri/text-file-download-in-react-a8b28a580c0d
export function downloadTextFile(filename: string, content: string) {
  const element = document.createElement("a");
  const file = new Blob([content], { type: "text/plain;charset=utf-8" });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}

// Generate custom Canvus url from external url and canvas id
export function toCanvusUrl(externalUrl: URL, canvasId: string): URL {
  // Determine protocol to use from externalUrl
  const protocol =
    externalUrl.protocol === "http:" ? "canvus+tcp:" : "canvus+ssl:";

  return new URL(`${protocol}//${externalUrl.host}/${canvasId}`);
}
