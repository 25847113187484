import { IUserInfo } from "./components/AuthProvider";
import { CanvasByIdMap } from "./components/CanvasesProvider";
import {
  FolderByIdMap,
  isHomeFolder,
  isRootFolder,
  isTrashFolder,
} from "./components/FoldersProvider";
import { IFolder } from "./interfaces";

// Store resource id as data for dragged item
export type DraggedItem = { id: string };

// Check if dragging a resource is permitted
export function isDragAllowed(
  draggedResourceId: string,
  user: IUserInfo,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
): boolean {
  // Rules for dragged canvases
  const canvas = canvases.get(draggedResourceId);
  if (canvas !== undefined) {
    // TODO: if we allow drag & drop copy, need to allow "view" here
    if (!user.admin && canvas.access !== "edit" && canvas.access !== "owner")
      return false;
  }

  // Rules for dragged folders
  const folder = folders.get(draggedResourceId);
  if (folder !== undefined) {
    // Root folder can't be dragged
    if (isRootFolder(folder)) return false;

    // Trash folder can't be dragged
    if (isTrashFolder(folder)) return false;

    // Home folders can't be dragged
    if (isHomeFolder(folder)) return false;

    // TODO: if we allow drag & drop copy, need to allow "view" here
    if (!user.admin && folder.access !== "edit" && folder.access !== "owner")
      return false;
  }

  return true;
}

// Check if dropping a resource is permitted to a specific folder
export function isDropAllowed(
  targetFolder: IFolder,
  draggedResourceId: string,
  user: IUserInfo,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
): boolean {
  // Drop is not allowed on folders inside trash
  if (targetFolder.in_trash) return false;

  // Can't drop on itself
  if (draggedResourceId === targetFolder.id) return false;

  // Check access to target folder for regular users
  if (user.admin === false) {
    const isWritable =
      targetFolder.access === "owner" || targetFolder.access === "edit";
    if (!isWritable) return false;
  }

  // Rules for dragged canvases
  const canvas = canvases.get(draggedResourceId);
  if (canvas !== undefined) {
    // No canvases allowed in root folder
    if (isRootFolder(targetFolder)) return false;

    // TODO: if we allow drag & drop copy, need to allow "view" here
    if (!user.admin && canvas.access !== "edit" && canvas.access !== "owner")
      return false;
  }

  // Rules for dragged folders
  const folder = folders.get(draggedResourceId);
  if (folder !== undefined) {
    // Only admin can put folders in root folder
    if (isRootFolder(targetFolder) && !user.admin) return false;

    // TODO: if we allow drag & drop copy, need to allow "view" here
    if (!user.admin && folder.access !== "edit" && folder.access !== "owner")
      return false;
  }

  return true;
}
