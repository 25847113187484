import * as PIXI from "pixi.js";
import {
  CanvasBackgroundEvent,
  CanvasBackgroundImage,
} from "../canvas-element-events";
import { MipmapSprite, MipmapTilingSprite } from "../images/mipmap-sprite";
import { hexToTintAndAlpha } from "../math/color";
import { WebClient } from "../web-client";
import { CanvasWidget } from "./canvas";

export type CanvasBackground = {
  host: PIXI.Sprite;
  image?: MipmapSprite | MipmapTilingSprite;
  imageProps: CanvasBackgroundImage;
};

function syncImage(bg: CanvasBackground, canvas: CanvasWidget) {
  if (
    !bg.image ||
    (bg.image.texture.width <= 1.0 && bg.image.texture.height <= 1.0)
  )
    return;
  const canvasW = canvas.bbox.right - canvas.bbox.left;
  const canvasH = canvas.bbox.bottom - canvas.bbox.top;
  const texW = bg.image.texture.baseTexture.width;
  const texH = bg.image.texture.baseTexture.height;
  const sx = canvasW / texW;
  const sy = canvasH / texH;
  let scale;

  switch (bg.imageProps.fit) {
    case "fill":
      scale = Math.max(sx, sy);
      bg.image.scale.set(scale);
      const x = 0.5 * (texW - canvasW / scale);
      const y = 0.5 * (texH - canvasH / scale);
      bg.image.texture.frame = new PIXI.Rectangle(
        x,
        y,
        texW - 2.0 * x,
        texH - 2.0 * y
      );
      bg.image.texture.updateUvs();
      break;
    case "fit":
      scale = Math.min(sx, sy);
      bg.image.scale.set(scale);
      bg.image.x = 0.5 * (canvasW - texW * scale);
      bg.image.y = 0.5 * (canvasH - texH * scale);
      break;
    case "stretch":
    case "tile":
      bg.image.width = canvasW;
      bg.image.height = canvasH;
      break;
  }
}

export function syncCanvasBackground(
  app: WebClient,
  event: CanvasBackgroundEvent,
  canvas: CanvasWidget
) {
  let bg: CanvasBackground;
  if (canvas.bg) {
    bg = canvas.bg;
  } else {
    bg = {
      host: new PIXI.Sprite(PIXI.Texture.WHITE),
      imageProps: event.image,
    };
    bg.host.name = "Canvas background";
    bg.host.zIndex = -2;
    canvas.host.addChild(bg.host);
    canvas.bg = bg;
  }
  bg.host.tint = hexToTintAndAlpha(event.background_color)[0];
  bg.host.width = canvas.bbox.right - canvas.bbox.left;
  bg.host.height = canvas.bbox.bottom - canvas.bbox.top;

  switch (event.type) {
    case "image":
      if (event.image.hash === "") {
        if (bg.image) bg.image.destroy();
        bg.image = undefined;
      } else if (
        event.image.fit !== bg.imageProps.fit ||
        event.image.hash !== bg.imageProps.hash ||
        !bg.image
      ) {
        if (bg.image) bg.image.destroy();
        switch (event.image.fit) {
          case "tile":
            bg.image = MipmapTilingSprite.create(app, event.image.hash);
            break;
          case "fit":
          case "stretch":
            bg.image = MipmapSprite.create(app, event.image.hash);
            break;
          case "fill":
            // don't use shared textures with "fill" type, since we are modifying the texture frame
            bg.image = MipmapSprite.create(app, event.image.hash, 0, false);
            break;
        }
        bg.image.name = "Canvas background image";
        bg.image.zIndex = -1;
        bg.image.on("update", () => syncImage(bg, canvas));
        canvas.host.addChild(bg.image);
      }

      bg.imageProps = event.image;
      syncImage(bg, canvas);
      break;

    case "solid_color":
    case "haze":
      if (bg.image) {
        bg.image.destroy();
        bg.image = undefined;
      }
      break;
  }
}
