import * as PIXI from "pixi.js";

export async function loadFont(
  name: string,
  fntUrl: string,
  imgUrl: string
): Promise<string> {
  const image = new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", reject);
    img.src = imgUrl;
  });

  const resp = await fetch(fntUrl);
  const txt = await resp.text();
  const fnt = new window.DOMParser().parseFromString(txt, "text/xml");

  /// TODO: Would need to use some kind of texture lod bias with msdf fonts,
  /// otherwise mipmaps makes them blurry.
  const tex = PIXI.Texture.from(await image, { mipmap: PIXI.MIPMAP_MODES.OFF });
  const installedName = PIXI.BitmapFont.install(fnt, tex, true).font;
  if (installedName !== name) {
    throw new Error(
      `Unexpected font name '${installedName}', expected '${name}'`
    );
  }
  return name;
}
