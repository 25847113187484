import { Button, Dropdown, Tooltip } from "antd";
import { useState } from "react";
import { EllipsisOutlined, CheckOutlined } from "@ant-design/icons";

export interface ITileHeaderProps {
  title: string;
  lastModified?: string;
  isDropdownVisible: boolean;
  menu: JSX.Element;
}

export function TileHeader(props: ITileHeaderProps) {
  const [nameOverflows, setNameOverflows] = useState(false);

  return (
    <div className="header">
      <span
        className="info"
        ref={(el: HTMLSpanElement | null) => {
          if (!el) {
            // Called with null when component unmounts
            return;
          }
          setNameOverflows(el.offsetWidth < el.scrollWidth);
        }}
      >
        {nameOverflows ? (
          <Tooltip placement="topLeft" title={props.title}>
            {props.title}
          </Tooltip>
        ) : (
          props.title
        )}
        <br />
        {props.lastModified && (
          <span className="modified">{`Modified: ${props.lastModified}`}</span>
        )}
      </span>
      <span>
        {props.isDropdownVisible && (
          <Dropdown overlay={props.menu} trigger={["click"]}>
            <Button
              type="text"
              onClick={(e) => {
                // Stop event propagation so that clicking on button does
                // not trigger selection.
                e.stopPropagation();
              }}
              onDoubleClick={(e) => {
                // Do not open canvas if double-click on menu
                e.stopPropagation();
              }}
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        )}
        {!props.isDropdownVisible && (
          <CheckOutlined style={{ fontSize: "18pt", marginRight: "8px" }} />
        )}
      </span>
    </div>
  );
}
