import * as PIXI from "pixi.js";
import { VideoWidgetEvent } from "../canvas-element-events";
import { onSingleTap } from "../interaction";
import { Widget, WebClient } from "../web-client";
import { syncCanvasItem } from "../widget-synchronizer";
import { setBBoxSize } from "./helpers";

export type VideoWidget = Widget & {
  updateInterval: number;
  video: PIXI.Sprite;
};

/*async function findVideoMimeType(hash: string) {
  // The server doesn't support HEAD requests, so use GET with one byte range instead
  const response = await fetch(`/api/v1/assets/${hash}`, {
    headers: {
      Range: "bytes=0-0",
    },
  });
  if (!response.ok) {
    throw Error(response.statusText);
  }
  const mime = response.headers.get("content-type");
  if (mime === null || !mime.startsWith("video/")) {
    throw Error("Unknown mime type");
  }
  return mime;
}*/

async function loadVideo(
  app: WebClient,
  w: VideoWidget,
  sprite: PIXI.Sprite,
  hash: string
) {
  // TODO: handle errors
  //const mime = await findVideoMimeType(hash);
  if (w.video !== sprite) return;

  const video = document.createElement("video");

  // https://github.com/pixijs/pixi.js/issues/5996
  video.setAttribute("preload", "auto");
  video.setAttribute("webkit-playsinline", "");
  video.setAttribute("playsinline", "");

  const src = document.createElement("source");
  src.src = `/api/v1/assets/${hash}`;
  // TODO: It seems like using correct mime type sometimes prevents videos
  // from playing, like with video/quicktime on Chrome on Linux.
  //src.type = mime;
  src.type = "video/mp4";

  video.appendChild(src);

  const res = new PIXI.VideoResource(video, { autoPlay: false });
  const baseTexture = new PIXI.BaseTexture(res);
  sprite.texture = new PIXI.Texture(baseTexture);
  sprite.width = w.bbox.right - w.bbox.left;
  sprite.height = w.bbox.bottom - w.bbox.top;
  // TODO: this now leaks listeners if the video changes
  onSingleTap(w.host, (e: PIXI.InteractionEvent) => {
    if (w.updateInterval === 0) {
      res.source.play();
      /// There's really no good way to do this in JS right now
      w.updateInterval = window.setInterval(() => {
        app.setDirty();
      }, 1000 / 30);
    } else {
      res.source.pause();
      window.clearInterval(w.updateInterval);
      w.updateInterval = 0;
    }
  });
}

export function syncVideoWidget(app: WebClient, event: VideoWidgetEvent) {
  let w: VideoWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as VideoWidget;
    // TODO: What if event.hash changed?
    w.title = event.title;
    setBBoxSize(w, event.size);
    w.video.width = w.bbox.right - w.bbox.left;
    w.video.height = w.bbox.bottom - w.bbox.top;
  } else {
    const host = new PIXI.Container();
    host.name = "VideoWidget";

    const video = new PIXI.Sprite();
    host.addChild(video);
    w = {
      id: event.id,
      host,
      video,
      type: "Video",
      title: event.title,
      updateInterval: 0,
      downloadUrl: `/api/v1/canvases/${app.canvasId}/videos/${event.id}/download`,
      bbox: {
        left: 0,
        top: 0,
        right: event.size.width,
        bottom: event.size.height,
      },
    };

    loadVideo(app, w, video, event.hash);
    app.widgets.set(event.id, w);
  }

  syncCanvasItem(app, event, w);
}
