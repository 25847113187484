import { Result } from "antd";
import React from "react";
import { AxiosError } from "axios";

export interface IQueryFailureProps {
  title: string;
  error: AxiosError<any>;
}

export const QueryFailure: React.FunctionComponent<IQueryFailureProps> = (
  props
) => {
  const title = props.title;
  const error = props.error;

  console.error(title, error);

  if (error.response) {
    // We received an error response (5xx, 4xx)
    return (
      <Result status="error" title={title} subTitle={error.response.data.msg} />
    );
  } else if (error.request) {
    // We never received a response or the request never left
    return (
      <Result
        status="500"
        title={title}
        subTitle="We received no response or our request never left."
      />
    );
  } else {
    // Something else failed (bug in the application?)
    return (
      <Result status={500} title={title} subTitle="Something went wrong." />
    );
  }
};
