import { Form, Input, Menu, message, Typography } from "antd";
import Axios from "axios";
import { ICanvas, IFolder, IResource } from "../../interfaces";
import { ModalStaticFunctions } from "antd/lib/modal/confirm";
import {
  deleteFolder,
  duplicateCanvas,
  duplicateFolder,
  moveFolder,
  patchCanvas,
  patchFolder,
} from "../../api";
import {
  DeleteOutlined,
  EditOutlined,
  DesktopOutlined,
  LinkOutlined,
  ShareAltOutlined,
  InfoCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { DetailsFolder } from "./DetailsFolder";
import { PermissionsTable } from "../../components/PermissionsTable";
import { FolderByIdMap, isTrashFolder } from "../../components/FoldersProvider";
import { deleteCanvasWithConfirm } from "./PreviewTileCanvas";
import { DetailsCanvas } from "./DetailsCanvas";
import { toCanvusUrl } from "../../util";
import { IUserInfo } from "../../components/AuthProvider";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Returns a dropdown menu for given trash folder.
export function menuForTrashFolder(
  folder: IFolder,
  modal: Omit<ModalStaticFunctions, "warn">
) {
  return (
    <Menu>
      <Menu.Item
        key="empty-trash"
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.confirm({
            content:
              "Are you sure you want to permanently erase the items in trash?",
            okText: "Empty trash",
            onOk: () => {
              Axios.delete(
                `/api/dashboard/canvas-folders/${folder.id}/children`
              ).catch((error) => {
                const serverReply = error.response?.data?.msg;
                const msg = `Failed to empty trash folder`;

                console.error(msg, error.response);
                message.error(msg + ` (${serverReply})`);
              });
            },
          });
        }}
      >
        Empty trash
      </Menu.Item>
    </Menu>
  );
}

// Returns a dropdown menu for the given folder.
export function menuForFolder(
  folder: IFolder,
  user: IUserInfo,
  external_url: string,
  modal: Omit<ModalStaticFunctions, "warn">,
  parentFolder: IFolder
) {
  const userTrashFolderId = `trash.${user.id}`;

  // Form to rename a folder
  const renameForm = (
    <Form
      preserve={false}
      name="rename-resource"
      initialValues={{ name: folder.name }}
      onFinish={(values) => {
        patchFolder(
          { ...folder, name: values.name },
          `Renamed folder '${folder.name}' to '${values.name}'`,
          `Failed to rename canvas ${folder.name}`
        );
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, type: "string", message: "Name is required" },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );

  const permissionsTable = (
    <PermissionsTable
      resourcePrefix={"canvas-folders/"}
      resource={folder}
      external_url={external_url}
    />
  );

  return (
    <Menu>
      <Menu.Item
        key="share"
        icon={<ShareAltOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.info({
            title: "Share with users and groups",
            closable: true,
            centered: true,
            width: "60%",
            className: "permission-modal",
            okText: "Done",
            icon: undefined,
            content: permissionsTable,
          });
        }}
      >
        Share
      </Menu.Item>

      <Menu.Item
        key="rename"
        disabled={folder.access === "view"}
        icon={<EditOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.confirm({
            title: "Rename folder",
            closable: true,
            okText: "Rename",
            className: "rename-resource-modal",
            content: renameForm,
            okButtonProps: { form: "rename-resource", htmlType: "submit" },
            icon: undefined,
          });
        }}
      >
        Rename
      </Menu.Item>

      <Menu.Item
        key="duplicate"
        disabled={
          !parentFolder ||
          (parentFolder.access !== "edit" && parentFolder.access !== "owner")
        }
        icon={<CopyOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          const hideProgress = message.loading("Duplicating folder...", 0);

          duplicateFolder(folder)
            .then(() => {
              message.success(`Duplicated folder '${folder.name}'.`);
            })
            .catch((error) => {
              console.error("Failed to duplicate folder:", error.response);
              const msgFromServer = error.response?.data?.msg;
              message.error(
                `Failed to duplicate folder '${folder.name}'. (${msgFromServer})`
              );
            })
            .finally(() => {
              hideProgress();
            });
        }}
      >
        Duplicate
      </Menu.Item>

      <Menu.Item
        key="details"
        icon={<InfoCircleOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.info({
            width: "416px",
            className: "details-modal",
            title: <Typography.Text strong>Details</Typography.Text>,
            icon: undefined,
            content: <DetailsFolder folder={folder} />,
            closable: true,
            maskClosable: true,
          });
        }}
      >
        Details
      </Menu.Item>

      <Menu.Item
        key="trash"
        disabled={folder.access !== "owner" && !user.admin}
        icon={
          <Typography.Text type="danger">
            <DeleteOutlined />
          </Typography.Text>
        }
        onClick={(info) => {
          info.domEvent.stopPropagation();

          deleteFolderWithConfirm(folder, userTrashFolderId, modal);
        }}
      >
        <Typography.Text type="danger">
          {folder.in_trash ? "Delete" : "Remove"}
        </Typography.Text>
      </Menu.Item>
    </Menu>
  );
}

// Delete a folder with modal confirmation. Folders in trash are deleted
// permanently, otherwise they are moved to trash.
export function deleteFolderWithConfirm(
  resource: IFolder,
  trashFolderId: string,
  modal: Omit<ModalStaticFunctions, "warn">
) {
  // Handle trashed resource differently
  if (resource.in_trash) {
    modal.confirm({
      okText: "Delete",
      content: `Permanently delete folder '${resource.name}'?`,
      okButtonProps: { danger: true },
      onOk: () => {
        deleteFolder(resource)
          .then(() => {
            message.success(`Folder '${resource.name}' was deleted.`);
          })
          .catch((error) => {
            console.error("Failed to delete folder:", error.response);
            const msgFromServer = error.response?.data?.msg;
            message.error(
              `Failed to delete folder '${resource.name}'. (${msgFromServer})`
            );
          });
        return false;
      },
    });
  } else {
    modal.confirm({
      okText: "Trash",
      content: `Move folder '${resource.name}' to trash?`,
      okButtonProps: { danger: true },
      onOk: () => {
        moveFolder(resource, trashFolderId, "move", "replace")
          .then(() => {
            message.success(`Folder '${resource.name}' was moved to trash.`);
          })
          .catch((error) => {
            console.error(
              `Failed to move folder '${resource.name}' to trash:`,
              error.response
            );
            const msgFromServer = error.response?.data?.msg;
            message.error(
              `Failed to move folder '${resource.name}' to trash. (${msgFromServer})`
            );
          });
        return false;
      },
    });
  }
}

// Returns a dropdown menu for the given canvas
export function menuForCanvas(
  canvas: ICanvas,
  user: IUserInfo,
  external_url: string,
  modal: Omit<ModalStaticFunctions, "warn">,
  parentFolder: IFolder
) {
  const openInDesktopAppURL = toCanvusUrl(new URL(external_url), canvas.id);
  const linkToCanvas = `${external_url}/open/${canvas.id}`;
  const userTrashFolderId = `trash.${user.id}`;

  const renameForm = (
    <Form
      preserve={false}
      name="rename-resource"
      initialValues={{ name: canvas.name }}
      onFinish={(values) => {
        patchCanvas(
          { ...canvas, name: values.name },
          `Renamed canvas '${canvas.name}' to '${values.name}'`,
          `Failed to rename canvas ${canvas.name}`
        );
      }}
    >
      <Form.Item
        label="Name"
        name="name"
        rules={[
          { required: true, type: "string", message: "Name is required" },
        ]}
      >
        <Input />
      </Form.Item>
    </Form>
  );

  const permissionsTable = (
    <PermissionsTable
      resourcePrefix={"canvases/"}
      resource={canvas}
      external_url={external_url}
    />
  );

  return (
    <Menu>
      <Menu.Item
        key="share"
        icon={<ShareAltOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.info({
            title: "Share with users and groups",
            closable: true,
            centered: true,
            width: "60%",
            className: "permission-modal",
            okText: "Done",
            icon: undefined,
            content: permissionsTable,
          });
        }}
      >
        Share
      </Menu.Item>
      <Menu.Item
        disabled={canvas.access === "view"}
        key="rename"
        icon={<EditOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.confirm({
            title: "Rename canvas",
            closable: true,
            okText: "Rename",
            className: "rename-resource-modal",
            content: renameForm,
            okButtonProps: { form: "rename-resource", htmlType: "submit" },
            icon: undefined,
          });
        }}
      >
        Rename
      </Menu.Item>

      <CopyToClipboard text={linkToCanvas}>
        <Menu.Item
          key="copy-link"
          icon={<LinkOutlined />}
          onClick={(info) => {
            info.domEvent.stopPropagation();
            message.info("Link copied.");
          }}
        >
          Copy link
        </Menu.Item>
      </CopyToClipboard>

      <Menu.Item key="open-in-desktop" icon={<DesktopOutlined />}>
        <a href={openInDesktopAppURL.href}>Open in desktop app</a>
      </Menu.Item>

      <Menu.Item
        key="duplicate"
        disabled={
          !parentFolder ||
          (parentFolder.access !== "edit" && parentFolder.access !== "owner")
        }
        icon={<CopyOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          const hideProgress = message.loading("Duplicating canvas...", 0);

          duplicateCanvas(canvas)
            .then(() => {
              message.success(`Duplicated canvas '${canvas.name}'.`);
            })
            .catch((error) => {
              console.error("Failed to duplicate canvas:", error.response);
              const msgFromServer = error.response?.data?.msg;
              message.error(
                `Failed to duplicate canvas '${canvas.name}'. (${msgFromServer})`
              );
            })
            .finally(() => {
              hideProgress();
            });
        }}
      >
        Duplicate
      </Menu.Item>

      <Menu.Item
        key="details"
        icon={<InfoCircleOutlined />}
        onClick={(info) => {
          info.domEvent.stopPropagation();

          modal.info({
            width: "416px",
            className: "details-modal",
            title: <Typography.Text strong>Details</Typography.Text>,
            icon: undefined,
            content: (
              <DetailsCanvas canvas={canvas} external_url={external_url} />
            ),
            maskClosable: true,
            closable: true,
          });
        }}
      >
        Details
      </Menu.Item>

      <Menu.Item
        disabled={canvas.access !== "owner" && !user.admin}
        key="trash"
        icon={
          <Typography.Text type="danger">
            <DeleteOutlined />
          </Typography.Text>
        }
        onClick={(info) => {
          info.domEvent.stopPropagation();

          deleteCanvasWithConfirm(canvas, userTrashFolderId, modal);
        }}
      >
        <Typography.Text type="danger">
          {canvas.in_trash ? "Delete" : "Remove"}
        </Typography.Text>
      </Menu.Item>
    </Menu>
  );
}

// Returns a dropdown menu for the given resource
export function menuForResource(
  resource: IResource,
  user: IUserInfo,
  external_url: string,
  modal: Omit<ModalStaticFunctions, "warn">,
  folders: FolderByIdMap
) {
  const isFolder = folders.has(resource.id);
  const parentFolder = folders.get(resource.folder_id) as IFolder;

  if (isFolder) {
    if (isTrashFolder(resource)) return menuForTrashFolder(resource, modal);

    return menuForFolder(resource, user, external_url, modal, parentFolder);
  }

  return menuForCanvas(
    resource as ICanvas,
    user,
    external_url,
    modal,
    parentFolder
  );
}
