import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Space,
} from "antd";
import AdminLayout from "./AdminLayout";
import Axios, { AxiosError } from "axios";
import { IServerConfig } from "../../interfaces";
import { formLayout, formTailLayout } from "../FormLayout";
import { QueryFailure } from "../../components/QueryFailure";
import PageLoading from "../../components/PageLoading";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import { useAuthDataContext } from "../../components/AuthProvider";

interface IWrapperProps {
  onAlertClose: () => void;
  errorMessage?: string;
}

const PageWrapper: React.FunctionComponent<IWrapperProps> = (props) => {
  return (
    <AdminLayout selectedSideMenuKey="settings.email">
      {props.errorMessage !== undefined && (
        <Alert
          message="Failed to save settings"
          description={props.errorMessage}
          type="error"
          showIcon
          closable
          onClose={props.onAlertClose}
        />
      )}
      <Card title="Email settings">{props.children}</Card>
    </AdminLayout>
  );
};

export const AdminSettingsEmailPage: React.FunctionComponent = () => {
  const { isLoading, data, error } =
    useQuery<IServerConfig, AxiosError>("/server-config");

  const mutation = useMutation<IServerConfig, AxiosError, any>(
    async (config) => {
      const { data } = await Axios.patch(
        "/api/dashboard/server-config",
        config
      );
      return data;
    }
  );

  const [errorMessage, setErrorMessage] = useState<string>();

  const queryClient = useQueryClient();

  function clearError() {
    setErrorMessage(undefined);
  }

  function onSubmit(values: any) {
    mutation.mutate(values, {
      onSuccess: (data) => {
        message.success("Settings saved.");
        clearError();
        queryClient.setQueryData("/server-config", data);
      },
      onError: (error) => {
        const msgFromServer = error.response?.data?.msg;
        setErrorMessage(msgFromServer);
      },
    });
  }

  if (error) {
    return (
      <PageWrapper onAlertClose={clearError}>
        <QueryFailure
          title="Failed to query server configuration"
          error={error}
        />
      </PageWrapper>
    );
  }

  if (isLoading) {
    return (
      <PageWrapper onAlertClose={clearError}>
        <PageLoading />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper errorMessage={errorMessage} onAlertClose={clearError}>
      <EmailSettings serverConfig={data!} onSubmit={onSubmit} />
    </PageWrapper>
  );
};

interface IProps {
  serverConfig: IServerConfig;
  onSubmit: (values: any) => void;
}

enum TestEmailState {
  Ready,
  OperationInProgress,
}

const EmailSettings: React.FunctionComponent<IProps> = (props) => {
  const [testEmailState, setTestEmailState] = useState<TestEmailState>(
    TestEmailState.Ready
  );

  const config = props.serverConfig.email;

  const { user } = useAuthDataContext();

  // We use a separate state variable to tracker access because of the custom
  // layout we want in the form with separate explanations for each Radio items.
  // Ideally we'd just use a Radio.Group and no separate state, but I couldn't
  // get the layout I wanted with that.
  const [security, setSecurity] = useState<"none" | "tls" | "starttls">(
    config.smtp_security
  );

  function submitForm(values: any): void {
    let payload = { email: { ...values } };
    payload.email.smtp_security = security;

    props.onSubmit(payload);
  }

  function sendTestEmail() {
    setTestEmailState(TestEmailState.OperationInProgress);
    const hideProgressMsg = message.loading("Sending email...", 0);

    Axios.post("/api/dashboard/server-config/send-test-email")
      .then(() => {
        message.success(`An email was sent to ${user.email}`);
      })
      .catch((error) => {
        const msg = `Failed to send email: ${error.response?.data?.msg}`;
        message.error(msg);
      })
      .finally(() => {
        hideProgressMsg();
        setTestEmailState(TestEmailState.Ready);
      });
  }

  return (
    <>
      Configure email settings for the server.
      <Form
        {...formLayout}
        name="email"
        initialValues={{
          smtp_host: config.smtp_host,
          smtp_port: config.smtp_port,
          smtp_username: config.smtp_username,
          smtp_password: config.smtp_password,
          smtp_allow_self_signed_certificates:
            config.smtp_allow_self_signed_certificates,
          mail_reply_to_name: config.mail_reply_to_name,
          mail_reply_to_address: config.mail_reply_to_address,
          mail_sender_address: config.mail_sender_address,
          mail_sender_name: config.mail_sender_name,
        }}
        onFinish={submitForm}
      >
        <Divider orientation="left">SMTP</Divider>

        <Form.Item label="SMTP host" name="smtp_host">
          <Input />
        </Form.Item>

        <Form.Item label="SMTP port" name="smtp_port">
          <InputNumber min={1} max={65535} />
        </Form.Item>

        <Form.Item label="Username" name="smtp_username">
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="smtp_password"
          rules={[{ type: "string" }]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>

        <Form.Item
          label="Connection security"
          help={
            <p>
              Connection will not use TLS even if the SMTP host supports
              STARTTLS extension.
            </p>
          }
        >
          <Radio
            checked={security === "none"}
            onChange={() => setSecurity("none")}
          >
            None
          </Radio>
        </Form.Item>

        <Form.Item
          label={<span />}
          colon={false}
          help={
            <p>
              The connection will be encrypted using SSL or TLS immediately
              after connecting to the SMTP host.
            </p>
          }
        >
          <Radio
            checked={security === "tls"}
            onChange={() => setSecurity("tls")}
          >
            TLS
          </Radio>
        </Form.Item>

        <Form.Item
          label={<span />}
          colon={false}
          help={
            <p>
              The connection will attempt to use SSL/TLS if the server supports
              the STARTTLS extension. If the SMTP server does not support it,
              messages will not be sent.
            </p>
          }
        >
          <Radio
            checked={security === "starttls"}
            onChange={() => setSecurity("starttls")}
          >
            STARTTLS
          </Radio>
        </Form.Item>

        <Form.Item
          label="Allow self-signed certificates"
          name="smtp_allow_self_signed_certificates"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Divider orientation="left">Messages</Divider>

        <Form.Item
          label="Sender name"
          name="mail_sender_name"
          rules={[{ type: "string" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Sender address"
          name="mail_sender_address"
          rules={[{ type: "string" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Reply-to name"
          name="mail_reply_to_name"
          rules={[{ type: "string" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Reply-to address"
          name="mail_reply_to_address"
          rules={[{ type: "string" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item {...formTailLayout}>
          <Space>
            <Button type="primary" htmlType="submit">
              Save settings
            </Button>
            <Button
              loading={testEmailState === TestEmailState.OperationInProgress}
              onClick={sendTestEmail}
            >
              Send test email
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
