import * as PIXI from "pixi.js";
import { createWidgetSynchronizer } from "./widget-synchronizer";
import { Navigation } from "./navigation";
import { loadFont } from "./font-loader";
import { AnnotationContainer } from "./widgets/annotation-container";
import { gcRun, Renderer } from "./gc";
import { IActiveWidget } from "../pages/canvas/CanvusWebClient";
import { rectEquals, Rectf } from "./math/rect";
import { intersects } from "./math/pixi-additions";
import { AnchorManager } from "./anchor-manager";
import { SnapshotManager } from "./snapshot-manager";
import { fromWorldToApp, widgetWorldBBox } from "./widgets/helpers";
import { CanvasWidget } from "./widgets/canvas";
import { onSingleTap } from "./interaction";
import { Easing } from "./easing";
import { PresentationMode } from "./presentation-mode";

export type Widget = {
  id: string;
  host: PIXI.Container;
  bbox: Rectf;
  type:
    | "Note"
    | "Canvas"
    | "Image"
    | "Video"
    | "Other"
    | "Anchor"
    | "Pdf"
    | "Connector";
  title?: string;
  annotations?: AnnotationContainer;
  active?: boolean;
  downloadUrl?: string;
};

export class WebClient {
  canvasId: string;
  widgets: Map<string, Widget> = new Map<string, Widget>();
  annotations: Map<string, Widget> = new Map<string, Widget>();
  pixi: PIXI.Application;
  defaultFont: Promise<string>;
  ui: WebClientUiCallbacks;
  canvas?: CanvasWidget;
  view: PIXI.Container;

  anchorLayer: PIXI.Container;

  activeWidget?: Widget;
  activeWidgetParams?: IActiveWidget;

  anchorManager: AnchorManager;
  snapshotManager: SnapshotManager;

  nav: Navigation;
  presentationMode?: PresentationMode;

  easing: Easing;

  showAnchors = false;

  isRendering = false;

  // Set to true when the main canvas viewport (view) needs to be painted
  private paintView = false;
  // Non-zero if we have pending animation frame request. All painting happens then.
  private animFrameRequestId = 0;
  // Non-zero if we have pending timeout request for calling garbage collection (see gc.ts)
  private gcTimeoutId = 0;

  private lastNotifiedScale = [0, 0, 0];

  private viewLocalTransformInvCache: PIXI.Matrix = new PIXI.Matrix();
  private viewLocalTransformId: number = -1;

  constructor(
    root: HTMLDivElement,
    canvasId: string,
    ui: WebClientUiCallbacks
  ) {
    this.anchorManager = new AnchorManager(this);
    this.snapshotManager = new SnapshotManager(this);
    this.nav = new Navigation(this);
    this.easing = new Easing(this);

    const defaultFont = "Roboto-Regular";
    this.canvasId = canvasId;
    this.pixi = new PIXI.Application({
      resizeTo: root,
      autoStart: false,
      antialias: true, // TODO: Should not use with slow GPUs or hi-dpi screens
    });

    this.defaultFont = loadFont(
      defaultFont,
      "/Roboto-Regular.fnt",
      "/Roboto-Regular.png"
    );
    this.ui = ui;
    PIXI.Ticker.system.stop();

    this.view = new PIXI.Container();
    this.view.name = "View";
    this.pixi.stage.addChild(this.view);

    this.view.sortableChildren = true;
    this.view.interactive = true;
    onSingleTap(this.view, (e: PIXI.InteractionEvent) => {
      this.activateWidget(undefined);
      e.stopPropagation();
    });

    this.pixi.renderer.on("prerender", () => {
      this.isRendering = true;
    });
    this.pixi.renderer.on("postrender", () => {
      this.isRendering = false;
    });

    this.anchorLayer = new PIXI.Container();
    this.anchorLayer.name = "AnchorLayer";
    this.anchorLayer.sortableChildren = true;
    this.anchorLayer.interactive = true;
    this.anchorLayer.zIndex = 2;
    this.view.addChild(this.anchorLayer);
  }

  isPresenting() {
    return this.presentationMode ? this.presentationMode.isPresenting() : false;
  }

  viewLocalTransformInv(): PIXI.Matrix {
    const t = this.view.transform;
    if (t._worldID !== this.viewLocalTransformId) {
      this.viewLocalTransformId = t._worldID;
      this.viewLocalTransformInvCache.copyFrom(t.localTransform);
      this.viewLocalTransformInvCache.invert();
    }
    return this.viewLocalTransformInvCache;
  }

  requestPaint() {
    if (this.animFrameRequestId === 0) {
      this.animFrameRequestId = requestAnimationFrame((time) =>
        this.paint(time)
      );
    }
  }

  // Called when the viewport location changes, typically by user interaction.
  // This invalidates the main view but not anchors views.
  setNavigationDirty() {
    if (this.isRendering) return;
    this.paintView = true;
    this.requestPaint();
    this.checkChangedScale();
  }

  // Schedule paint for all anchor views and the main view. Called when something
  // changes in the scene graph or a resource that affects painting finished loading.
  setDirty() {
    if (this.isRendering) return;
    this.anchorManager.setDirty();
    this.setNavigationDirty();
  }

  private checkChangedScale() {
    const scale = [this.view.scale.x, this.nav.minScale, this.nav.maxScale];
    if (
      this.lastNotifiedScale[0] !== scale[0] ||
      this.lastNotifiedScale[1] !== scale[1] ||
      this.lastNotifiedScale[2] !== scale[2]
    ) {
      this.lastNotifiedScale = scale;
      this.ui.onScaleChanged(scale[0], scale[1], scale[2]);
    }
  }

  run(cb: () => void) {
    if (this.isRendering) {
      cb();
    } else {
      this.pixi.ticker.addOnce(cb);
    }
  }

  paint(time: DOMHighResTimeStamp) {
    this.animFrameRequestId = 0;

    if (!this.easing.update(time)) {
      this.requestPaint();
    }

    const viewPainted = this.paintView;
    if (viewPainted) {
      this.paintView = false;
      this.paintNow(time);
    }

    this.snapshotManager.paint(time, viewPainted);

    if (this.gcTimeoutId !== 0) {
      window.clearTimeout(this.gcTimeoutId);
    }
    // Run GC after 2 seconds of idle
    this.gcTimeoutId = window.setTimeout(() => {
      this.gcTimeoutId = 0;
      gcRun(this.pixi.renderer as Renderer);
    }, 2000);
  }

  paintNow(time: DOMHighResTimeStamp) {
    const renderer = this.pixi.renderer as Renderer;
    renderer.viewport = this.pixi.screen;
    renderer.frameTime = time;
    renderer.viewRenderTime = time;
    renderer.projectionScale = 1.0;

    this.pixi.ticker.update(time);
    this.checkActiveWidgetBounds();
  }

  start() {
    this.paint(performance.now());
  }

  stop() {
    if (this.animFrameRequestId !== 0) {
      window.cancelAnimationFrame(this.animFrameRequestId);
      this.animFrameRequestId = 0;
    }
    if (this.gcTimeoutId !== 0) {
      window.clearTimeout(this.gcTimeoutId);
      this.gcTimeoutId = 0;
    }
  }

  activateWidget(w?: Widget) {
    if (this.activeWidget === w) return;

    const cb = () => {
      this.activeWidget = undefined;
      this.ui.onWidgetActivate(undefined);
    };

    if (this.activeWidget !== undefined) {
      this.activeWidget.host.off("destroyed", cb);
      this.activeWidget.active = false;
    }

    this.activeWidget = w;

    if (w === undefined) {
      this.ui.onWidgetActivate(undefined);
      return;
    }

    w.active = true;
    w.host.once("destroyed", cb);

    const worldBounds = widgetWorldBBox(w);
    this.activeWidgetParams = {
      rect: {
        left: worldBounds.left,
        top: worldBounds.top,
        right: worldBounds.right,
        bottom: worldBounds.bottom,
      },
      id: w.id,
      type: w.type,
      title: w.title,
      page: (w as any).page,
      pageCount: (w as any).pageCount,
      downloadUrl: w.downloadUrl,
    };
    this.ui.onWidgetActivate(this.activeWidgetParams);
  }

  checkActiveWidgetBounds() {
    if (this.activeWidget === undefined) return;

    const worldBounds = widgetWorldBBox(this.activeWidget);
    const viewport = (this.pixi.renderer as Renderer).viewport;
    const visible = intersects(worldBounds, viewport);
    if (!visible) {
      this.activateWidget(undefined);
      return;
    }

    const params = {
      rect: {
        left: worldBounds.left,
        top: worldBounds.top,
        right: worldBounds.right,
        bottom: worldBounds.bottom,
      },
      id: this.activeWidget.id,
      type: this.activeWidget.type,
      title: this.activeWidget.title,
      page: (this.activeWidget as any).page,
      pageCount: (this.activeWidget as any).pageCount,
      downloadUrl: this.activeWidget.downloadUrl,
    };
    if (
      this.activeWidgetParams === undefined ||
      this.activeWidgetParams.id !== params.id ||
      this.activeWidgetParams.title !== params.title ||
      this.activeWidgetParams.page !== params.page ||
      this.activeWidgetParams.pageCount !== params.pageCount ||
      this.activeWidgetParams.downloadUrl !== params.downloadUrl ||
      !rectEquals(params.rect, this.activeWidgetParams.rect)
    ) {
      this.activeWidgetParams = params;
      this.ui.onWidgetActivate(params);
    }
  }

  setShowAnchors(show: boolean) {
    if (this.showAnchors === show) return;
    this.anchorManager.setShowAnchors(show);
    this.showAnchors = show;
    this.setDirty();
  }

  setFullscreen(widgetId?: string) {
    if (widgetId) {
      const w = this.widgets.get(widgetId);
      if (w) {
        if (!this.presentationMode) {
          this.presentationMode = new PresentationMode(this);
        }
        this.presentationMode.startPresenting(w);
        return;
      }
    }

    if (this.presentationMode) {
      this.presentationMode.stopPresenting();
      this.presentationMode = undefined;
    }
  }

  destroy() {
    this.pixi.destroy(true, true);
    this.snapshotManager = undefined!;
    this.anchorManager = undefined!;
    this.nav = undefined!;
    this.easing = undefined!;
  }
}

function enablePixiInspector() {
  // https://github.com/bfanger/pixi-inspector/issues/42
  (window as any).__PIXI_INSPECTOR_GLOBAL_HOOK__ &&
    (window as any).__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI: PIXI });
}

function addMobileListeners(app: WebClient) {
  async function onOrientationChange(e: any) {
    try {
      if (
        document.fullscreenElement === null &&
        e.target.screen.orientation.type.includes("landscape")
      ) {
        await app.pixi.view.requestFullscreen();
      } else if (document.fullscreenElement !== null) {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.log(error);
    }
  }
  window.addEventListener("orientationchange", onOrientationChange);
  return function cleanup() {
    window.removeEventListener("orientationchange", onOrientationChange);
  };
}

function addResizeListener(app: WebClient) {
  function onResize() {
    app.nav.resized();
    if (app.presentationMode) {
      app.presentationMode.resized();
    }
    app.setNavigationDirty();
  }
  app.pixi.renderer.on("resize", onResize);

  return function cleanup() {
    app.pixi.renderer.off("resize", onResize);
  };
}

// Interface implemented by React code to receive data from the web client
interface WebClientUiCallbacks {
  onWidgetActivate: (data: IActiveWidget | undefined) => void;
  onAnchorUpdated: (id: string, name: string, index: number) => void;
  onAnchorRemoved: (id: string) => void;
  onScaleChanged: (scale: number, minScale: number, maxScale: number) => void;
}

// Class used by React code to send commands and register <canvas> elements as
// external render targets for AnchorList
export class WebClientApi {
  constructor(private app: WebClient) {}

  registerAnchorCanvas(
    anchorId: string,
    element: HTMLCanvasElement | null
  ): void {
    this.app.anchorManager.registerAnchorCanvas(anchorId, element);
  }
  zoomToFit() {
    this.app.nav.zoomToFit();
  }
  zoom(diff: number, x: number, y: number) {
    this.app.nav.setScaleRelative(diff, x, y);
    this.app.setNavigationDirty();
  }
  zoomToAnchor(anchorId: string) {
    const anchor = this.app.anchorManager.findAnchor(anchorId);
    if (anchor) {
      const bounds = fromWorldToApp(this.app, anchor.w.host.getBounds(false));
      this.app.nav.zoomTo(bounds);
    }
  }
  setShowAnchors(show: boolean) {
    this.app.setShowAnchors(show);
  }
  setFullscreen(widgetId?: string) {
    this.app.setFullscreen(widgetId);
  }
}

export type WebClientRet = {
  api: WebClientApi;
  cleanup: () => void;
};

export function openWebClient(
  root: HTMLDivElement,
  canvasId: string,
  ui: WebClientUiCallbacks
): WebClientRet {
  PIXI.settings.RESOLUTION = window.devicePixelRatio;
  PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON;
  // Without this we would only get WebGL 1 context on Android. WebGL 2 is
  // needed for snapshots (anchor list) and NPOT mipmaps.
  PIXI.settings.PREFER_ENV = PIXI.ENV.WEBGL2;

  const defaultFont = "Roboto-Regular";
  const app = new WebClient(root, canvasId, ui);

  const renderer = app.pixi.renderer as Renderer;
  renderer.frameTime = performance.now();
  renderer.viewRenderTime = renderer.frameTime;
  renderer.viewport = PIXI.Rectangle.EMPTY;
  renderer.projectionScale = 1.0;

  // The <canvas> element CSS size needs to be different from its pixel
  // resolution in hi-dpi setups, fix the canvas size here.
  app.pixi.view.style.width = "100%";
  app.pixi.view.style.height = "100%";

  root.appendChild(app.pixi.view);

  const destroyWidgetSynchronizer = createWidgetSynchronizer(app, canvasId);
  const removeNavigationListeners = app.nav.addListeners(root);
  const removeMobileListeners = addMobileListeners(app);
  const removeResizeListener = addResizeListener(app);

  enablePixiInspector();

  app.start();

  function cleanup() {
    app.stop();

    PIXI.BitmapFont.uninstall(defaultFont);
    removeResizeListener();
    removeMobileListeners();
    removeNavigationListeners();
    destroyWidgetSynchronizer();
    app.destroy();
  }
  return { api: new WebClientApi(app), cleanup };
}
