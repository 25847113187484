import { Color } from "../math/color";
import {
  cross,
  cross2,
  dot2,
  dot3,
  length,
  lengthSqr3,
  rotate,
  rotate2,
  Vec2,
  Vec3,
} from "../math/vec";

export enum SplineCapStyle {
  FLAT,
  ROUND,
}

export type SplineStyle = {
  capBegin: SplineCapStyle;
  capEnd: SplineCapStyle;
};

export type PolylinePoint = {
  point: Vec3;
  tangent2D: Vec2;
};

export type BezierNode = {
  ctrlIn: Vec3;
  point: Vec3;
  ctrlOut: Vec3;
};

export type BezierVertex = {
  location: Vec2;
  color: Color;
};

export type CubicBezierCurve = { p0: Vec3; p1: Vec3; p2: Vec3; p3: Vec3 };

export function createBezierCurve(
  b: BezierNode,
  e: BezierNode
): CubicBezierCurve {
  return { p0: b.point, p1: b.ctrlOut, p2: e.ctrlIn, p3: e.point };
}

export function bezierTangent2D(curve: CubicBezierCurve, t: number): Vec2 {
  const tm = 1.0 - t;
  const a = 3.0 * tm * tm;
  const b = 6.0 * tm * t;
  const c = 3.0 * t * t;
  return {
    x:
      a * (curve.p1.x - curve.p0.x) +
      b * (curve.p2.x - curve.p1.x) +
      c * (curve.p3.x - curve.p2.x),
    y:
      a * (curve.p1.y - curve.p0.y) +
      b * (curve.p2.y - curve.p1.y) +
      c * (curve.p3.y - curve.p2.y),
  };
}

export function bezierReversed(curve: CubicBezierCurve) {
  return { p0: curve.p3, p1: curve.p2, p2: curve.p1, p3: curve.p0 };
}

function bezierSubdivide(
  curve: CubicBezierCurve,
  t: number
): [CubicBezierCurve, CubicBezierCurve] {
  const t1 = 1.0 - t;
  const p11: Vec3 = {
    x: t1 * curve.p0.x + t * curve.p1.x,
    y: t1 * curve.p0.y + t * curve.p1.y,
    z: t1 * curve.p0.z + t * curve.p1.z,
  };
  const p21: Vec3 = {
    x: t1 * curve.p1.x + t * curve.p2.x,
    y: t1 * curve.p1.y + t * curve.p2.y,
    z: t1 * curve.p1.z + t * curve.p2.z,
  };
  const p31: Vec3 = {
    x: t1 * curve.p2.x + t * curve.p3.x,
    y: t1 * curve.p2.y + t * curve.p3.y,
    z: t1 * curve.p2.z + t * curve.p3.z,
  };
  const p12: Vec3 = {
    x: t1 * p11.x + t * p21.x,
    y: t1 * p11.y + t * p21.y,
    z: t1 * p11.z + t * p21.z,
  };
  const p22: Vec3 = {
    x: t1 * p21.x + t * p31.x,
    y: t1 * p21.y + t * p31.y,
    z: t1 * p21.z + t * p31.z,
  };
  const p13: Vec3 = {
    x: t1 * p12.x + t * p22.x,
    y: t1 * p12.y + t * p22.y,
    z: t1 * p12.z + t * p22.z,
  };

  return [
    { p0: curve.p0, p1: p11, p2: p12, p3: p13 },
    { p0: p13, p1: p22, p2: p31, p3: curve.p3 },
  ];
}

function isFlat(curve: CubicBezierCurve, toleranceSqr: number): boolean {
  const tmp: Vec3 = {
    x: curve.p3.x - curve.p0.x,
    y: curve.p3.y - curve.p0.y,
    z: curve.p3.z - curve.p0.z,
  };
  const invSqr = 1.0 / dot3(tmp, tmp);

  const a: Vec3 = {
    x: curve.p1.x - curve.p0.x,
    y: curve.p1.y - curve.p0.y,
    z: curve.p1.z - curve.p0.z,
  };
  const b: Vec3 = {
    x: curve.p1.x - curve.p3.x,
    y: curve.p1.y - curve.p3.y,
    z: curve.p1.z - curve.p3.z,
  };
  const c: Vec3 = {
    x: curve.p2.x - curve.p0.x,
    y: curve.p2.y - curve.p0.y,
    z: curve.p2.z - curve.p0.z,
  };
  const d: Vec3 = {
    x: curve.p2.x - curve.p3.x,
    y: curve.p2.y - curve.p3.y,
    z: curve.p2.z - curve.p3.z,
  };
  const aSqr = lengthSqr3(cross(a, b)) * invSqr;
  const bSqr = lengthSqr3(cross(c, d)) * invSqr;

  return Math.max(aSqr, bSqr) <= toleranceSqr;
}

function bezierEvaluate2D(
  curve: CubicBezierCurve,
  points: Array<PolylinePoint>,
  toleranceSqr: number,
  angleToleranceCos: number,
  prevUnitTangent: Vec2
) {
  if (isFlat(curve, toleranceSqr)) {
    let t = bezierTangent2D(curve, 1.0);
    const lenSqr = dot2(t, t);
    if (lenSqr < toleranceSqr) {
      points.push({ point: curve.p3, tangent2D: t });
      return;
    }

    const len = Math.sqrt(lenSqr);
    t.x /= len;
    t.y /= len;
    if (dot2(t, prevUnitTangent) > angleToleranceCos) {
      points.push({ point: curve.p3, tangent2D: t });
      return;
    }
  }

  const lenSqr = lengthSqr3({
    x: curve.p3.x - curve.p0.x,
    y: curve.p3.y - curve.p0.y,
    z: curve.p3.z - curve.p0.z,
  });
  if (lenSqr < toleranceSqr) {
    let t = bezierTangent2D(curve, 1.0);
    const len = length(t);
    t.x /= len;
    t.y /= len;
    points.push({ point: curve.p3, tangent2D: t });
    return;
  }

  let t = bezierTangent2D(curve, 0.5);
  const len = length(t);
  t.x /= len;
  t.y /= len;

  const [left, right] = bezierSubdivide(curve, 0.5);
  bezierEvaluate2D(
    left,
    points,
    toleranceSqr,
    angleToleranceCos,
    prevUnitTangent
  );
  bezierEvaluate2D(right, points, toleranceSqr, angleToleranceCos, t);
}

function capSegmentAngle(
  strokeRadius: number,
  maxRoundCapError: number
): number {
  return 2.0 * Math.acos(1.0 - maxRoundCapError / strokeRadius);
}

function roundCapSegments(
  strokeRadius: number,
  maxRoundCapError: number,
  angle: number = Math.PI
): number {
  const csa = capSegmentAngle(strokeRadius, maxRoundCapError);
  if (!isFinite(csa)) return 0;
  return Math.floor(1.0 + angle / csa);
}

function capSegmentAngleCos(
  strokeRadius: number,
  maxRoundCapError: number
): number {
  const a = 1.0 - maxRoundCapError / strokeRadius;
  return 2.0 * a * a - 1.0;
}

function renderRoundCapBegin(
  out: Array<BezierVertex>,
  p: PolylinePoint,
  normal: Vec2,
  color: Color,
  maxRoundCapError: number
) {
  const segments = roundCapSegments(p.point.z, maxRoundCapError);
  if (segments <= 1) return;

  let angle = Math.PI / segments;
  if (segments % 2 === 0) angle = -angle;
  const s = Math.sin(angle);
  const c = Math.cos(angle);

  let dir0: Vec2 = { x: normal.x, y: normal.y };
  rotate(dir0, ((segments + 1.0) / 2.0 / segments) * Math.PI);

  let dir1: Vec2 = { x: dir0.x, y: dir0.y };

  for (let segment = 1; ; ) {
    out.push({
      location: { x: p.point.x + dir0.x, y: p.point.y + dir0.y },
      color,
    });

    if (++segment >= segments) break;

    rotate2(dir1, -s, c);

    out.push({
      location: { x: p.point.x + dir1.x, y: p.point.y + dir1.y },
      color,
    });

    if (++segment >= segments) break;

    rotate2(dir0, s, c);
  }
}

/// @todo we should take the .z tangent into account here and scale
/// the round cap begin/end accordingly
function renderRoundCapEnd(
  out: Array<BezierVertex>,
  p: PolylinePoint,
  normal: Vec2,
  color: Color,
  maxRoundCapError: number
) {
  const segments = roundCapSegments(p.point.z, maxRoundCapError);

  if (segments <= 1) return;

  const angle = Math.PI / segments;
  const s = Math.sin(angle);
  const c = Math.cos(angle);

  let dir0: Vec2 = { x: -normal.x, y: -normal.y };
  let dir1: Vec2 = { x: normal.x, y: normal.y };

  for (let segment = 1; ; ) {
    rotate2(dir0, s, c);

    out.push({
      location: { x: p.point.x + dir0.x, y: p.point.y + dir0.y },
      color,
    });

    if (++segment === segments) break;

    rotate2(dir1, -s, c);

    out.push({
      location: { x: p.point.x + dir1.x, y: p.point.y + dir1.y },
      color,
    });

    if (++segment === segments) break;
  }
}

export function tessellateBezierSpline(
  nodes: Array<BezierNode>,
  color: Color,
  style: SplineStyle,
  out: Array<BezierVertex>,
  maxCurveError: number,
  maxRoundCapError: number
) {
  // TODO: draw a circle if nodes.size == 1?
  if (nodes.length <= 1) return;

  let normal: Vec2 = { x: 0, y: 0 };

  let prevPoint: Vec3 = { x: 0, y: 0, z: 0 };
  let prevUnitTangent: Vec2 = { x: 0, y: 0 };
  let first = true;

  // 32-bit float is not accurate enough for smaller values
  maxCurveError = Math.max(maxCurveError, 0.0001);

  let polylineBuffer: Array<PolylinePoint>;

  for (let i = 0, s = nodes.length - 1; i < s; ++i) {
    polylineBuffer = [];

    const curve = createBezierCurve(nodes[i], nodes[i + 1]);
    if (first) {
      polylineBuffer.push({
        point: nodes[i].point,
        tangent2D: bezierTangent2D(curve, 0.0),
      });
    }

    let cc = capSegmentAngleCos(nodes[i].point.z, maxRoundCapError);
    if (cc < -1.0 || cc > 1.0) cc = -1.0;

    bezierEvaluate2D(
      curve,
      polylineBuffer,
      maxCurveError * maxCurveError,
      cc,
      bezierTangent2D(curve, 0.0)
    );

    for (let p of polylineBuffer) {
      let len = length(p.tangent2D);
      let unitTangent: Vec2 = { x: 0, y: 0 };

      if (len <= 1e-10) {
        unitTangent.x = curve.p3.x - curve.p0.x;
        unitTangent.y = curve.p3.y - curve.p0.y;
        len = length(unitTangent);
        if (!first && len <= 1e-10) {
          unitTangent = prevUnitTangent;
        } else {
          unitTangent.x /= len;
          unitTangent.y /= len;
        }
      } else {
        unitTangent.x = p.tangent2D.x / len;
        unitTangent.y = p.tangent2D.y / len;
      }

      normal.x = -unitTangent.y * p.point.z;
      normal.y = unitTangent.x * p.point.z;

      if (first) {
        if (style.capBegin === SplineCapStyle.ROUND)
          renderRoundCapBegin(out, p, normal, color, maxRoundCapError);
      } else {
        const s = capSegmentAngleCos(p.point.z, maxRoundCapError);
        const angleCos = dot2(unitTangent, prevUnitTangent);
        if (angleCos < s && s >= -1.0 && s < 1.0) {
          let angle = Math.acos(angleCos);
          if (isFinite(angle)) {
            const steps = Math.floor(angle / Math.acos(s));

            const left = cross2(prevUnitTangent, unitTangent) > 0.0;
            const normal2: Vec2 = {
              x: -prevUnitTangent.y * prevPoint.z,
              y: prevUnitTangent.x * prevPoint.z,
            };
            angle = (angle / (steps + 1)) * (left ? 1 : -1);

            const si = Math.sin(angle),
              co = Math.cos(angle);
            for (let j = 0; j < steps; ++j) {
              rotate2(normal2, si, co);

              out.push({
                location: {
                  x: prevPoint.x - normal2.x,
                  y: prevPoint.y - normal2.y,
                },
                color,
              });
              out.push({
                location: {
                  x: prevPoint.x + normal2.x,
                  y: prevPoint.y + normal2.y,
                },
                color,
              });
            }
          }
        }
      }

      first = false;
      prevPoint = p.point;
      prevUnitTangent = unitTangent;

      out.push({
        location: { x: p.point.x - normal.x, y: p.point.y - normal.y },
        color,
      });
      out.push({
        location: { x: p.point.x + normal.x, y: p.point.y + normal.y },
        color,
      });
    }
  }

  if (style.capEnd === SplineCapStyle.ROUND)
    renderRoundCapEnd(
      out,
      polylineBuffer![polylineBuffer!.length - 1],
      normal,
      color,
      maxRoundCapError
    );
}
