import React, { useState } from "react";
import {
  Card,
  Radio,
  Input,
  Table,
  RadioChangeEvent,
  Button,
  Form,
  Space,
  Divider,
  Select,
  Typography,
  InputNumber,
  message,
} from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import AdminLayout from "./AdminLayout";
import "./AdminAuditLogPage.less";

import DatePicker from "../../components/DatePicker";
import dayjs from "dayjs";
import { AuditEventTargetType, IAuditEvent } from "../../interfaces";
import Axios, { AxiosError } from "axios";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { QueryFailure } from "../../components/QueryFailure";
import UsersProvider, { useUsersContext } from "../../components/UsersProvider";
import GroupsProvider, {
  GroupByIdMap,
  useGroupsContext,
} from "../../components/GroupsProvider";
import FoldersProvider, {
  FolderByIdMap,
  useFoldersContext,
} from "../../components/FoldersProvider";
import CanvasesProvider, {
  CanvasByIdMap,
  useCanvasesContext,
} from "../../components/CanvasesProvider";
import { UserByIdMap } from "../../components/UsersReducer";
import { Link } from "react-router-dom";
import { guestUserId, systemUserId } from "../../constants";
import parseLinkHeader from "parse-link-header";
import { downloadTextFile } from "../../util";

// Accepted values for filter date range radio button
enum DateRange {
  Last7,
  Last14,
  Last30,
}

// Initial values for the filter form
const initialValues = {
  dateRadioButton: DateRange.Last7,
  dates: [dayjs().endOf("day").subtract(7, "day"), dayjs().endOf("day")],
  target_type: "user",
};

export const AdminAuditLogPage: React.FunctionComponent = () => {
  return (
    <AdminLayout selectedSideMenuKey="audit">
      <UsersProvider>
        <GroupsProvider>
          <FoldersProvider>
            <CanvasesProvider>
              <AuditLogContent />
            </CanvasesProvider>
          </FoldersProvider>
        </GroupsProvider>
      </UsersProvider>
    </AdminLayout>
  );
};

function formatUser(id: number | null, users: UserByIdMap) {
  const copyable = {
    text: `${id}`,
    tooltips: ["Copy user ID", "Copied"],
  };

  // Handle NULL acting user
  if (id === null) {
    return <Typography.Text>N/A</Typography.Text>;
  }

  // Handle built-in accounts as special case
  if (id === systemUserId) {
    return <Typography.Text copyable={copyable}>System</Typography.Text>;
  }

  if (id === guestUserId) {
    return <Typography.Text copyable={copyable}>Guest</Typography.Text>;
  }

  // If the user exists, display their current name
  const user = users.get(id);
  if (user !== undefined) {
    return (
      <span>
        <Link to={`/admin/users/${user.id}/edit`}>{user.name}</Link>
        <Typography.Text copyable={copyable} />
      </span>
    );
  }

  return (
    <Typography.Text copyable={copyable}>Unknown user #{id}</Typography.Text>
  );
}

function formatGroup(id: number, groups: GroupByIdMap) {
  const copyable = {
    text: `${id}`,
    tooltips: ["Copy group ID", "Copied"],
  };

  // If the group exists, display their current name
  const group = groups.get(id);
  if (group !== undefined) {
    return (
      <span>
        <Link to={`/admin/groups/${group.id}/edit`}>{group.name}</Link>
        <Typography.Text copyable={copyable} />
      </span>
    );
  }
  return (
    <Typography.Text copyable={copyable}>Deleted group #{id}</Typography.Text>
  );
}

function formatCanvas(id: string, canvases: CanvasByIdMap) {
  const copyable = {
    text: `${id}`,
    tooltips: ["Copy canvas ID", "Copied"],
  };

  // If the canvas exists, display its current name
  const canvas = canvases.get(id);
  if (canvas !== undefined) {
    return (
      <span>
        <Link to={`/open/${canvas.id}`}>{canvas.name}</Link>
        <Typography.Text copyable={copyable} />
      </span>
    );
  }
  return <Typography.Text copyable={copyable}>Deleted canvas</Typography.Text>;
}

function formatFolder(
  id: string,
  folders: FolderByIdMap,
  nameOverride?: string,
  quote: boolean = true
) {
  const copyable = {
    text: `${id}`,
    tooltips: ["Copy folder ID", "Copied"],
  };

  const folder = folders.get(id);

  let folderName = nameOverride;
  if (folderName === undefined) {
    if (folder !== undefined) folderName = folder.name;
    else folderName = "Deleted folder";
  }

  const out = quote ? `'${folderName}'` : folderName;

  return <Typography.Text copyable={copyable}>{out}</Typography.Text>;
}

function formatChanges(changeSet: any): string {
  const changes: string[] = [];

  if (changeSet) {
    for (const [key, value] of Object.entries(changeSet)) {
      if (value !== null) {
        changes.push(`${key} to '${value}'`);
      } else {
        changes.push(key);
      }
    }
  }

  return changes.join(", ");
}

function formatPermission(
  details: any,
  users: UserByIdMap,
  groups: GroupByIdMap
) {
  if (details.hasOwnProperty("editors_can_share")) {
    const value = details["editors_can_share"];
    return `Changed editors can shared to ${value}`;
  }

  let principal;
  if (details.hasOwnProperty("group")) {
    principal = formatGroup(parseInt(details.group), groups);
  } else if (details.hasOwnProperty("user")) {
    principal = formatUser(parseInt(details.user), users);
  }

  return (
    <Typography.Text>
      Changed access for {principal} to {details.permission}
    </Typography.Text>
  );
}

function formatClearPermission(
  details: any,
  users: UserByIdMap,
  groups: GroupByIdMap
) {
  let principal;
  if (details.hasOwnProperty("group")) {
    principal = formatGroup(parseInt(details.group), groups);
  } else if (details.hasOwnProperty("user")) {
    principal = formatUser(parseInt(details.user), users);
  }

  return <Typography.Text>Cleared access for {principal}</Typography.Text>;
}

function formatMoveResource(event: IAuditEvent, folders: FolderByIdMap) {
  const fromId = event.details["from-folder-id"];
  const fromName = event.details["from-folder-name"];
  const toId = event.details["to-folder-id"];
  const toName = event.details["to-folder-name"];

  return (
    <>
      Moved {event.target_type} from {formatFolder(fromId, folders, fromName)}{" "}
      to {formatFolder(toId, folders, toName)}
    </>
  );
}

function formatEventDetails(
  event: IAuditEvent,
  users: UserByIdMap,
  groups: GroupByIdMap,
  folders: FolderByIdMap
) {
  switch (event.action) {
    case "Remove group member": {
      const user = formatUser(parseInt(event.details.member), users);
      const group = formatGroup(parseInt(event.details.group), groups);
      return (
        <>
          Removed {user} from {group}
        </>
      );
    }
    case "Add group member": {
      const user = formatUser(parseInt(event.details.member), users);
      const group = formatGroup(parseInt(event.details.group), groups);
      return (
        <>
          Added {user} to {group}
        </>
      );
    }
    case "Delete group":
      return `Deleted group`;
    case "Delete access token":
      return `Deleted access token ${event.details.token}`;
    case "Create email confirmation token":
      return `Created email confirmation token for '${event.details.email}'`;
    case "Confirm email":
      return `Confirmed email ${event.details.email}`;
    case "Register user":
      return `Registered user account ${event.details.name} <${event.details.email}>`;
    case "Login failed":
      const msg = event.details.email ? `for ${event.details.email} ` : "";
      return `Failed login ${msg}with ${event.details.method}`;
    case "Reset password failed":
      return `Failed to reset password`;
    case "Reset password":
      return `Reset password`;
    case "Forced password reset failed":
      return `Failed to force password reset`;
    case "Forced password reset":
      return `Forced password reset`;
    case "Logout":
      return `Signed out`;
    case "Create password reset token":
      return `Created password reset token`;
    case "Update user":
      return `Updated user ${formatChanges(event.details.changed)}`;
    case "Create access token":
      return `Created access token ${event.details.token}`;
    case "Create user":
      return `Created user ${event.details.name} <${event.details.email}>`;
    case "Login":
      return `Signed in using ${event.details.method}`;
    case "Create resource": {
      const folderId = event.details["parent-folder-id"];
      const folderName = event.details["parent-folder-name"];
      return (
        <>
          Created {event.target_type} '{event.details.name}' in folder{" "}
          {formatFolder(folderId, folders, folderName)}
        </>
      );
    }
    case "Move resource":
      return formatMoveResource(event, folders);
    case "Rename resource":
      return `Renamed ${event.target_type} from '${event.details["old-name"]}' to '${event.details["new-name"]}'`;
    case "Update resource":
      return `Updated ${event.target_type} ${formatChanges(
        event.details.changed
      )}`;
    case "Trash resource":
      return `Moved ${event.target_type} to trash`;
    case "Delete resource":
      return `Deleted ${event.target_type}`;
    case "Delete user":
      return `Deleted user`;
    case "Create group":
      return `Created group '${event.details.name}'.`;
    case "Update group":
      return `Updated group ${formatChanges(event.details.changed)}`;
    case "Change permission":
      return formatPermission(event.details, users, groups);
    case "Change link permission":
      return `Changed link permission to ${event.details.permission}`;
    case "Clear permission":
      return formatClearPermission(event.details, users, groups);
    default:
      return event.action;
  }
}

function formatTable(
  users: UserByIdMap,
  groups: GroupByIdMap,
  canvases: CanvasByIdMap,
  folders: FolderByIdMap
) {
  return [
    { title: "ID", dataIndex: "id" },
    {
      title: "Author",
      render: (_text: unknown, record: IAuditEvent) => {
        return formatUser(record.author_id, users);
      },
    },
    {
      title: "Action",
      render: (_text: unknown, record: IAuditEvent) => {
        return formatEventDetails(record, users, groups, folders);
      },
    },
    {
      title: "Target",
      render: (_text: unknown, record: IAuditEvent) => {
        switch (record.target_type) {
          case "user":
            const id: number | null = record.target_id
              ? parseInt(record.target_id as string)
              : null;
            return formatUser(id, users);
          case "canvas":
            return formatCanvas(record.target_id as string, canvases);
          case "group":
            return formatGroup(parseInt(record.target_id as string), groups);
          case "folder":
            return formatFolder(
              record.target_id as string,
              folders,
              undefined,
              false
            );
          default:
            return record.target_id;
        }
      },
    },
    {
      title: "IP address",
      dataIndex: "ip_address",
    },
    {
      title: "Date",
      render: (_text: unknown, record: IAuditEvent) => {
        // Convert to local timezone
        const date = new Date(record.created_at);
        return date.toString();
      },
    },
  ];
}

interface AuditLogFilterProps {
  onSearch: (params: IFilterParams) => void;
  onExportCSV: (params: IFilterParams) => void;
}

// TODO: clean form layout (responsive)
const AuditLogFilter: React.FunctionComponent<AuditLogFilterProps> = (
  props
) => {
  const [form] = Form.useForm();

  const [isAuthorFilterEnabled, setIsAuthorFilterEnabled] =
    useState<boolean>(false);
  const [isEntityFilterEnabled, setIsEntityFilterEnabled] =
    useState<boolean>(false);

  function onDateRadioButton(event: RadioChangeEvent) {
    const value: DateRange = event.target.value;

    if (value !== undefined) {
      let start: dayjs.Dayjs;
      const today = dayjs().endOf("day");

      switch (value) {
        case DateRange.Last7:
          start = today.subtract(7, "day");
          break;
        case DateRange.Last14:
          start = today.subtract(14, "day");
          break;
        case DateRange.Last30:
          start = today.subtract(30, "day");
          break;
      }

      form.setFieldsValue({
        dateRadioButton: value,
        dates: [start, today],
      });
    }
  }

  function onRangePickerChange() {
    form.setFieldsValue({ dateRadioButton: undefined });
  }

  function onSearch(values: any) {
    const params: IFilterParams = {
      created_after: dayjs(values.dates[0]).toISOString(),
      created_before: dayjs(values.dates[1]).toISOString(),
      author_id: values.authorId,
      target_type: values.targetType,
      target_id: values.targetId,
      per_page: defaultPerPage,
    };

    props.onSearch(params);
  }

  function onExportCSV() {
    const values = form.getFieldsValue();

    const params: IFilterParams = {
      created_after: dayjs(values.dates[0]).toISOString(),
      created_before: dayjs(values.dates[1]).toISOString(),
      author_id: values.authorId,
      target_type: values.targetType,
      target_id: values.targetId,
      per_page: defaultPerPage,
    };

    props.onExportCSV(params);
  }

  return (
    <div className="audit-log-filters">
      <Form form={form} initialValues={initialValues} onFinish={onSearch}>
        <Form.Item name="dateRadioButton">
          <Radio.Group onChange={onDateRadioButton}>
            <Radio.Button value={DateRange.Last7}>Last 7 days</Radio.Button>
            <Radio.Button value={DateRange.Last14}>Last 14 days</Radio.Button>
            <Radio.Button value={DateRange.Last30}>Last 30 days</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="dates">
          <DatePicker.RangePicker onChange={onRangePickerChange} />
        </Form.Item>

        {isAuthorFilterEnabled && (
          <Space align="baseline">
            <Form.Item noStyle>
              <Form.Item label="Author ID" name="authorId">
                <InputNumber />
              </Form.Item>
            </Form.Item>
            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => setIsAuthorFilterEnabled(false)}
            />
          </Space>
        )}
        {!isAuthorFilterEnabled && (
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => setIsAuthorFilterEnabled(true)}
              icon={<PlusOutlined />}
              block
              data-cy="filter-by-author"
            >
              Filter by author
            </Button>
          </Form.Item>
        )}

        {isEntityFilterEnabled && (
          <Space align="baseline">
            <Form.Item noStyle>
              <Form.Item label="Target type" name="targetType">
                <Select style={{ width: 120 }}>
                  <Select.Option value="user">User</Select.Option>
                  <Select.Option value="group">Group</Select.Option>
                  <Select.Option value="canvas">Canvas</Select.Option>
                  <Select.Option value="folder">Folder</Select.Option>
                  <Select.Option value="server">Server</Select.Option>
                </Select>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Target ID" name="targetId">
              <Input />
            </Form.Item>

            <MinusCircleOutlined
              className="dynamic-delete-button"
              onClick={() => setIsEntityFilterEnabled(false)}
            />
          </Space>
        )}
        {!isEntityFilterEnabled && (
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => setIsEntityFilterEnabled(true)}
              icon={<PlusOutlined />}
              block
              data-cy="filter-by-target"
            >
              Filter by target
            </Button>
          </Form.Item>
        )}

        <div className="footer">
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Search
          </Button>
          <Button icon={<DownloadOutlined />} onClick={() => onExportCSV()}>
            Export CSV
          </Button>
        </div>
      </Form>
    </div>
  );
};

// Result for an "infinite" query using keyset pagination
interface IQueryResult {
  data: IAuditEvent[];
  nextCursor?: string;
}

async function fetchAuditEvents(
  params: IFilterParams,
  cursor?: string
): Promise<IQueryResult> {
  const response = await Axios.get("/api/dashboard/audit-log", {
    params: { ...params, cursor: cursor },
  });

  // Parse details JSON in the response
  const data = response.data as any[];

  const parsedData = data.map((x) => {
    const result = x as IAuditEvent;
    result.details = JSON.parse(x.details);
    return result;
  });

  // Parse Link header for pagination
  const links = parseLinkHeader(response.headers.link);

  return {
    data: parsedData,
    nextCursor: links?.next?.cursor,
  };
}

interface IFilterParams {
  created_after?: string;
  created_before?: string;
  author_id?: number;
  target_type?: AuditEventTargetType;
  target_id?: number | string;
  per_page: number;
}

// This is hardcoded now for simplicity. If required, it can be added to the
// filter UI.
const defaultPerPage = 1000;

const AuditLogContent: React.FunctionComponent = () => {
  const queryClient = useQueryClient();

  const [filter, setFilter] = useState<IFilterParams>({
    created_after: initialValues.dates[0].toISOString(),
    created_before: initialValues.dates[1].toISOString(),
    per_page: defaultPerPage,
  });

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<IQueryResult, AxiosError>(
    ["audit-events", filter],
    ({ pageParam = undefined }) => fetchAuditEvents(filter, pageParam),
    {
      getNextPageParam: (lastPage, _allPages) => {
        return lastPage.nextCursor;
      },
    }
  );

  const { users } = useUsersContext();
  const { groups } = useGroupsContext();
  const { canvases } = useCanvasesContext();
  const { folders } = useFoldersContext();

  if (error) {
    return <QueryFailure title="Failed to query audit logs" error={error} />;
  }

  function onSearch(params: IFilterParams) {
    // Update filter state which causes the query to be re-fetched because the
    // query key changes
    setFilter(params);

    // In addition, we explicitly want to re-fetch when the user presses search
    // in case they didn't change the filter state
    queryClient.invalidateQueries("audit-events");
  }

  function onExportCSV(params: IFilterParams) {
    const url = "/api/dashboard/audit-log/export-csv";

    Axios.get(url, { params: params })
      .then((response) => {
        // This is not ideal that we load the file into memory before
        // downloading it
        downloadTextFile("audit-log.csv", response.data);
      })
      .catch((error) => {
        const msg = error.response?.data?.msg;
        message.error(`Failed to export CSV: ${msg}`);
      });
  }

  const columns = formatTable(users, groups, canvases, folders);

  // We need a flat array for antd
  const tableData = data ? data.pages.flatMap((group) => group.data) : [];

  return (
    <Card title="Audit log">
      <AuditLogFilter onSearch={onSearch} onExportCSV={onExportCSV} />
      <Divider orientation="left">Audit events</Divider>

      <Space direction="vertical">
        <Table
          size="small"
          loading={isFetching}
          columns={columns}
          dataSource={tableData}
          rowKey="id"
          pagination={false}
        />

        <Button
          className="load-more"
          disabled={!hasNextPage || isFetchingNextPage}
          onClick={() => fetchNextPage()}
        >
          {isFetchingNextPage
            ? "Loading..."
            : hasNextPage
            ? "Load more"
            : "All events shown"}
        </Button>
      </Space>
    </Card>
  );
};
