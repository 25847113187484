import { notification, Typography } from "antd";
import Axios from "axios";
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { IServerLicense } from "../interfaces";
import socket from "../socket";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(RelativeTime);

// TODO: These are duplicated in backend & frontend, can the code be shared
// somehow?
export interface IUserInfo {
  admin: boolean;
  blocked: boolean;
  approved: boolean;

  email: string;
  id: number;
  name: string;
  state: "normal" | "deleted";

  last_login: string;
  created_at: string;
}

export interface IAuthReply {
  token: string;
  user: IUserInfo;
}

interface IAuthData {
  isAuthenticated: boolean;
  user: IUserInfo;
}

export interface IAuthDataContext extends IAuthData {
  login: (user: IUserInfo, token: string) => void;
  logout: () => void;
}

// Initial value for AuthDataContext where the callbacks do nothing.
const initialAuthDataContextValue = {
  isAuthenticated: false,
  user: {
    admin: false,
    blocked: false,
    email: "",
    id: -1,
    name: "",
    state: "normal",
  } as IUserInfo,
  login: (user: IUserInfo, token: string) => {},
  logout: () => {},
};

export const AuthDataContext = createContext<IAuthDataContext>(
  initialAuthDataContextValue
);

// This component provides authentication data to its children using
// React.Context.
const AuthDataProvider: React.FunctionComponent = (children) => {
  const [authData, setAuthData] = useState<IAuthData>(
    initialAuthDataContextValue
  );

  // Sign out the current user and remove the cookie that stores the user info
  const logout = useCallback(async () => {
    console.log("logout() CALLED");

    try {
      const response = await Axios.post("/users/logout");
      console.log("LOGOUT OK:", response.data);
    } catch (error) {
      console.error("LOGOUT FAILED:", error);
    }

    socket.close();
    setAuthData(initialAuthDataContextValue);
  }, []);

  // Setup socket.io event handlers related to authenticated-user on first
  // component render
  useEffect(() => {
    const onAuthenticatedUser: Function = (user: IUserInfo) => {
      console.log("GOT AUTH INFO");
      setAuthData({ isAuthenticated: true, user: user });
    };

    socket.on("authenticated-user", onAuthenticatedUser);
    socket.on("logout", logout);

    return function cleanup() {
      socket.off("authenticated-user", onAuthenticatedUser);
      socket.off("logout", logout);
    };
  }, [logout]);

  // Sign in the given user and store the information in a cookie
  function login(user: IUserInfo, token: string) {
    console.log("login() CALLED");

    // We expect the user info to arrive via socket.io authenticated-user event
    socket.emit("authenticate", user.id, token);

    // Open socket.io connection to backend if needed
    // TODO: this is not required for Canvus client?
    if (!socket.connected) socket.open();

    // If the user to sign-in is administrator, check the server license to
    // display
    if (user.admin) {
      Axios.get("/api/dashboard/license").then((response) => {
        const license = response.data as IServerLicense;
        checkLicense(license);
      });
    }
  }

  const contextData: IAuthDataContext = {
    isAuthenticated: authData.isAuthenticated,
    user: authData.user,
    login,
    logout,
  };

  return <AuthDataContext.Provider value={contextData} {...children} />;
};

// Check server license and display an error or warning if needed
function checkLicense(license: IServerLicense) {
  if (license.is_valid === false) {
    // We can't use client-side routing here, since the callback is executed
    // outside <Router>.
    notification.error({
      message: (
        <Typography.Text>
          This Canvus Connect server has no valid license. See the{" "}
          <Typography.Link href="/admin/settings/license">
            license page
          </Typography.Link>{" "}
          to activate a license.
        </Typography.Text>
      ),
      duration: 0,
    });
  } else if (license.valid_until !== undefined) {
    // License is valid and has an expiry date (not perpetual license), so check
    // if it is about to expire

    const expiryDate = dayjs(license.valid_until);
    const today = dayjs();
    const diff = expiryDate.diff(today, "day");

    if (diff < 30) {
      let txtStart = `This Canvus Connect server license will expire in {diff} days.`;
      if (diff === 0) {
        txtStart = "This Canvus Connect server license expires today.";
      } else if (diff < 0) {
        txtStart = "This Canvus Connect server license has expired.";
      }
      notification.warning({
        message: (
          <Typography.Text>
            {txtStart} See the{" "}
            <Typography.Link href="/admin/settings/license">
              license page
            </Typography.Link>{" "}
            to renew your license.
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }
}

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;
