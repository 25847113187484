import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuthDataContext } from "./AuthProvider";
import { LoginPage, MobileLoginPage } from "../pages/LoginPage";
import { ProfileAccountPage } from "../pages/profile/Account";
import { ProfilePasswordPage } from "../pages/profile/Password";
import { ProfileAccessTokensPage } from "../pages/profile/AccessTokens";
import { CanvasListPage } from "../pages/canvas-list/CanvasListPage";
import { UsersPage } from "../pages/UsersPage";
import { GroupsPage } from "../pages/GroupsPage";
import { AdminDashboardPage } from "../pages/admin/AdminDashboardPage";
import { AdminUsersPage } from "../pages/admin/AdminUsersPage";
import { UnauthorizedPage } from "../pages/ErrorPage";
import { AdminEditUserPage } from "../pages/admin/AdminEditUserPage";
import { AdminGroupsPage } from "../pages/admin/AdminGroupsPage";
import { AdminNewGroupPage } from "../pages/admin/AdminNewGroupPage";
import { AdminEditGroupPage } from "../pages/admin/AdminEditGroupPage";
import { AdminSettingsGeneralPage } from "../pages/admin/AdminSettingsGeneralPage";
import { AdminSettingsAuthenticationPage } from "../pages/admin/AdminSettingsAuthenticationPage";
import { AdminSettingsLicensePage } from "../pages/admin/AdminSettingsLicensePage";
import { LoginOk } from "../pages/LoginOk";
import { AdminSettingsEmailPage } from "../pages/admin/AdminSettingsEmailPage";
import { AdminNewUserPage } from "../pages/admin/AdminNewUserPage";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import { RegisterPage } from "../pages/RegisterPage";
import { VerifyEmailPage } from "../pages/VerifyEmailPage";
import { AdminAuditLogPage } from "../pages/admin/AdminAuditLogPage";
import { CanvasPage } from "../pages/canvas/CanvasPage";

// A private route that requires authentication. Redirects to sign-in if not
// authenticated.
function PrivateRoute({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuthDataContext();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/users/login" state={{ from: location }} />;
  }

  return children;
}

// A route that requires user is authenticated and an admin. If authenticated
// but not an admin, it will render the unauthorized page. If the user is not
// authenticated, it will redirect to login page.
function AdminRoute({ children }: { children: JSX.Element }) {
  const { isAuthenticated, user } = useAuthDataContext();
  const location = useLocation();

  const isUserAdmin = isAuthenticated && user.admin;

  if (!isAuthenticated) {
    return <Navigate to="/users/login" state={{ from: location }} />;
  }

  if (isUserAdmin) {
    return children;
  }

  return <UnauthorizedPage />;
}

export const Router: React.FunctionComponent = () => {
  return (
    <Routes>
      <Route path="/users/login" element={<LoginPage />} />
      <Route path="/users/password/reset" element={<ResetPasswordPage />} />
      <Route path="/users/register" element={<RegisterPage />} />
      <Route path="/users/confirm-new-user" element={<VerifyEmailPage />} />
      <Route path="/users/confirm-new-email" element={<VerifyEmailPage />} />

      <Route
        path="/users/login/mobile/:mobileToken"
        element={
          <PrivateRoute>
            <MobileLoginPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/login-ok"
        element={
          <PrivateRoute>
            <LoginOk />
          </PrivateRoute>
        }
      />

      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfileAccountPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/password"
        element={
          <PrivateRoute>
            <ProfilePasswordPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile/access-tokens"
        element={
          <PrivateRoute>
            <ProfileAccessTokensPage />
          </PrivateRoute>
        }
      />

      <Route
        path="/canvases"
        element={
          <PrivateRoute>
            <CanvasListPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <UsersPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/groups"
        element={
          <PrivateRoute>
            <GroupsPage />
          </PrivateRoute>
        }
      />

      <Route path="/open/:canvasId" element={<CanvasPage />} />

      <Route
        path="/admin/dashboard"
        element={
          <AdminRoute>
            <AdminDashboardPage />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/users"
        element={
          <AdminRoute>
            <AdminUsersPage />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/users/new"
        element={
          <AdminRoute>
            <AdminNewUserPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/users/:userId/edit"
        element={
          <AdminRoute>
            <AdminEditUserPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/groups"
        element={
          <AdminRoute>
            <AdminGroupsPage />
          </AdminRoute>
        }
      />
      <Route
        path="/admin/groups/new"
        element={
          <AdminRoute>
            <AdminNewGroupPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/groups/:groupId/edit"
        element={
          <AdminRoute>
            <AdminEditGroupPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/settings/general"
        element={
          <AdminRoute>
            <AdminSettingsGeneralPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/settings/authentication"
        element={
          <AdminRoute>
            <AdminSettingsAuthenticationPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/settings/email"
        element={
          <AdminRoute>
            <AdminSettingsEmailPage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/settings/license"
        element={
          <AdminRoute>
            <AdminSettingsLicensePage />
          </AdminRoute>
        }
      />

      <Route
        path="/admin/audit-log"
        element={
          <AdminRoute>
            <AdminAuditLogPage />
          </AdminRoute>
        }
      />

      <Route index element={<Navigate to="/users/login" />} />

      <Route path="*" element={<NoRouteMatch />} />
    </Routes>
  );
};

// For reporting client-side routing errors ("404")
function NoRouteMatch() {
  const location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}
