import * as PIXI from "pixi.js";
import { ImageWidgetEvent } from "../canvas-element-events";
import { MipmapSprite } from "../images/mipmap-sprite";
import { WebClient, Widget } from "../web-client";
import { syncCanvasItem } from "../widget-synchronizer";
import { setBBoxSize } from "./helpers";

export type ImageWidget = Widget & {
  sprite?: MipmapSprite;
};

export function syncImageWidget(app: WebClient, event: ImageWidgetEvent) {
  let w: ImageWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as ImageWidget;
    w.title = event.title;
    setBBoxSize(w, event.size);
  } else {
    const host = new PIXI.Container();
    host.name = "ImageWidget";
    w = {
      id: event.id,
      host,
      type: "Image",
      title: event.title,
      downloadUrl: `/api/v1/canvases/${app.canvasId}/images/${event.id}/download`,
      bbox: {
        left: 0,
        top: 0,
        right: event.size.width,
        bottom: event.size.height,
      },
    };
    app.widgets.set(event.id, w);
  }

  if (
    event.hash !== "" &&
    (!w.sprite || w.sprite.publicHashHex !== event.hash)
  ) {
    if (w.sprite) w.sprite.destroy();
    w.sprite = MipmapSprite.create(app, event.hash);
    w.host.addChild(w.sprite);
  }

  syncCanvasItem(app, event, w);
}
