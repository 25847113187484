// Returns an Intl.Collator object for language-sensitive string comparison.
export function getCollator() {
  // Sorting arbitrary strings in case-insensitive manner is a language-specific
  // feature. We use the preferred content language from the web browser.
  let languages = navigator.languages
    ? navigator.languages
    : [navigator.language];
  if (languages.length === 0) {
    languages = ["en"];
  }
  // navigator.languages is readonly string[], so we need to use "as string[]",
  // since Intl.Collator takes in just string[].
  const collator = new Intl.Collator(languages as string[], {
    sensitivity: "accent",
  });

  return collator;
}
