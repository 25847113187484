// Defines a responsive layout for the main content (labels and input controls)
// of normal (wide) forms
export const formLayout = {
  labelCol: {
    sm: {
      span: 12,
    },
    md: {
      span: 10,
    },
    lg: {
      span: 6,
    },
    xl: {
      span: 5,
    },
  },
  wrapperCol: {
    sm: {
      span: 12,
    },
    md: {
      span: 14,
    },
    lg: {
      span: 9,
    },
    xl: {
      span: 8,
    },
  },
};

// Defines a responsive layout for the tail (submit button, etc) of normal
// (wide) forms
export const formTailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
    },
    sm: {
      offset: 12,
    },
    md: {
      offset: 10,
    },
    lg: {
      offset: 6,
    },
    xl: {
      offset: 5,
    },
  },
};

// Defines a responsive layout for the main content (labels and input controls)
// of narrow forms
export const narrowFormLayout = {
  labelCol: {
    sm: {
      span: 12,
    },
  },
  wrapperCol: {
    sm: {
      span: 12,
    },
  },
};

// Defines a responsive layout for the tail (submit button, etc) of narrow forms
export const narrowFormTailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
    },
    sm: {
      offset: 12,
    },
  },
};
