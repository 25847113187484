import * as PIXI from "pixi.js";
import { subscribe, unsubscribe } from "../backend";
import {
  AnchorWidgetEvent,
  AnnotationChangeEvent,
  AnnotationInitialEvent,
  CanvasBackgroundEvent,
  CanvasElementEvent,
  CanvasItemEvent,
  CanvasWidgetEvent,
  ConnectorWidgetEvent,
  ImageWidgetEvent,
  NoteWidgetEvent,
  PdfWidgetEvent,
  VideoWidgetEvent,
} from "./canvas-element-events";
import { onSingleTap } from "./interaction";
import { WebClient, Widget } from "./web-client";
import { syncAnchorWidget } from "./widgets/anchor";
import { AnnotationContainer } from "./widgets/annotation-container";
import { syncCanvasBackground } from "./widgets/background";
import { syncCanvasWidget } from "./widgets/canvas";
import { syncConnectorWidget } from "./widgets/connector";
import { syncImageWidget } from "./widgets/image";
import { syncNoteWidget } from "./widgets/note";
import { syncPdfWidget } from "./widgets/pdf";
import { syncVideoWidget } from "./widgets/video";

function syncUnsupportedCanvasItem(app: WebClient, event: CanvasItemEvent) {
  console.log("Unsupported canvas item", event);
  let w = app.widgets.get(event.id);
  if (w === undefined) {
    w = {
      id: event.id,
      host: new PIXI.Container(),
      type: "Other",
      title: event.widget_type,
      bbox: {
        left: 0,
        top: 0,
        right: event.size.width,
        bottom: event.size.height,
      },
    };
    app.widgets.set(event.id, w);
    w.host.name = event.widget_type;
  }
  syncCanvasItem(app, event, w);
}

export function syncCanvasItem(
  app: WebClient,
  event: CanvasItemEvent,
  w: Widget,
  handleParent = true
): Widget {
  w.host.x = event.location.x;
  w.host.y = event.location.y;
  if (w.host.sortableChildren) {
    if (w.host.zIndex !== event.depth) {
      w.host.zIndex = event.depth;
      w.host.emit("depth-changed");
    }
  } else {
    w.host.sortableChildren = true;
    w.host.zIndex = event.depth;
  }
  w.host.scale.set(event.scale);

  if (handleParent) {
    const parent = app.widgets.get(event.parent_id);
    if (parent !== undefined && parent.host !== w.host.parent) {
      parent.host.addChild(w.host);
    }
  }

  if (!w.host.interactive) {
    (w.host as any).canvasItem = true;
    w.host.interactive = true;
    onSingleTap(w.host, (e: PIXI.InteractionEvent) => {
      app.activateWidget(w);
      e.stopPropagation();
    });
  }

  syncAnnotations(app, event, w);

  return w;
}

export function syncAnnotations(
  app: WebClient,
  event: CanvasItemEvent,
  w: Widget
) {
  if (!event.annotations) return;

  if (event.annotations.length > 0) {
    if (!w.annotations) {
      w.annotations = new AnnotationContainer();
      w.annotations.name = "AnnotationContainer";
      (w.annotations as any).canvasItem = true;

      w.host.addChild(w.annotations);
    }
    w.annotations.setAnnotations(event.annotations);
    for (const e of event.annotations) {
      app.annotations.set(e.id, w);
    }
  } else if (w.annotations) {
    w.annotations.setAnnotations(event.annotations);
  }
}

function syncAnnotationChanges(app: WebClient, event: AnnotationChangeEvent) {
  if (event.parent_id) {
    // New annotation was added
    const w = app.widgets.get(event.parent_id);
    if (!w) {
      console.error("Failed to find widget for annotation", event);
      return;
    }

    if (!w.annotations) {
      w.annotations = new AnnotationContainer();
      w.annotations.name = "AnnotationContainer";
      (w.annotations as any).canvasItem = true;

      w.host.addChild(w.annotations);
    }
    w.annotations.addAnnotation(event as AnnotationInitialEvent);
    app.annotations.set(event.id, w);
  } else {
    const w = app.annotations.get(event.id);
    if (!w || !w.annotations) {
      console.error("Failed to find annotation", event);
      return;
    }

    if (event.state === "deleted") {
      w.annotations.deleteAnnotation(event.id);
      app.annotations.delete(event.id);
    } else {
      w.annotations.updateAnnotation(event);
    }
  }
}

function widgetDeleted(app: WebClient, id: string) {
  const w = app.widgets.get(id);
  if (w !== undefined) {
    w.host.destroy({ children: true });
    app.widgets.delete(id);
    if (w.type === "Anchor") {
      app.anchorManager.anchorRemoved(id);
    }
    if (w.annotations) {
      const strokes = w.annotations.strokes();
      if (strokes) {
        for (const strokeId of strokes.keys()) {
          app.annotations.delete(strokeId);
        }
      }
    }
    // TODO: What about children in app.widgets?
  }
}

function processEvents(app: WebClient, events: CanvasElementEvent[]) {
  for (let event of events) {
    if (event.state === "deleted") {
      if (event.widget_type === "Annotation") {
        syncAnnotationChanges(app, event as AnnotationChangeEvent);
      } else {
        widgetDeleted(app, (event as CanvasItemEvent).id);
      }
      continue;
    }

    switch (event.widget_type) {
      case "SharedCanvas":
        syncCanvasWidget(app, event as CanvasWidgetEvent, app.view);
        break;

      case "Note":
        syncNoteWidget(app, event as NoteWidgetEvent);
        break;

      case "Image":
        syncImageWidget(app, event as ImageWidgetEvent);
        break;

      case "Video":
        syncVideoWidget(app, event as VideoWidgetEvent);
        break;

      case "Anchor":
        const w = syncAnchorWidget(app, event as AnchorWidgetEvent);
        app.anchorManager.anchorUpdated((event as AnchorWidgetEvent).id, w);
        break;

      case "Pdf":
        syncPdfWidget(app, event as PdfWidgetEvent);
        break;

      case "Connector":
        syncConnectorWidget(app, event as ConnectorWidgetEvent);
        break;

      case "CanvasBackground":
        if (app.canvas) {
          syncCanvasBackground(app, event as CanvasBackgroundEvent, app.canvas);
        } else {
          console.error(
            "CanvasBackground event arrived before CanvasWidgetEvent"
          );
        }
        break;

      case "Annotation":
        syncAnnotationChanges(app, event as AnnotationChangeEvent);
        break;

      default:
        if (!(event as any).parent_id) {
          console.log("Unsupported message", event);
          continue;
        }
        syncUnsupportedCanvasItem(app, event as CanvasItemEvent);
    }
  }
  app.setDirty();
}

export function createWidgetSynchronizer(app: WebClient, canvasId: string) {
  const endpoint = `/canvases/${canvasId}/widgets?annotations=1`;
  let first = true;

  const handler = (events: CanvasElementEvent[]) => {
    processEvents(app, events);
    if (first) {
      first = false;
      app.nav.zoomToFit(0);
    }
  };
  subscribe(endpoint, handler);

  return function cleanup() {
    unsubscribe(endpoint, handler);
  };
}
