import * as PIXI from "pixi.js";
import { Renderer } from "../gc";
import { intersects } from "../math/pixi-additions";
import { WebClient } from "../web-client";
import { estimateScale } from "../widgets/helpers";
import { MipmapResource } from "./mipmap-resource";

function render(sprite: PIXI.Sprite, renderer: Renderer): boolean {
  const worldBounds = sprite.getBounds(true);
  const viewport = renderer.viewport;

  const visible = intersects(worldBounds, viewport);
  if (!visible) return false;

  const scale =
    estimateScale(sprite.transform.worldTransform) * renderer.projectionScale;
  const level = Math.floor(Math.log2(1.0 / scale));
  const res = sprite._texture.baseTexture.resource as MipmapResource;

  res.loadLevel(level, renderer.frameTime, true);

  return res.hasData();
}

function create(
  type: typeof MipmapTilingSprite | typeof MipmapSprite,
  app: WebClient,
  publicHashHex: string,
  page: number,
  useTextureCache: boolean
) {
  const cacheId = `${publicHashHex}.${page}`;
  let texture = useTextureCache ? PIXI.utils.TextureCache[cacheId] : undefined;
  if (texture === undefined) {
    const res = new MipmapResource(publicHashHex, page, () => {
      app.setDirty();
    });
    const baseTexture = new PIXI.BaseTexture(res, {
      mipmap: PIXI.MIPMAP_MODES.ON_MANUAL,
    });
    baseTexture.setResolution(1.0);
    baseTexture.cacheId = cacheId;

    texture = new PIXI.Texture(baseTexture);
    if (useTextureCache) {
      PIXI.Texture.addToCache(texture, cacheId);
    }
  }

  let sprite = new type(texture, publicHashHex);
  texture.baseTexture.on("update", () => {
    sprite.emit("update");
  });
  return sprite;
}

// Like PIXI.Sprite but uses dynamic mipmap levels and /api/v1/mipmaps endpoint
// Do not add random children to this object, since it uses (recursive) getBounds
// for culling and mipmap level calculation.
export class MipmapSprite extends PIXI.Sprite {
  constructor(tex: PIXI.Texture, public publicHashHex: string) {
    super(tex);
  }

  protected _render(renderer: Renderer): void {
    if (render(this, renderer)) super._render(renderer);
  }

  static create(
    app: WebClient,
    publicHashHex: string,
    page = 0,
    useTextureCache = true
  ): MipmapSprite {
    return create(
      MipmapSprite,
      app,
      publicHashHex,
      page,
      useTextureCache
    ) as MipmapSprite;
  }
}

export class MipmapTilingSprite extends PIXI.TilingSprite {
  constructor(tex: PIXI.Texture, public publicHashHex: string) {
    super(tex);
  }

  protected _render(renderer: Renderer): void {
    if (render(this, renderer)) super._render(renderer);
  }

  static create(
    app: WebClient,
    publicHashHex: string,
    page = 0,
    useTextureCache = true
  ): MipmapTilingSprite {
    return create(
      MipmapTilingSprite,
      app,
      publicHashHex,
      page,
      useTextureCache
    ) as MipmapTilingSprite;
  }
}
