// Simple rectangle class, similar to Nimble::Rectf
//
// See comments about third-party libraries in vector.ts.
//
// There also is PIXI.Rectangle, but that can't be used since PIXI doesn't
// seem to work outside browser context, so for instance bezier tessellator
// couldn't be invoked in node.js unit and performance tests, it it imports
// anything from PIXI.
// It is also slightly more inconvenient, since rectangles are mostly used
// in all kinds of bounding-box checking so storing the right/bottom edges
// instead of width/height simplifies much code.

import { Vec2, Vec3 } from "./vec";

export type Rectf = {
  left: number;
  right: number;
  top: number;
  bottom: number;
};

export function intersects(a: Rectf, b: Rectf): boolean {
  if (b.right < a.left || b.left > a.right) return false;
  if (b.bottom < a.top || b.top > a.bottom) return false;
  return true;
}

export function intersection(a: Rectf, b: Rectf): Rectf {
  return {
    left: Math.max(a.left, b.left),
    top: Math.max(a.top, b.top),
    right: Math.min(a.right, b.right),
    bottom: Math.min(a.bottom, b.bottom),
  };
}

export function contains(a: Rectf, b: Rectf): boolean {
  return (
    b.left >= a.left &&
    b.right <= a.right &&
    b.top >= a.top &&
    b.bottom <= a.bottom
  );
}

export function isValid(rect: Rectf) {
  return rect.left <= rect.right && rect.top <= rect.bottom;
}

export function expand(rect: Rectf, o: Rectf) {
  expand2(rect, { x: o.left, y: o.top });
  expand2(rect, { x: o.right, y: o.bottom });
}

export function createScaledRect(rect: Rectf, rel: number) {
  const dw = 0.5 * rel * (rect.right - rect.left);
  const dh = 0.5 * rel * (rect.bottom - rect.top);
  return {
    left: rect.left - dw,
    right: rect.right + dw,
    top: rect.top - dh,
    bottom: rect.bottom + dh,
  };
}

export function expand2(rect: Rectf, v: Vec2) {
  if (isValid(rect)) {
    rect.left = Math.min(rect.left, v.x);
    rect.top = Math.min(rect.top, v.y);
    rect.right = Math.max(rect.right, v.x);
    rect.bottom = Math.max(rect.bottom, v.y);
  } else {
    rect.left = rect.right = v.x;
    rect.top = rect.bottom = v.y;
  }
}

export function expand3(rect: Rectf, v: Vec3) {
  expand2(rect, { x: v.x - v.z, y: v.y - v.z });
  expand2(rect, { x: v.x + v.z, y: v.y + v.z });
}

export function createEmptyRect() {
  return { left: 1, right: 0, top: 1, bottom: 0 };
}

export function rectEquals(a: Rectf, b: Rectf) {
  const epsilon = 0.0001;
  return (
    Math.abs(a.left - b.left) < epsilon &&
    Math.abs(a.top - b.top) < epsilon &&
    Math.abs(a.right - b.right) < epsilon &&
    Math.abs(a.bottom - b.bottom) < epsilon
  );
}
