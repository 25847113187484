import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  message,
  Divider,
  Typography,
  Radio,
} from "antd";
import Axios from "axios";

import { formLayout, formTailLayout } from "../FormLayout";
import AdminLayout from "./AdminLayout";

export const AdminNewUserPage: React.FunctionComponent = () => {
  return (
    <AdminLayout selectedSideMenuKey="users">
      <NewUserForm />
    </AdminLayout>
  );
};

const NewUserForm: React.FunctionComponent = () => {
  const [form] = Form.useForm();

  // We use a separate state variable to tracker access because of the custom
  // layout we want in the form with separate explanations for each Radio items.
  // Ideally we'd just use a Radio.Group and no separate state, but I couldn't
  // get the layout I wanted with that.
  const [access, setAccess] = useState<"regular" | "admin">("regular");

  function handleSubmitForm(values: any): void {
    const { name, email } = values;

    const messageKey = "create-user";

    message.loading({ content: "Creating user...", key: messageKey });

    Axios.post("/api/dashboard/users", {
      name,
      email,
      // Use the state variable to get the value of desired access level
      admin: access === "admin",
    })
      .then((_response) => {
        message.success({
          content: `Created user '${name}'.`,
          key: messageKey,
        });
        // Clear the form after successful operation
        form.resetFields();
      })
      .catch((err) => {
        console.error("Failed to create a user:", err.response);
        const msgFromServer = err.response?.data?.msg;
        message.error({
          content: `Failed to create a user. (${msgFromServer})`,
          key: messageKey,
        });
      });
  }

  return (
    <>
      <Card title="New User">
        <Form
          form={form}
          {...formLayout}
          name="new-user"
          initialValues={{}}
          onFinish={handleSubmitForm}
        >
          <Divider orientation="left">Account</Divider>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                type: "string",
                message: "User name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "User email is required",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Divider orientation="left">Password</Divider>

          <Form.Item label="Password">
            <Typography.Text strong>
              A password reset link will be generated and sent to the user. The
              user will be forced to reset the password.
            </Typography.Text>
          </Form.Item>

          <Divider orientation="left">Access</Divider>

          <Form.Item
            name="access_regular"
            label="Access level"
            help={
              <p>Regular users have access to their canvases and folders.</p>
            }
          >
            <Radio
              value="regular"
              checked={access === "regular"}
              onChange={() => setAccess("regular")}
            >
              Regular
            </Radio>
          </Form.Item>

          <Form.Item
            label={<span />}
            colon={false}
            name="access_admin"
            help={
              <p>
                Administrators have access to all canvases and folders and can
                manage all features in this installation.
              </p>
            }
          >
            <Radio
              value="administrator"
              checked={access === "admin"}
              onChange={() => setAccess("admin")}
            >
              Administrator
            </Radio>
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit">
              Create user
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
