import * as PIXI from "pixi.js";
import { AnchorItem } from "./anchor-manager";
import { readPixelsAsync } from "./async-gl";
import { Renderer } from "./gc";
import { Rectf } from "./math/rect";
import { WebClient } from "./web-client";

type SnapshotQueueItem = {
  timestamp: number;
  options: SnapshotOptions;
};
export type SnapshotOptions = {
  anchor?: AnchorItem;
};

export class SnapshotManager {
  private snapshotQueue: Array<SnapshotQueueItem> = [];

  constructor(private app: WebClient) {}

  // Schedule snaphot as soon as there is idle frame or enough time passes
  takeSnapshot(options: SnapshotOptions) {
    this.snapshotQueue.push({
      options,
      timestamp: performance.now(),
    });
    this.app.requestPaint();
  }

  paint(time: DOMHighResTimeStamp, viewPainted: boolean) {
    const maxSnapshotWaitMs = 2000.0;
    if (
      this.snapshotQueue.length > 0 &&
      (!viewPainted ||
        time - this.snapshotQueue[0].timestamp > maxSnapshotWaitMs)
    ) {
      this.takeSnapshotNow(time, this.snapshotQueue.shift()!);
    }

    // Take max one snapshot per frame, take another snapshot next frame
    if (this.snapshotQueue.length > 0) {
      this.app.requestPaint();
    }
  }
  async takeSnapshotNow(time: DOMHighResTimeStamp, item: SnapshotQueueItem) {
    let snapshotWidth: number;
    let snapshotHeight: number;
    let region: Rectf;
    let canvas: HTMLCanvasElement;

    if (item.options.anchor?.canvas) {
      canvas = item.options.anchor.canvas;
      snapshotWidth = canvas.width;
      snapshotHeight = canvas.height;

      const worldBounds = item.options.anchor.w.host.getBounds(false);
      region = {
        left: worldBounds.left,
        top: worldBounds.top,
        right: worldBounds.right,
        bottom: worldBounds.bottom,
      };

      item.options.anchor.snapshotQueued = false;
    } else {
      return;
    }

    const regionW = region.right - region.left;
    const regionH = region.bottom - region.top;
    const regionAs = regionW / regionH;
    const snapshotAs = snapshotWidth / snapshotHeight;

    if (regionAs > snapshotAs) {
      // The region we are snapshotting is wider than the snapshot image.
      // Include some extra to the region at the top and bottom to make
      // the aspect ratio to match.
      const extra = regionW / snapshotAs - regionH;
      region.top -= extra * 0.5;
      region.bottom += extra * 0.5;
    } else {
      const extra = regionH * snapshotAs - regionW;
      region.left -= extra * 0.5;
      region.right += extra * 0.5;
    }

    const renderer = this.app.pixi.renderer as Renderer;
    const renderTexture = PIXI.RenderTexture.create({
      width: snapshotWidth,
      height: snapshotHeight,
    });
    let transform = new PIXI.Matrix();
    transform.translate(-region.left, -region.top);
    const scale =
      Math.min(renderTexture.width / regionW, renderTexture.height / regionH) /
      renderer.resolution;
    transform.scale(scale, scale);

    renderer.viewport = new PIXI.Rectangle(
      region.left,
      region.top,
      regionW * renderer.resolution,
      regionH * renderer.resolution
    );
    renderer.frameTime = time;
    renderer.projectionScale = scale;

    renderer.render(this.app.view, { renderTexture, transform });

    // TODO: We might consider caching this and maybe renderTexture
    const buffer = new Uint8ClampedArray(snapshotWidth * snapshotHeight * 4);
    await readPixelsAsync(
      renderer.gl,
      0,
      0,
      snapshotWidth,
      snapshotHeight,
      renderer.gl.RGBA,
      renderer.gl.UNSIGNED_BYTE,
      buffer
    );
    const key = `${snapshotWidth}x${snapshotHeight}`;
    const id = new ImageData(buffer, snapshotWidth, snapshotHeight);
    const ctx = canvas.getContext("2d");
    if (!ctx) throw Error("Failed to get 2D canvas context");

    if (canvas.dataset.size !== undefined && canvas.dataset.size !== key) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    canvas.dataset.size = key;
    ctx.putImageData(id, 0, 0);
    renderTexture.destroy(true);
  }
}
