import { Spin, Typography } from "antd";
import { IFolder, IPermissionRequest } from "../../interfaces";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useUsersContext } from "../../components/UsersProvider";
import { FolderPreview } from "./FolderPreview";

export interface IDetailsFolderProps {
  folder: IFolder;
}

// Find the id of the principal who owns a resource.
export function findOwnerId(permissions: IPermissionRequest) {
  for (const principal of permissions.users) {
    if (principal.permission === "owner") {
      return principal.id;
    }
  }

  // Should never happen
  return undefined;
}

// Provides detailed information for a given folder.
export const DetailsFolder: React.FunctionComponent<IDetailsFolderProps> = (
  props
) => {
  const url = `/canvas-folders/${props.folder.id}/permissions`;

  // This doesn't subscribe for changes to keep this simple. It shouldn't matter
  // in this component.
  const { data } = useQuery<IPermissionRequest, AxiosError>(url);
  const { users } = useUsersContext();

  let ownerName;

  if (data) {
    const ownerId = findOwnerId(data);

    if (ownerId) {
      const owner = users.get(ownerId);

      if (owner) {
        ownerName = owner.name;
      }
    }
  } else {
    ownerName = <Spin />;
  }

  return (
    <div className="resource-details-container" data-cy="folder-details">
      <FolderPreview folder={props.folder} tags={true} />

      <Typography.Paragraph className="title" strong>
        {props.folder.name}
      </Typography.Paragraph>

      <div className="row">
        <Typography.Text strong>Owner</Typography.Text>
        <Typography.Text>{ownerName}</Typography.Text>
      </div>

      <div className="row">
        <Typography.Text strong>Resource Id</Typography.Text>
        <Typography.Text
          copyable={{
            text: props.folder.id,
            tooltips: ["Copy resource id for REST API.", "Copied."],
          }}
        ></Typography.Text>
      </div>
    </div>
  );
};
