import socket from "./socket";

// Type for handler functions that can be registered
type Handler = (obj: any) => void;

// Status message for subscription requests
export interface ISubscribeStatus {
  ok: boolean;
  status: number;
}
// Callback for subscription requests
export type SubscribeCallbackFn = (status: ISubscribeStatus) => void;

// Tracks registered handlers on an endpoint
let subscriptions = new Map<string, Handler[]>();

// Process registered events
function processSubscribedEvent(endpoint: string, data: any) {
  const handlers = subscriptions.get(endpoint);
  if (handlers) {
    // Invoke all registered handlers
    handlers.forEach((handler) => handler(data));
  } else {
    console.error(
      `Received event for ${endpoint} but no registered handlers exist.`
    );
  }
}

// Subscribe to a given API endpoint on the backend and invoke the handler for
// each change
export function subscribe(
  endpoint: string,
  handler: Handler,
  callback?: SubscribeCallbackFn
) {
  console.log(`SUBSCRIBE ${endpoint}`);

  // If a subscription does not exist, we need to ask the backend to create one
  let handlers = subscriptions.get(endpoint);

  if (handlers === undefined) {
    // Start processing events from this endpoint
    socket.on(endpoint, (data: any) => {
      processSubscribedEvent(endpoint, data);
    });

    // Register the handler handler
    subscriptions.set(endpoint, [handler]);

    // Ask the backend to set up a subscription
    socket.emit("subscribe", endpoint, callback);
  } else {
    // Append the new handler to the list for this endpoint
    handlers.push(handler);
  }
}

// Unsubscribe a handler from the given endpoint
export function unsubscribe(endpoint: string, handler: (obj: any) => void) {
  console.log(`UNSUBSCRIBE ${endpoint}`);

  let handlers = subscriptions.get(endpoint);

  if (handlers === undefined) {
    console.error(`No active subscriptions for ${endpoint} exist.`);
  } else {
    const handlerIndex = handlers.indexOf(handler);
    if (handlerIndex < 0) {
      console.error(
        `Handler ${handler} is not registered from endpoint ${endpoint}.`
      );
      return;
    }
    handlers.splice(handlerIndex, 1);

    // If no registered handlers remain for this endpoint, ask the backend to
    // unsubscribe
    if (handlers.length === 0) {
      socket.emit("unsubscribe", endpoint);
      subscriptions.delete(endpoint);
    }
  }
}
