import { Vec2 } from "./vec";

// https://developer.mozilla.org/en-US/docs/Web/CSS/easing-function
export class BezierTimingFunction {
  constructor(private p0: Vec2, private p1: Vec2) {}

  y(x: number) {
    const t = this.solveT(x);
    return this.evalY(t);
  }

  evalY(t: number) {
    const n = 1.0 - t;
    return (
      3.0 * n * n * t * this.p0.y + 3.0 * n * t * t * this.p1.y + t * t * t
    );
  }

  evalX(t: number) {
    const n = 1.0 - t;
    return (
      3.0 * n * n * t * this.p0.x + 3.0 * n * t * t * this.p1.x + t * t * t
    );
  }

  derivateX(t: number) {
    return (
      -3.0 *
      (-this.p0.x * (3.0 * t * t - 4.0 * t + 1.0) +
        t * (3.0 * this.p1.x * t - 2.0 * this.p1.x - t))
    );
  }

  solveT(x: number) {
    let t = x;

    for (let i = 0; i < 5; ++i) {
      const v = this.evalX(t);
      const error = x - v;
      if (Math.abs(error) < 0.0001) break;
      const d = this.derivateX(t);
      t = Math.max(0.0, Math.min(1.0, t + (0.9 * error) / d));
    }

    return t;
  }
}
