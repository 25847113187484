import { decode } from "base64-arraybuffer";
import { BezierNode } from "./bezier-spline-tessellator";

// Decodes base64-based annotation data to BezierNodes
export function decodeAnnotation(pointsBase64: string): Array<BezierNode> {
  if (pointsBase64.length === 0) return [];

  const points = new Float32Array(decode(pointsBase64));
  const nodes: Array<BezierNode> = new Array<BezierNode>(points.length / 9);

  for (let i = 0, m = points.length; i < m; i += 9) {
    nodes[i / 9] = {
      ctrlIn: { x: points[i], y: points[i + 1], z: points[i + 2] },
      point: {
        x: points[i + 3],
        y: points[i + 4],
        z: points[i + 5],
      },
      ctrlOut: {
        x: points[i + 6],
        y: points[i + 7],
        z: points[i + 8],
      },
    };
  }

  return nodes;
}
