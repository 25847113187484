import * as PIXI from "pixi.js";
import { decodeAnnotation } from "../annotations/annotation-decoder";
import { BezierSplineBuilder } from "../annotations/bezier-spline-builder";
import { BezierSplineRenderer } from "../annotations/bezier-spline-renderer";
import { SplineCapStyle } from "../annotations/bezier-spline-tessellator";
import {
  AnnotationChangeEvent,
  AnnotationInitialEvent,
} from "../canvas-element-events";
import { Renderer } from "../gc";
import { hexToColor } from "../math/color";
import { intersects } from "../math/pixi-additions";

// TODO: This is also used with connectors, should rename to something like SplineContainer
// Do not add random children to this object, since it uses (recursive) getBounds
// for culling and mipmap level calculation.
export class AnnotationContainer extends PIXI.Container {
  private builder_?: BezierSplineBuilder;
  splineRenderer?: BezierSplineRenderer;

  // TODO: this shouldn't be needed, it only exists since BezierSplineBuilder
  // doesn't yet support changes.
  public reset() {
    this.builder_ = new BezierSplineBuilder();
    this.splineRenderer = undefined;
    this._bounds.clear();
  }

  strokes() {
    return this.builder_ ? this.builder_.strokes : undefined;
  }

  get builder() {
    if (!this.builder_) this.builder_ = new BezierSplineBuilder();
    return this.builder_;
  }

  public destroy(options?: PIXI.IDestroyOptions | boolean): void {
    super.destroy(options);

    if (this.splineRenderer) this.splineRenderer.destroy();
    this.builder_ = undefined;
    this.splineRenderer = undefined;
  }

  setAnnotations(annotations: Array<AnnotationInitialEvent>) {
    if (this.builder_) {
      this.splineRenderer = undefined;
      this._bounds.clear();
    }

    this.builder_ = new BezierSplineBuilder();
    const style = {
      capBegin: SplineCapStyle.ROUND,
      capEnd: SplineCapStyle.ROUND,
    };
    for (let ae of annotations) {
      this.builder_.add(
        ae.id,
        hexToColor(ae.line_color),
        decodeAnnotation(ae.points),
        ae.depth,
        style
      );
    }
  }

  addAnnotation(ae: AnnotationInitialEvent) {
    if (!this.builder_) this.builder_ = new BezierSplineBuilder();
    const style = {
      capBegin: SplineCapStyle.ROUND,
      capEnd: SplineCapStyle.ROUND,
    };
    this.builder_.add(
      ae.id,
      hexToColor(ae.line_color),
      decodeAnnotation(ae.points),
      ae.depth,
      style
    );
  }

  deleteAnnotation(id: string) {
    if (!this.builder_) return;
    this.builder_.delete(id);
  }

  updateAnnotation(event: AnnotationChangeEvent) {
    if (!this.builder_) return;
    if (event.points) {
      this.builder_.setNodes(event.id, decodeAnnotation(event.points));
    } else if (event["points:insert"]) {
      const [idx, diff] = event["points:insert"];
      this.builder_.insertNodes(event.id, idx, decodeAnnotation(diff));
    } else if (event["points:change"]) {
      console.warn("Not implemented", event);
    } else if (event["points:erase"]) {
      console.warn("Not implemented", event);
    } else if (event.line_color || event.depth) {
      this.builder_.updateNode(
        event.id,
        event.line_color ? hexToColor(event.line_color) : undefined,
        event.depth
      );
    }
  }

  calculateBounds(): void {
    this._bounds.clear();

    if (this.builder_) {
      const bbox = this.builder_.bbox();

      this._bounds.addPoint(this.toGlobal({ x: bbox.left, y: bbox.top }));
      this._bounds.addPoint(this.toGlobal({ x: bbox.right, y: bbox.bottom }));
    }

    this._bounds.updateID = this._boundsID;
  }

  protected _render(renderer: Renderer): void {
    if (this.builder_ === undefined) return;

    const worldBounds = this.getBounds(true);
    const viewport = renderer.viewport;

    const visible = intersects(worldBounds, viewport);
    if (!visible) return;

    if (this.splineRenderer === undefined) {
      this.splineRenderer = new BezierSplineRenderer(this.builder_);
    }

    this.splineRenderer.render(
      renderer,
      viewport,
      this.transform.worldTransform
    );
  }
}
