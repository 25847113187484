import { WebClient } from "./web-client";
import { AnchorWidget } from "./widgets/anchor";

export type AnchorItem = {
  w: AnchorWidget;
  canvas?: HTMLCanvasElement;
  snapshotQueued: boolean;
};

export class AnchorManager {
  private anchors = new Map<string, AnchorItem>();

  constructor(private app: WebClient) {}

  anchorUpdated(id: string, w: AnchorWidget) {
    let anchor = this.anchors.get(id);
    if (anchor === undefined) {
      anchor = { w, snapshotQueued: false };
      this.anchors.set(id, anchor);
      this.app.ui.onAnchorUpdated(id, w.title ?? "", w.index);
    } else if (anchor.canvas !== undefined && !anchor.snapshotQueued) {
      anchor.snapshotQueued = true;
      this.app.snapshotManager.takeSnapshot({ anchor });
    }
  }

  anchorRemoved(id: string) {
    if (this.anchors.delete(id)) {
      this.app.ui.onAnchorRemoved(id);
    }
  }

  setDirty() {
    for (const anchor of this.anchors.values()) {
      if (!anchor.snapshotQueued && anchor.canvas !== undefined) {
        anchor.snapshotQueued = true;
        this.app.snapshotManager.takeSnapshot({ anchor });
      }
    }
  }

  registerAnchorCanvas(
    anchorId: string,
    element: HTMLCanvasElement | null
  ): void {
    const anchor = this.anchors.get(anchorId);
    if (!anchor) return;
    anchor.canvas = element ?? undefined;
    if (anchor.canvas !== undefined && !anchor.snapshotQueued) {
      anchor.snapshotQueued = true;
      this.app.snapshotManager.takeSnapshot({ anchor });
    }
  }

  setShowAnchors(show: boolean) {
    for (const anchor of this.anchors.values()) {
      anchor.w.host.visible = show;
    }
  }

  findAnchor(anchorId: string): AnchorItem | undefined {
    return this.anchors.get(anchorId);
  }
}
