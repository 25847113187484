import React, { useEffect, useReducer, Reducer } from "react";
import { createContext, useContext } from "react";
import { subscribe, unsubscribe } from "../backend";

export interface IGroup {
  id: number;
  name: string;
  description: string;
  state: "normal" | "deleted";
}

export type GroupByIdMap = Map<number, IGroup>;

export interface IGroupsContext {
  groups: GroupByIdMap;
}

const initialValue: IGroupsContext = {
  groups: new Map(),
};

// Apply given change to a map of users
function mutateGroups(groups: GroupByIdMap, change: IGroup) {
  if (change.state === "deleted") {
    groups.delete(change.id);
  } else {
    groups.set(change.id, change);
  }
}

export const GroupsContext = createContext<IGroupsContext>(initialValue);

const GroupsReducer: Reducer<GroupByIdMap, IGroup[]> = (prevState, changes) => {
  // Must make a copy and not modify prevState
  let state = new Map(prevState);

  changes.forEach((change) => {
    mutateGroups(state, change);
  });

  return state;
};

// Provides an array of all users on the server to child components
const GroupsProvider: React.FunctionComponent = (children) => {
  const [state, dispatch] = useReducer(GroupsReducer, new Map());

  useEffect(() => {
    const endpoint = "/groups";

    function handleGroupsChange(changes: IGroup[]) {
      dispatch(changes);
    }

    subscribe(endpoint, handleGroupsChange);

    return function cleanup() {
      unsubscribe(endpoint, handleGroupsChange);
    };
  }, []);

  const contextData: IGroupsContext = {
    groups: state,
  };

  return <GroupsContext.Provider value={contextData} {...children} />;
};

export const useGroupsContext = () => useContext(GroupsContext);

export default GroupsProvider;
