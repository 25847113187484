import { Button, Modal, Progress } from "antd";
import { useEffect, useState } from "react";
import { ConflictResolutionType, moveResource, MoveType } from "../../api";
import { CanvasByIdMap } from "../../components/CanvasesProvider";
import { FolderByIdMap } from "../../components/FoldersProvider";
import { FolderTree } from "./FolderTree";
import { createResourceModal } from "./ModalUtils";
import { FolderAddOutlined } from "@ant-design/icons";

interface ISelectTargetFolderDialogProps {
  serverName: string;
  visible: boolean;
  currentFolderId: string;
  resourceIds: string[];
  operation: MoveType;
  canvases: CanvasByIdMap;
  folders: FolderByIdMap;
  onCopyFinish: (targetFolderId: string) => void;
  onCancel: () => void;
}

interface IState {
  state: "selecting-target" | "copying" | "conflict";
  indexToCopy: number;
  promise?: Promise<any>;
  conflicts: ConflictResolutionType;
}

const initialState: IState = {
  state: "selecting-target",
  indexToCopy: 0,
  conflicts: "cancel",
};

// This dialog is used to both copy and move resources to a target folder. It
// allows user to select destination folder, visualizes progress and handles
// conflicts.
export const SelectTargetFolderDialog: React.FunctionComponent<ISelectTargetFolderDialogProps> =
  (props) => {
    const [targetFolderId, setTargetFolderId] = useState<string>(
      props.currentFolderId
    );

    // Use compound state to avoid excessive triggering of the hook below
    const [myState, setMyState] = useState<IState>(initialState);

    useEffect(() => {
      if (myState.state !== "copying") return;

      if (myState.promise === undefined) {
        const resourceId = props.resourceIds[myState.indexToCopy];
        const isCanvas = props.canvases.has(resourceId);
        const prefix = isCanvas ? "canvases" : "canvas-folders";

        const promise = moveResource(
          prefix,
          props.operation,
          myState.conflicts,
          resourceId,
          targetFolderId
        )
          .then(() => {
            if (myState.indexToCopy! >= props.resourceIds.length - 1) {
              // TODO: This causes the dialog to disappear, but the progress never
              // reaches 100%. Might be nice for to user to see it reach 100%.
              props.onCopyFinish(targetFolderId);
              setMyState(initialState);
            } else {
              setMyState((prev) => {
                return {
                  state: "copying",
                  indexToCopy: prev.indexToCopy! + 1,
                  promise: undefined,
                  conflicts: "cancel",
                };
              });
            }
          })
          .catch((error) => {
            const response = error.response;
            if (response.status === 409) {
              setMyState((prev) => {
                return {
                  state: "conflict",
                  indexToCopy: prev.indexToCopy,
                  promise: undefined,
                  conflicts: "cancel",
                };
              });
            }
          });

        setMyState({
          state: "copying",
          indexToCopy: myState.indexToCopy,
          promise: promise,
          conflicts: "cancel",
        });
      }
    }, [myState, targetFolderId, props]);

    const title = `${props.operation === "copy" ? "Copy" : "Move"} ${
      props.resourceIds.length
    } item${props.resourceIds.length > 1 ? "s" : ""} to:`;

    const selectTargetModalConfig = {
      visible: props.visible,
      centered: true,
      title: title,
      onCancel: props.onCancel,
      wrapClassName: "copy-resource-wrapper",
      footer: [
        <Button
          style={{ marginRight: "auto" }}
          icon={<FolderAddOutlined />}
          key="new-folder"
          onClick={() => {
            createResourceModal("folder", targetFolderId, (resourceId) => {
              setTargetFolderId(resourceId);
            });
          }}
        >
          New folder
        </Button>,
        <Button key="cancel" onClick={props.onCancel}>
          Cancel
        </Button>,
        <Button
          type="primary"
          key="ok"
          onClick={() => {
            setMyState({
              state: "copying",
              indexToCopy: 0,
              promise: undefined,
              conflicts: "cancel",
            });
          }}
        >
          {props.operation === "copy" ? "Copy" : "Move"}
        </Button>,
      ],
    };

    const percent = Math.floor(
      100 * (myState.indexToCopy / props.resourceIds.length)
    );

    const resourceId = props.resourceIds[myState.indexToCopy];

    // Handle conflict. Try to generate some informative message to the user
    const resourceName =
      props.canvases.get(resourceId)?.name ||
      props.folders.get(resourceId)?.name;
    const msg = `A resource named '${resourceName}' already exists in this location. Do you want to replace it with the one you're moving?`;

    const conflictModal = (
      <Modal
        visible={props.visible}
        centered={true}
        title="Replace resource?"
        okText="Replace"
        okButtonProps={{ danger: true }}
        onCancel={() => {
          setMyState(initialState);
          props.onCancel();
        }}
        onOk={() => {
          // TODO: replace next resource, not cancel
          setMyState((prev) => {
            return {
              state: "copying",
              indexToCopy: prev.indexToCopy,
              promise: undefined,
              conflicts: "replace",
            };
          });
        }}
      >
        {msg}
      </Modal>
    );

    const progressModal = (
      <Modal
        visible={props.visible}
        centered={true}
        closable={false}
        title={title}
        footer={null}
      >
        <div className={`copy-resource-container ${myState.state}`}>
          <Progress type="circle" percent={percent} />
        </div>
      </Modal>
    );

    const selectTargetModal = (
      <Modal {...selectTargetModalConfig}>
        <div className={`copy-resource-container ${myState.state}`}>
          <FolderTree
            rootNodeName={props.serverName}
            selectedFolderId={targetFolderId}
            onSelectFolder={(selectedKeys) => {
              const key = selectedKeys[0] as string;
              setTargetFolderId(key);
            }}
          />
        </div>
      </Modal>
    );

    switch (myState.state) {
      case "selecting-target":
        return selectTargetModal;
      case "copying":
        return progressModal;
      case "conflict":
        return conflictModal;
    }
  };
