import * as PIXI from "pixi.js";
import { distance, Vec2 } from "./math/vec";

type PointerInfo = {
  loc: Vec2;
  distance: number;
};

const pointers = new Map<number, PointerInfo>();

export function onSingleTap(
  host: PIXI.Container,
  cb: (e: PIXI.InteractionEvent) => void
) {
  host.on("touchstart", (e: PIXI.InteractionEvent) => {
    pointers.set(e.data.identifier, {
      loc: {
        x: e.data.global.x,
        y: e.data.global.y,
      },
      distance: 0,
    });
    e.stopPropagation();
  });

  host.on("touchmove", (e: PIXI.InteractionEvent) => {
    const p = pointers.get(e.data.identifier);
    if (p) {
      const loc = {
        x: e.data.global.x,
        y: e.data.global.y,
      };
      p.distance += distance(loc, p.loc);
      p.loc = loc;
    }
    e.stopPropagation();
  });

  host.on("pointertap", (e: PIXI.InteractionEvent) => {
    if (e.data.pointerType === "mouse") {
      if (e.data.button === 0) cb(e);
      return;
    }

    if (e.data.pointerType === "touch") {
      const p = pointers.get(e.data.identifier);
      if (p) {
        if (p.distance < 1.0) {
          cb(e);
        }
        pointers.delete(e.data.identifier);
      }
    }
  });
}
