import * as PIXI from "pixi.js";
import { NoteWidgetEvent } from "../canvas-element-events";
import { hexToTintAndAlpha } from "../math/color";
import { Widget, WebClient } from "../web-client";
import { syncCanvasItem } from "../widget-synchronizer";
import { setBBoxSize } from "./helpers";

export type NoteWidget = Widget & {
  // Rounded rectangle, the note background
  bg: PIXI.Graphics;
  // Actual text. This is undefined if the text is empty or if we are still loading the font
  text?: PIXI.BitmapText;
};

function drawBg(bg: PIXI.Graphics, size: { height: number; width: number }) {
  // We draw the shape as white, and then use the object tint to color it,
  // so changing color doesn't require redraw.
  bg.beginFill(0xffffff);
  // 1px inner border using @divider-color
  bg.lineStyle(1, 0x0, 0.06, 0);
  // 4px rounded corner
  bg.drawRoundedRect(0, 0, size.width, size.height, 4);
  bg.endFill();
}

export function syncNoteWidget(app: WebClient, event: NoteWidgetEvent) {
  const padding = 30.0;

  let w: NoteWidget;
  let test = app.widgets.get(event.id);
  if (test) {
    w = test as NoteWidget;
    if (setBBoxSize(w, event.size)) {
      w.bg.clear();
      drawBg(w.bg, event.size);
    }
  } else {
    w = {
      id: event.id,
      host: new PIXI.Container(),
      bg: new PIXI.Graphics(),
      type: "Note",
      title: event.title,
      bbox: {
        left: -padding,
        top: -padding,
        right: event.size.width - padding,
        bottom: event.size.height - padding,
      },
    };
    w.host.name = "NoteWidget";
    w.bg.x = -padding;
    w.bg.y = -padding;
    drawBg(w.bg, event.size);

    w.host.addChild(w.bg);
    app.widgets.set(event.id, w);
  }

  if (w.text === undefined && event.text.length > 0) {
    app.defaultFont.then((name) => {
      if (w === undefined) return;
      if (w.text === undefined && event.text.length > 0) {
        w.text = new PIXI.BitmapText(event.text, {
          fontName: name,
          fontSize: 19.5,
          maxWidth: event.size.width - padding * 2.0,
        });
        [w.text.tint, w.text.alpha] = hexToTintAndAlpha(event.text_color);
        w.host.addChild(w.text);
        app.setDirty();
      } else if (event.text.length > 0) {
        w.text!.text = event.text;
        w.text!.maxWidth = event.size.width - padding * 2.0;
        [w.text!.tint, w.text!.alpha] = hexToTintAndAlpha(event.text_color);
      }
    });
  } else if (event.text.length > 0) {
    w.text!.text = event.text;
    w.text!.maxWidth = event.size.width - padding * 2.0;
    [w.text!.tint, w.text!.alpha] = hexToTintAndAlpha(event.text_color);
  } else if (w.text) {
    w.text.destroy();
    w.text = undefined;
  }

  [w.bg.tint, w.bg.alpha] = hexToTintAndAlpha(event.background_color);

  w.title = event.title;
  syncCanvasItem(app, event, w);

  // The note scale origin is the content box, not padding box.
  w.host.pivot.set(-padding / event.scale, -padding / event.scale);
}
