import * as PIXI from "pixi.js";
import { Rectf } from "../math/rect";
import { WebClient, Widget } from "../web-client";

export function estimateScale(worldTransform: PIXI.Matrix) {
  const det =
    worldTransform.a * worldTransform.d - worldTransform.b * worldTransform.c;
  return Math.sqrt(Math.abs(det));
}

export function toLocal(r: PIXI.Rectangle, worldTransform: PIXI.Matrix): Rectf {
  const leftTop = worldTransform.applyInverse({ x: r.left, y: r.top });
  const rightBottom = worldTransform.applyInverse({
    x: r.right,
    y: r.bottom,
  });

  return {
    left: leftTop.x,
    top: leftTop.y,
    right: rightBottom.x,
    bottom: rightBottom.y,
  };
}

// Modifies rect
export function fromWorldToApp(
  app: WebClient,
  rect: PIXI.Rectangle
): PIXI.Rectangle {
  return fromWorldToLocal(app.view, rect);
}

// Modifies rect
export function fromWorldToLocal(
  target: PIXI.DisplayObject,
  rect: PIXI.Rectangle
): PIXI.Rectangle {
  const low = target.toLocal(
    { x: rect.x, y: rect.y },
    undefined,
    undefined,
    true
  );
  const high = target.toLocal(
    { x: rect.right, y: rect.bottom },
    undefined,
    undefined,
    true
  );
  rect.x = low.x;
  rect.y = low.y;
  rect.width = high.x - low.x;
  rect.height = high.y - low.y;
  return rect;
}

export function widgetWorldBBox(w: Widget): PIXI.Rectangle {
  const lo = w.host.worldTransform.apply({ x: w.bbox.left, y: w.bbox.top });
  const hi = w.host.worldTransform.apply({ x: w.bbox.right, y: w.bbox.bottom });
  return new PIXI.Rectangle(lo.x, lo.y, hi.x - lo.x, hi.y - lo.y);
}

export function setBBoxSize(
  w: Widget,
  size: { height: number; width: number }
): boolean {
  const r = w.bbox.left + size.width;
  const b = w.bbox.top + size.height;
  if (
    Math.abs(w.bbox.right - r) < 0.0001 ||
    Math.abs(w.bbox.bottom - b) < 0.0001
  ) {
    w.bbox.right = r;
    w.bbox.bottom = b;
    w.host.emit("change");
    return true;
  }
  return false;
}

type PatchedDisplayObject = PIXI.DisplayObject & {
  oldTx_?: number;
  oldTy_?: number;
  oldScale_?: number;
};

export function fuzzyCompare(a: number, b: number, diff = 0.0001) {
  return Math.abs(a - b) < diff;
}

// Monitors changes to the given DisplayObject world transform and emits
// a signal "change" when the object scene transform (world transform
// excluding the viewport transform) changes.
export function listenSceneTransform(app: WebClient, o: PatchedDisplayObject) {
  if (o.oldTx_ !== undefined) return;

  o.oldTx_ = o.worldTransform.tx;
  o.oldTy_ = o.worldTransform.ty;
  o.oldScale_ = estimateScale(o.worldTransform);

  const orig = o.updateTransform;
  const sceneTransform = new PIXI.Matrix();

  o.updateTransform = function () {
    orig.apply(this);
    sceneTransform.copyFrom(app.viewLocalTransformInv());
    sceneTransform.append(this.worldTransform);
    const scale = estimateScale(sceneTransform);

    if (
      !fuzzyCompare(this.oldScale_!, scale) ||
      !fuzzyCompare(this.oldTx_!, sceneTransform.tx) ||
      !fuzzyCompare(this.oldTy_!, sceneTransform.ty)
    ) {
      this.oldScale_ = scale;
      this.oldTx_ = sceneTransform.tx;
      this.oldTy_ = sceneTransform.ty;
      this.emit("change");
    }
  };
}
