import React, { useContext, useState } from "react";
import { IActiveWidget, IAnchor } from "../../pages/canvas/CanvusWebClient";
import { TitleBar } from "./TitleBar";
import { AnchorList } from "./AnchorList";
import { WebClientApi } from "../web-client";
import { ZoomToolbar } from "./ZoomToolbar";
import { CanvasPageContext, PageState } from "../../pages/canvas/CanvasPage";

import "./CanvasUiLayer.less";

interface ICanvasUiLayerProps {
  api: WebClientApi;
  activeWidget?: IActiveWidget;
  anchors: IAnchor[];
  scale: number;
  minScale: number;
  maxScale: number;
}

export const CanvasUiLayer: React.FunctionComponent<ICanvasUiLayerProps> = (
  props
) => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const canvasPageContext = useContext(CanvasPageContext);

  const layerRef = (e: HTMLDivElement) => {
    if (e && e.parentElement) {
      if (
        Math.abs(size.width - e.parentElement.clientWidth) > 0.5 ||
        Math.abs(size.height - e.parentElement.clientHeight) > 0.5
      ) {
        setSize({
          width: e.parentElement.clientWidth,
          height: e.parentElement.clientHeight,
        });
      }
    }
  };

  return (
    <div ref={layerRef} className="ui-layer">
      {props.activeWidget !== undefined &&
        size.width > 0 &&
        canvasPageContext.setFullScreenWidget &&
        canvasPageContext.fullScreenWidget === undefined && (
          <TitleBar
            workspaceSize={size}
            widget={props.activeWidget}
            viewOnly={canvasPageContext.viewOnly}
            fullScreenWidget={canvasPageContext.fullScreenWidget}
            setFullScreenWidget={canvasPageContext.setFullScreenWidget}
          />
        )}
      {canvasPageContext.pageState === PageState.AnchorList && (
        <AnchorList api={props.api} anchors={props.anchors} />
      )}
      {canvasPageContext.fullScreenWidget === undefined && (
        <ZoomToolbar
          workspaceSize={size}
          api={props.api}
          scale={props.scale}
          minScale={props.minScale}
          maxScale={props.maxScale}
        ></ZoomToolbar>
      )}
    </div>
  );
};
