import React, { Reducer, useEffect, useReducer, useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  message,
  Table,
  Typography,
  Alert,
  Modal,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useAuthDataContext } from "../../components/AuthProvider";
import Axios from "axios";
import ProfileLayout from "./ProfileLayout";
import { IAccessToken, IAccessTokenWithPlainToken } from "../../interfaces";
import { subscribe, unsubscribe } from "../../backend";

const AccessTokenReducer: Reducer<Map<string, IAccessToken>, IAccessToken[]> = (
  prevState,
  changes
) => {
  // Must make a copy and not modify prevState
  let state = new Map(prevState);

  changes.forEach((change) => {
    if (change.state === "deleted") {
      state.delete(change.id);
    } else {
      state.set(change.id, change);
    }
  });

  return state;
};

export const ProfileAccessTokensPage: React.FunctionComponent = () => {
  return (
    <ProfileLayout selectedSideMenuKey="tokens">
      ´ <CreateAccessToken />
      <AccessTokenTable />
    </ProfileLayout>
  );
};

function revokeAccessToken(userId: number, tokenId: string) {
  const url = `/api/dashboard/users/${userId}/access-tokens/${tokenId}`;
  Axios.delete(url)
    .then(() => {
      message.success(`Access token was revoked.`);
    })
    .catch((error) => {
      console.error("Failed to revoke access token:", error.response);
      const msgFromServer = error.response?.data?.msg;

      message.error(`Failed to revoke access token (${msgFromServer})`);
    });
}

function showRevokeConfirm(userId: number, tokenId: string) {
  Modal.confirm({
    title: "Are you sure you want to revoke this access token?",
    icon: <ExclamationCircleOutlined />,
    content: "This action can not be undone.",
    okText: "Yes",
    okType: "danger",
    cancelText: "No",
    onOk() {
      revokeAccessToken(userId, tokenId);
    },
  });
}

const AccessTokenTable: React.FunctionComponent = () => {
  const { user } = useAuthDataContext();

  const [tokens, dispatch] = useReducer(AccessTokenReducer, new Map());

  // Subscribe to monitor access tokens for the authenticated user
  useEffect(() => {
    const endpoint = `/users/${user.id}/access-tokens`;

    function handleChange(changes: IAccessToken[]) {
      dispatch(changes);
    }

    subscribe(endpoint, handleChange);

    return function cleanup() {
      unsubscribe(endpoint, handleChange);
    };
  }, [user.id]);

  const columns = [
    { title: "Description", dataIndex: "description" },
    {
      title: "Created",
      render: (_text: unknown, record: IAccessToken) => {
        return (
          <Typography.Text>
            {new Date(record.created_at).toLocaleDateString("default", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </Typography.Text>
        );
      },
    },
    {
      title: "",
      // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
      align: "right" as "right",
      render: (_text: unknown, record: IAccessToken) => {
        return (
          <Button
            type="primary"
            danger
            onClick={() => showRevokeConfirm(user.id, record.id)}
          >
            Revoke
          </Button>
        );
      },
    },
  ];

  return (
    <Card title="Access tokens">
      <Table<IAccessToken>
        columns={columns}
        dataSource={Array.from(tokens.values())}
        rowKey="id"
      />
    </Card>
  );
};

const CreateAccessToken: React.FunctionComponent = () => {
  const { user } = useAuthDataContext();
  const [form] = Form.useForm();

  const [newAccessToken, setNewAccessToken] = useState<string>();

  function handleSubmitForm(values: any): void {
    const { description } = values;

    Axios.post(`/api/dashboard/users/${user.id}/access-tokens`, {
      description: description,
    })
      .then((result) => {
        const token = result.data as IAccessTokenWithPlainToken;
        setNewAccessToken(token.plain_token);
      })
      .catch((error) => {
        console.error("Failed to create access token:", error.response);
        const msgFromServer = error.response?.data?.msg;

        message.error("Failed to create access token: " + msgFromServer);
      })
      .finally(() => form.resetFields());
  }

  return (
    <Card title="Create access token">
      {newAccessToken && (
        <Alert
          type="success"
          message={
            <>
              <Typography.Text>Your new access token: </Typography.Text>
              <Typography.Text code copyable>
                {newAccessToken}
              </Typography.Text>
            </>
          }
          showIcon
          description="Make sure you save it - you won't be able to access it again."
          closable
          onClose={() => setNewAccessToken(undefined)}
        />
      )}

      <Typography.Paragraph>
        You can create personal access token for each application that needs to
        access Canvus API. Enter a description, and we'll create a unique
        personal access token.
      </Typography.Paragraph>
      <Form
        layout="inline"
        form={form}
        name="create-token"
        initialValues={{}}
        onFinish={handleSubmitForm}
      >
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              type: "string",
              message: "Name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create access token
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
