import React from "react";
import { RouteProps, Link, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { QueryFailure } from "../components/QueryFailure";
import PageLoading from "../components/PageLoading";
import Axios, { AxiosError } from "axios";
import { Button, Result, Row } from "antd";

// Reuse styles from login page for consistent look
import "./LoginPage.less";
import PageLayout from "./PageLayout";

export const VerifyEmailPage: React.FunctionComponent<RouteProps> = (props) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  // Validate reset token
  const { isLoading, error } = useQuery<unknown, AxiosError>(
    "confirmation",
    async () => {
      const { data } = await Axios.post(`/api/dashboard/users/confirm-email`, {
        token: token,
      });
      return data;
    }
  );

  if (error) {
    return <QueryFailure title="Invalid token" error={error} />;
  }

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <PageLayout>
      <Row justify="center" align="middle">
        <Result
          status="success"
          title="Email verified"
          subTitle="Your email address was successfully verified."
          extra={
            <Link to="/users/login">
              <Button type="primary">Sign in</Button>
            </Link>
          }
        />
      </Row>
    </PageLayout>
  );
};
