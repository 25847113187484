import React, { useEffect, useRef, useState } from "react";
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Card,
  message,
  Checkbox,
  Radio,
  Divider,
  Result,
  Typography,
} from "antd";
import {
  MailOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import "./LoginPage.less";
import Axios, { AxiosError } from "axios";
import { useAuthDataContext } from "../components/AuthProvider";
import { CanvusHeader } from "../components/CanvusHeader";
import { RouteProps, Link, Navigate, useSearchParams } from "react-router-dom";
import socket from "../socket";
import { RadioChangeEvent } from "antd/lib/radio";
import QRCode from "qrcode.react";
import { IPublicServerConfig } from "../interfaces";
import { QueryFailure } from "../components/QueryFailure";
import { PageLoading } from "../components/PageLoading";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import PageLayout from "./PageLayout";

const { Header, Content } = Layout;

export const LoginPage: React.FunctionComponent<RouteProps> = (a) => {
  const [searchParams] = useSearchParams();

  const username = searchParams.get("username") || "";
  const siteName = searchParams.get("site_name") || "";

  const { isLoading, data, error } =
    useQuery<IPublicServerConfig, AxiosError>("/server-config");

  let isCanvusClientParam = searchParams.get("canvus_client");
  let isVideoWallParam = searchParams.get("multi_user");

  let isCanvusClient = isCanvusClientParam === "1";
  let isVideoWall = isVideoWallParam === "1";

  const storage = window.sessionStorage;
  if (storage && isCanvusClientParam !== null) {
    storage.setItem("canvus_client", isCanvusClientParam);
  } else if (storage) {
    isCanvusClientParam = storage.getItem("canvus_client");
    isCanvusClient = isCanvusClientParam === "1";
  }

  if (storage && isVideoWallParam !== null) {
    storage.setItem("multi_user", isVideoWallParam);
  } else if (storage) {
    isVideoWallParam = storage.getItem("multi_user");
    isVideoWall = isVideoWallParam === "1";
  }

  // Used to decide whether to redirect the user from this page
  const { isAuthenticated } = useAuthDataContext();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/canvases" } };

  // Redirect the user if needed (already signed in)
  if (isAuthenticated) {
    if (isCanvusClient) return <Navigate to="/login-ok" />;
    return <Navigate to={from} />;
  }

  if (error) {
    return (
      <Layout className={isCanvusClient ? "layout canvus-client" : "layout"}>
        <QueryFailure
          title="Failed to query server configuration"
          error={error}
        />
      </Layout>
    );
  }

  if (isLoading) {
    return (
      <Layout className={isCanvusClient ? "layout canvus-client" : "layout"}>
        <PageLoading />;
      </Layout>
    );
  }

  const config = data!;

  return (
    <Layout
      className={
        isCanvusClient
          ? "site-layout-background canvus-client"
          : "site-layout-background"
      }
    >
      {!isCanvusClient && (
        <Header>
          <CanvusHeader />
        </Header>
      )}
      <Content>
        <Row justify="center" align="middle">
          <LoginContent
            username={username}
            isVideoWall={isVideoWall}
            isCanvusClient={isCanvusClient}
            serverConfig={config}
            siteName={siteName}
          />
        </Row>
      </Content>
    </Layout>
  );
};

interface ILoginContentProps {
  username: string;
  isCanvusClient: boolean;
  isVideoWall: boolean;
  serverConfig: IPublicServerConfig;
  siteName: string;
}

enum LoginType {
  Password,
  SAML,
  QRCode,
}

function isSignInEnabled(config: IPublicServerConfig) {
  const authentication = config.authentication;

  // Either password or SAML authentication must be enabled. QR code
  // authentication alone is not enough to sign in.
  return authentication.password.enabled || authentication.saml.enabled;
}

function defaultLoginMethod(
  config: IPublicServerConfig,
  isVideoWall: boolean
): LoginType {
  if (isVideoWall && config.authentication.qr_code.enabled)
    return LoginType.QRCode;

  if (config.authentication.saml.enabled) return LoginType.SAML;

  return LoginType.Password;
}

function isSignUpEnabled(
  config: IPublicServerConfig,
  isCanvusClient: boolean
): boolean {
  if (isCanvusClient) return false;

  const passwordConfig = config.authentication.password;

  return passwordConfig.enabled && passwordConfig.sign_up_enabled;
}

const LoginContent: React.FunctionComponent<ILoginContentProps> = (props) => {
  const config = props.serverConfig;

  const defaultMethod = defaultLoginMethod(config, props.isVideoWall);

  const [selectedMethod, setSelectedMethod] =
    useState<LoginType>(defaultMethod);

  if (!isSignInEnabled(config)) {
    return (
      <Result
        status="error"
        title="Sign in disabled"
        subTitle="All sign-in methods have been disabled in the server configuration."
      />
    );
  }

  const showSignUp = isSignUpEnabled(config, props.isCanvusClient);

  return (
    <Card className="login-card" title="Sign in">
      {selectedMethod === LoginType.Password && (
        <PasswordLogin
          username={props.username}
          isVideoWall={props.isVideoWall}
          showSignUp={showSignUp}
        ></PasswordLogin>
      )}
      {selectedMethod === LoginType.SAML && (
        <SamlLogin isVideoWall={props.isVideoWall} />
      )}
      {selectedMethod === LoginType.QRCode && (
        <QRLogin
          siteName={props.siteName}
          serverConfig={config}
          isVideoWall={props.isVideoWall}
        />
      )}
      <Divider>Sign in options</Divider>
      <div className="sign-in-options">
        <Radio.Group
          defaultValue={defaultMethod}
          value={selectedMethod}
          onChange={(e: RadioChangeEvent) => {
            setSelectedMethod(e.target.value);
          }}
        >
          {config.authentication.password.enabled && (
            <Radio.Button value={LoginType.Password}>Password</Radio.Button>
          )}
          {config.authentication.saml.enabled && (
            <Radio.Button value={LoginType.SAML}>SAML</Radio.Button>
          )}
          {config.authentication.qr_code.enabled && (
            <Radio.Button value={LoginType.QRCode}>QR Code</Radio.Button>
          )}
        </Radio.Group>
      </div>
    </Card>
  );
};

interface IPasswordLoginProps {
  username: string;
  isVideoWall: boolean;
  showSignUp: boolean;
}

const PasswordLogin: React.FunctionComponent<IPasswordLoginProps> = (props) => {
  const { login } = useAuthDataContext();
  const username = useRef<Input>(null);
  const password = useRef<Input>(null);

  function handleSubmitForm(values: any): void {
    Axios.post("/users/login", {
      // Variable names must match passport configuration in backend
      username: values.username,
      password: values.password,
      remember: values.remember,
    })
      .then((response) => {
        // Login was successful
        const token = response.data.token;
        const user = response.data.user;

        login(user, token);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          const content = (
            <span>
              {error.response?.data}
              <Button
                type="link"
                onClick={() => {
                  window.location.href = "/users/password/reset";
                }}
              >
                Reset password.
              </Button>
            </span>
          );
          message.error(content);
        } else {
          message.error(error.response?.data);
        }
      });
  }

  useEffect(() => {
    if (props.username.length > 0) {
      if (password && password.current) {
        password.current.focus();
      }
    } else if (username && username.current) {
      username.current.focus();
    }
  }, [username, password, props.username]);

  return (
    <Form
      name="login"
      className="login-form"
      onFinish={handleSubmitForm}
      initialValues={{ remember: true, username: props.username }}
    >
      <Form.Item name="username" rules={[{ required: true, type: "email" }]}>
        <Input
          prefix={<MailOutlined className="site-form-item-icon" />}
          placeholder="Email"
          ref={username}
        />
      </Form.Item>

      <Form.Item name="password" rules={[{ required: true }]}>
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
          placeholder="Password"
          ref={password}
          iconRender={(visible) =>
            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>

      <Form.Item>
        {!props.isVideoWall && (
          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        )}

        <Link id="forgot_password" to="/users/password/reset">
          Forgot your password?
        </Link>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Sign in
        </Button>
      </Form.Item>

      {props.showSignUp && (
        <Form.Item>
          Don't have an account yet?{" "}
          <Link id="register" to="/users/register">
            Register now.
          </Link>
        </Form.Item>
      )}
    </Form>
  );
};

interface ISamlLoginProps {
  isVideoWall: boolean;
}

const SamlLogin: React.FunctionComponent<ISamlLoginProps> = (props) => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/canvases" } };

  function handleSubmitForm(values: any): void {
    // TODO: Is there a better way to do this? This triggers multiple
    // page reloads (one for GET, another after POST).

    // This must be a page reload as it goes to the backend to handle?
    window.location.href = `/users/login/saml?redirect=${from.pathname}&remember=${values.remember}`;
  }

  return (
    <Form
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={handleSubmitForm}
    >
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Sign in with SAML
        </Button>
      </Form.Item>

      {!props.isVideoWall && (
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
      )}
    </Form>
  );
};

interface IQRLoginProps {
  siteName: string;
  serverConfig: IPublicServerConfig;
  isVideoWall: boolean;
}

const QRLogin: React.FunctionComponent<IQRLoginProps> = (props) => {
  const { login } = useAuthDataContext();
  const [mobileId, setMobileId] = useState<string>();
  const [form] = Form.useForm();

  useEffect(() => {
    // We must have a socket.io connection for messaging
    if (!socket.connected) socket.open();

    // Request the backend to generate a new mobile id
    socket.emit("generate-mobile-id");

    const onMobileId: Function = (token: string) => {
      setMobileId(token);
    };

    socket.on("mobile-id", onMobileId);

    // We can close the connection when leaving this page
    return function cleanup() {
      socket.off("mobile-id", onMobileId);
      // We could disconnect on component unmount when the user leaves the page,
      // but it would also trigger after a user signs in and we load a different
      // page.
      // socket.disconnect();
    };
  }, []);

  if (mobileId === undefined) {
    return (
      <div className="login-form">
        <PageLoading />
      </div>
    );
  }

  const config = props.serverConfig;

  const url = `${config.external_url}/users/login/mobile/${mobileId}?name=${props.siteName}`;

  socket.once("mobile-authenticate-trigger", (token: string) => {
    console.log("Received mobile-authenticate-trigger with", token);
    const remember = form.getFieldValue("remember");

    Axios.post("/users/login/mobile", { token, remember })
      .then((response) => {
        // Login was successful
        const token = response.data.token;
        const user = response.data.user;

        login(user, token);
      })
      .catch((error) => {
        console.error(error.response);
        message.error(error.response?.data);
      });
  });

  return (
    <div className="login-form">
      <Typography.Text
        copyable={{
          text: url,
          tooltips: ["Copy QR code URL", "URL copied."],
        }}
      >
        Scan the code with your phone to sign in.
      </Typography.Text>
      <div className="qr-container">
        <QRCode value={url} />
      </div>
      {!props.isVideoWall && (
        <Form form={form} initialValues={{ remember: true }}>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export const MobileLoginPage: React.FunctionComponent = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  const id = params.mobileToken;
  const name = searchParams.get("name");

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const cardTitle = "Authenticate sign-in" + (name ? ` on ${name}` : "");

  function handleSubmitForm() {
    Axios.post("/authenticate-mobile-id", { id })
      .then((_response) => {
        message.success("Authenticated successfully.");
        console.log("Authenticated successfully.");
        setShowSuccess(true);
      })
      .catch((_error) => {
        message.error("Mobile authentication failed.");
        console.error("Mobile authentication failed.");
        setShowError(true);
      });
  }

  const success = (
    <Result status="success" title="Successfully authenticated" />
  );
  const error = <Result status="error" title="Mobile authentication failed" />;
  const form = (
    <Card className="login-card" title={cardTitle}>
      <Form
        name="mobile-authenticate"
        className="login-form"
        onFinish={handleSubmitForm}
      >
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Authenticate
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <PageLayout>
      <Row justify="center" align="middle">
        {showError && error}
        {showSuccess && success}
        {!showError && !showSuccess && form}
      </Row>
    </PageLayout>
  );
};
