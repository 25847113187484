import { Avatar, Dropdown, Menu, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useAuthDataContext } from "./AuthProvider";
import { UserOutlined } from "@ant-design/icons";

// Menu visualizing the currently authenticated user using their avatar.
export const UserAvatarMenu: React.FunctionComponent = () => {
  const { user, logout } = useAuthDataContext();

  const menu = (
    <Menu>
      <Menu.ItemGroup title={user.name}>
        <Menu.Divider />
        <Menu.Item key="profile">
          <Link to="/profile">Settings</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="logout">
          <Typography.Link onClick={logout}>Sign out</Typography.Link>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <span className="user-menu-container">
      <Dropdown
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="user-menu-overlay"
        overlay={menu}
        arrow
      >
        <Avatar icon={<UserOutlined />} />
      </Dropdown>
    </span>
  );
};
