import { Button, Divider, Switch, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { IAnchor } from "../../pages/canvas/CanvusWebClient";
import { WebClientApi } from "../web-client";
import "./AnchorList.less";
import { PageState, useCanvasPageContext } from "../../pages/canvas/CanvasPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlay,
  faEllipsisV,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

interface IAnchorTileProps {
  api: WebClientApi;
  anchorId: string;
  name: string;
  selected: boolean;
  onClick: (anchorId: string) => void;
}

interface IAnchorListProps {
  api: WebClientApi;
  anchors: IAnchor[];
}

const AnchorTile: React.FunctionComponent<IAnchorTileProps> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    props.api.registerAnchorCanvas(props.anchorId, canvasRef.current);
  }, [props]);

  const w = 282;
  const h = 153;
  const style = {
    width: `${w}px`,
    height: `${h}px`,
  };
  return (
    <div className={props.selected ? "selected anchor-tile" : "anchor-tile"}>
      <Button size="small" type="text" className="dotdotdot">
        <FontAwesomeIcon size="xs" icon={faEllipsisV} />
      </Button>
      <Typography.Text ellipsis={true} style={{ textAlign: "center" }}>
        {props.name}
      </Typography.Text>
      <canvas
        width={w * window.devicePixelRatio}
        height={h * window.devicePixelRatio}
        style={style}
        ref={canvasRef}
        onClick={() => props.onClick(props.anchorId)}
      ></canvas>
    </div>
  );
};

export const AnchorList: React.FunctionComponent<IAnchorListProps> = (
  props
) => {
  const canvasPageContext = useCanvasPageContext();
  const [selectedAnchor, setSelectedAnchor] = useState<string>();

  function onClick(anchorId: string) {
    props.api.zoomToAnchor(anchorId);
    setSelectedAnchor(anchorId);
  }

  const tiles = props.anchors.map((anchor) => (
    <AnchorTile
      key={anchor.id}
      anchorId={anchor.id}
      api={props.api}
      name={anchor.name}
      selected={anchor.id === selectedAnchor}
      onClick={onClick}
    />
  ));

  return (
    <div className="anchor-list">
      <Button
        type="text"
        onClick={() => canvasPageContext.setPageState!(PageState.NormalState)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>

      <Typography.Title level={2}>Anchor list</Typography.Title>

      <div className="buttons">
        <Button size="small" className="play-button">
          <FontAwesomeIcon size="xs" icon={faCirclePlay} />
          Present
        </Button>
        <Typography.Text className="label">Show anchors</Typography.Text>
        <Switch
          className="switch"
          onChange={(checked) => canvasPageContext.setShowAnchors!(checked)}
        />
      </div>

      <Divider />

      <div className="tiles">{tiles}</div>
    </div>
  );
};
