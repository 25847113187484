import { Empty } from "antd";
import { IFilteredFolderViewProps } from "./FolderView";
import { PreviewTileCanvas } from "./PreviewTileCanvas";
import { PreviewTileFolder } from "./PreviewTileFolder";

// Update selection given resource ids
export function selectResources(
  event: React.MouseEvent,
  resourceId: string,
  selection: Set<string>
): Set<string> {
  let result: Set<string>;

  if (event.ctrlKey || event.metaKey) {
    result = new Set(selection);

    if (result.has(resourceId)) {
      result.delete(resourceId);
    } else {
      result.add(resourceId);
    }
  } else {
    result = new Set([resourceId]);
  }

  return result;
}

// Component to visualize the contents of a folder in a grid layout.
export const FolderViewGrid: React.FunctionComponent<IFilteredFolderViewProps> =
  (props) => {
    const isFolderEmpty =
      props.childFolders.length === 0 && props.childCanvases.length === 0;
    const emptyMessage =
      props.nameFilter.length === 0
        ? "This folder is empty."
        : "No results match your filter.";

    return (
      <div
        className={`folder-view-grid${isFolderEmpty ? " empty" : ""}`}
        onClick={(event) => {
          // Clear selection if clicking on background
          event.stopPropagation();
          props.onSelectResources(new Set());
        }}
      >
        {isFolderEmpty && <Empty description={emptyMessage} />}
        {!isFolderEmpty && (
          <div className="grid">
            {
              // Show subfolders at the top
              props.childFolders.map((value) => {
                return (
                  <PreviewTileFolder
                    key={value.id}
                    folder={value}
                    onClickResource={(event) => {
                      const selection = selectResources(
                        event,
                        value.id,
                        props.selectedResources
                      );
                      props.onSelectResources(selection);
                    }}
                    onDoubleClickResource={props.onDoubleClickResource}
                    selectedResources={props.selectedResources}
                    external_url={props.external_url}
                    modal={props.modal}
                  />
                );
              })
            }
            {
              // Show canvases below folders
              props.childCanvases.map((value) => {
                return (
                  <PreviewTileCanvas
                    key={value.id}
                    canvas={value}
                    external_url={props.external_url}
                    onClickResource={(event) => {
                      const selection = selectResources(
                        event,
                        value.id,
                        props.selectedResources
                      );
                      props.onSelectResources(selection);
                    }}
                    onDoubleClickResource={props.onDoubleClickResource}
                    selectedResources={props.selectedResources}
                    modal={props.modal}
                  />
                );
              })
            }
          </div>
        )}
      </div>
    );
  };
