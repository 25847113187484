import React, { createContext, useContext, useEffect, useState } from "react";
import {
  CanvasByIdMap,
  useCanvasesContext,
} from "../components/CanvasesProvider";

import {
  FolderByIdMap,
  isRootFolder,
  useFoldersContext,
} from "../components/FoldersProvider";
import { getCollator } from "../sorting";

export interface ICanvasHierarchyNode {
  key: string;
  title: string;
  children: ICanvasHierarchyNode[];
  isCanvas: boolean;
}

export interface ICanvasHierarchyContext {
  canvases: CanvasByIdMap;
  folders: FolderByIdMap;
  hierarchy: ICanvasHierarchyNode;
}

const initialValue: ICanvasHierarchyContext = {
  canvases: new Map(),
  folders: new Map(),
  hierarchy: {
    key: "",
    title: "",
    children: [],
    isCanvas: false,
  },
};

export const CanvasHierarchyContext =
  createContext<ICanvasHierarchyContext>(initialValue);

// Maps folder id to ICanvasHierarchyNode
type FolderCache = Map<string, ICanvasHierarchyNode>;

// Provides a tree data structure of all canvases and folders (the user can
// access) on the server. Requires <CanvasesProvider> and <FoldersProvider>.
const CanvasHierarchyProvider: React.FunctionComponent = (children) => {
  const [root, setRoot] = useState<ICanvasHierarchyNode>(
    initialValue.hierarchy
  );

  const { folders } = useFoldersContext();
  const { canvases } = useCanvasesContext();

  useEffect(() => {
    const { root, folderCache } = updateFolders(folders);

    // There's no guarantee about the order in which this hook gets called when
    // canvases or folders change. We can't construct the hierarchy unless we
    // have the folder data available. We can just skip the hook in that case
    // and it will re-trigger when the folder data arrives.
    if (folderCache.size > 0) {
      updateCanvases(canvases, folderCache);
    }

    sortChildren(folderCache);

    setRoot(root);
  }, [folders, canvases]);

  const context: ICanvasHierarchyContext = {
    canvases: canvases,
    folders: folders,
    hierarchy: root,
  };

  return <CanvasHierarchyContext.Provider value={context} {...children} />;
};

export const useCanvasHierarchyContext = () =>
  useContext(CanvasHierarchyContext);

export default CanvasHierarchyProvider;

function updateFolders(folders: FolderByIdMap): {
  root: ICanvasHierarchyNode;
  folderCache: FolderCache;
} {
  let root = initialValue.hierarchy;
  const folderCache: FolderCache = new Map();

  // Update root node & rebuild folder cache
  folders.forEach((folder) => {
    const folderNode: ICanvasHierarchyNode = {
      key: folder.id,
      title: folder.name,
      children: [],
      isCanvas: false,
    };

    if (isRootFolder(folder)) {
      root = folderNode;
      root.title = "Canvus Connect Server";
    }

    folderCache.set(folder.id, folderNode);
  });

  // Construct folder hierarchy
  folders.forEach((folder) => {
    if (!isRootFolder(folder)) {
      const node = folderCache.get(folder.id);
      const parent = folderCache.get(folder.folder_id);

      if (parent && node) {
        parent.children.push(node);
      } else {
        console.error(
          `Folder ${folder.id} or its parent ${folder.folder_id} were not found in cache.`,
          folder
        );
      }
    }
  });

  return { root, folderCache };
}

function updateCanvases(canvases: CanvasByIdMap, folderCache: FolderCache) {
  // Populate folders with canvases
  canvases.forEach((canvas) => {
    const node: ICanvasHierarchyNode = {
      key: canvas.id,
      title: canvas.name,
      children: [],
      isCanvas: true,
    };
    const parent = folderCache.get(canvas.folder_id);

    if (parent && node) {
      parent.children.push(node);
    } else {
      console.error(
        `Canvas ${canvas.id} parent folder ${canvas.folder_id} was not found in cache.`
      );
    }
  });
}

function sortChildren(folderCache: FolderCache) {
  const collator = getCollator();

  for (let [, node] of folderCache) {
    node.children.sort((a, b) => {
      // Show folders before canvases
      if (a.isCanvas !== b.isCanvas) {
        return a.isCanvas ? 1 : -1;
      }
      return collator.compare(a.title, b.title);
    });
  }
}
